.operation-companies-wrapper
    width: 80%
    max-width: 1440px
    margin: -400px auto 0
    background: #fff
    position: relative
    border-top-right-radius: 20px
    @media(max-width: 616px)
        width: 100% !important
        border-top-left-radius: 20px;
    .container
        // padding: 85px 95px 35px
        padding: 70px 100px 60px
        flex-direction: column
        color: #110047
        // @media(max-width:850px)
        //     padding: 85px 75px 35px
        // @media(max-width: 685px)
        //     padding: 85px 30px 35px
        @media(max-width: 768px)
            padding: 30px 40px 35px
        #top
            margin: 0 !important
            .container
                padding: 0 !important
                .wrapper-top
                    padding: 0 !important
                    margin-bottom: 30px
                    .headers
                        line-height: 28px !important
                        word-spacing: 2px
                .menu-intro
                    display: none !important
        .text
            font-size: 16px
            line-height: 28px
            width: 100%
            font-weight: normal
            margin-bottom: 65px
            @media(max-width: 685px)
                padding: 0px 45px 0px
        .selects
            display: flex
            flex-direction: row
            @media(max-width: 1023px)
                flex-wrap: wrap
            .select-box
                margin-left: 20px
                width: 300px
                @media(max-width: 1012px)
                    margin-left:0
                &:nth-child(1)
                    margin-left:0
                &:nth-child(2)
                    width: 300px
                &:nth-child(3)
                    margin-top: 33px
                    width: 400px
                    display flex
                    align-items: center
                    .button-refresh-filter
                        padding: 0
                // @media(max-width: 1388px)
                //     margin-left: 0
                //     width: 200px !important
                &:nth-child(4)
                    // width: 150px
                    display: flex
                    align-items:center
                label
                    font-size: 14px
                .show-button
                    max-width: 200px !important
                    height: 40px
                    padding: 0 20px
                    padding-bottom: 5px
                .button-refresh-filter
                    color: #0cedc7
                    font-size: 14px
                    font-weight: bold
                    padding-top: 30px
                    cursor: pointer
        .categories
            display: flex
            margin: 45px 0 5px 13px
            @media(max-width:590px)
                flex-wrap: wrap
            .category
                display: flex
                margin-right: 30px
                @media(max-width:850px)
                    font-size: 14px
                @media(max-width:590px)
                    margin-top: 20px
                .symbol
                    margin-right: 20px
                    position: relative
                    z-index: 1
                    font-weight: bold
                    &:before
                        content: ''
                        position: absolute
                        left: calc(50% - 15px)
                        z-index: -1
                        height: 30px
                        width: 30px
                        border-radius: 100%
                        background: #0cedc7
                        box-shadow: 0px 3px 6px #00000033
                        @media(max-width:850px)
                            top: -5px

        .info
            display: flex
            flex-direction: row
            width:100%
            border-top: 1px solid #110047
            border-bottom: 1px solid #110047
            margin-top: 45px
            padding: 25px 0
            position: relative
            .info-details
                font-size: 18px
                margin-right: 30px
                @media(max-width:850px)
                    margin-right: 10px
                    font-size: 14px
                @media(max-width:425px)
                    width: 45%
                    font-size: 13px
                span
                    color: #00ecc2
            .sort-symbols
                position: absolute
                right: 0
                top: 20px
                display: flex
                flex-direction: column
                font-size: 13px
                font-weight: bold
                cursor: pointer
                .arrow-down
                    transform: rotate(180deg)

.select2
    .selection
        .companies-select
            border: 2px solid #411DA9 !important
            // width: 300px !important
            height: 48px !important
            border-radius: 100px !important
            padding: 4px
            padding-left: 10px
            position: relative !important
            z-index: 4 !important
            @media(max-width:420px)
                width: 270px !important
            &:focus
                outline: none !important
            .select2-selection__rendered
                color: #411DA9!important;
                font-size: 14px !important
                font-weight: 500
                padding-left: 15px !important
                line-height: 36px;
            .select2-selection__arrow
                top: 6px !important
                right: 13px !important
                b
                    background-image: url('../img/arrow-down.png')
                    width: 35px !important
                    height: 30px !important
                    top: 3px
                    left: 0
                    border: none
.select2-results__options::-webkit-scrollbar
    width: 16px
    background-clip: padding-box


.select2-container--open
    .select2-dropdown
        z-index: 3
    b
        transform: rotate(180deg)
        top: 0px !important
        left: -1px !important

.select2-container
    .companies-select-dropdown
        &.no-border
            .select2-results
                border: none !important
        .select2-results
            display: flex;
            height: auto;

            border: 2px solid #411DA9 !important
            border-top:none!important
            border-bottom-left-radius: 20px!important;
            border-bottom-right-radius: 20px!important;
            border-top: none;
            position: absolute;
            width: 100%
            top: -26px 
            padding: 13px 0 0
            @media(max-width:420px)
                width: 269px
            ul
                width: 100%;

                height: 100%;
                display: inline-block;
                text-align: left;
                position relative
                left: 0;
                background-color: #fff;
                border-bottom-left-radius: 20px!important;
                border-bottom-right-radius: 20px!important;
                border-top: none;
                max-height: 250px
                padding: 10px 20px 10px
                margin-bottom: 14px
                // padding: 30px 20px 20px;
                @media(max-width:420px)
                    width: 239px
                li
                    color:#411DA9 !important
                li.select2-results__option--highlighted
                    font-weight: bold

.companies-wrapper
    min-height: 300px;
    // justify-content: space-between
    @media(max-width:890px)
        justify-content: space-between
    .company-wrapper
        background: #f1f1f1
        display: flex
        flex-direction: column
        color: #110047
        margin-top: 50px
        padding: 25px 30px 20px 35px
        border-bottom-left-radius: 20px
        border-bottom-right-radius: 20px
        // width: 285px !important
        width: 30% !important
        margin-right: 3%

        transition: background-color 1s ease-out
        @media(max-width: 1145px)
            width: 47% !important
        @media(max-width:890px)
            width: 49% !important
            margin-right: 0
        @media(max-width:425px)
            padding: 20px
        .title
            font-size: 16px
            font-weight: bold
        .logo
            width:40px
            height 40px
        .description
            margin-top: 20px
            font-size: 12px
            line-height:22px
        a.newItem
            color:unset
            text-decoration:unset
            transition: all 0.2s!important
            &:hover
                transition: all 0.2s!important
                color:#00ecc2
                text-decoration:unset
        .map-marker
            padding-top: 10px
            font-weight: bold
            font-size: 12px
            cursor: pointer
            transition: all 0.2s
            &:hover
                transition: all 0.2s
                color:#00ecc2
                svg
                    transition: all 0.2s
                path
                    transition: all 0.2s
                    fill: #110047

            svg, img
                transition: all 0.2s
                padding-right: 5px
                width: 15px
                height: 24px
                padding-top: 10px
                path
                    transition: all 0.2s
                    fill: #00ecc2
.newsPagination
    margin-top: 65px
    text-align: center
    font-size: 12px
    position: relative
    width: 100%
    cursor: pointer
    &::before
        content: ''
        background: #00edc2
        width: 100px
        height: 1px
        position: absolute
        left: calc(50% - 50px)
        top: 44%
        opacity: .3
    a
        color: #110047 !important
        padding: 4px 8px
        font-weight: normal
    .disabled,  a:last-child
        color: #00edc2 !important


.z-index
    @media(max-width: 991px)
        position: relative
        z-index:2