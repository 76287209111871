.knows-more
    font-family: frank-new, sans-serif
    width: 80%
    max-width: 1440px
    margin: 30px auto
    .container
        width: 100%
        display: flex
        .info-wrapper
            display: flex
            flex-flow: row
            width: 100%
            padding: 0 
            margin: 0 auto
            justify-content: center
            align-items: flex-start
            .general-foto-wrapper
                height: 100%
                display: flex
                justify-content: flex-end
                padding: 0 10px 0 0
                .foto-wrapper
                    border-radius: 50%
                    height: 94px
                    width: 94px
                    position: relative
                    @media (max-width: 954px)
                        display: none
                    .patron-picture
                        width: 100%
                        height: 100%
                        background-size: cover
                        border-radius: 50%
                    .patron-picture-circle
                        width: 101%
                        height: 102%
                        background-size: contain
                        background-repeat: no-repeat
                        padding: 0
                        margin:0
                        position: absolute
                        top: -2px
                        right: -6px
            a
                text-decoration: none            
                .dialgo-box
                    border: 1px solid mapGrey
                    border-bottom-right-radius: 30px
                    height: 100%
                    display: flex
                    flex-flow: column
                    padding: 43px 20px 43px 20px
                    position: relative
                    text-align: center
                    h1
                        font-size: 36px
                        font-weight: 800 !important
                        color: pictograms
                        display: flex
                        width: 100%
                        justify-content: center
                        @media (max-width: 396px)
                            font-size: 26px
                    h2
                        font-size: 24px
                        font-weight: 500 !important
                        color: headerNumbersBlue
                        display: flex
                        width: 100%
                        justify-content: center
                        @media (max-width: 396px)
                            font-size: 14px
                    #triangle
                        transform: rotate(-90deg)
                        position: absolute
                        top: 80px
                        left: -22px
                    #triangle:after       
                        position:absolute
                        content:""
                        width: 0
                        height: 0
                        margin-top: 2px
                        margin-left: 2px
                        border-left: 20px solid transparent
                        border-right: 20px solid transparent
                        border-bottom: 20px solid white
                    #triangle:before 
                        position:absolute
                        content:""
                        width: 0
                        height: 0
                        border-left: 22px solid transparent
                        border-right: 22px solid transparent
                        border-bottom: 22px solid mapGrey