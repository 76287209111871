.input-field
	width: 100%;
	max-width: 250px;
.custom-select-wrapper 
	position: relative
	display: inline-block
	user-select: none
	width: 100%
	top: -1px

.custom-select-wrapper select 
	display: none

.custom-select 
	position: relative
	display: inline-block
	height:0 
	border: none
	padding: 0
	width: 100%

.custom-select
	&.opened 
		&.custom-select-trigger
			&:after {
				transform: rotate(-135deg)
}
.custom-select-trigger 
	position: relative
	transform: translateY(-20%)
	display: flex
	font-family: 'BasierCircle-SemiBold'
	align-items: center
	width: 100%
	padding: 0 22px 0 22px
	font-size: 16px
	font-weight: 300
	border: 3px solid green
	color: #4C31D5
	background-color: white
	line-height: 60px
	max-height: 60px
	background: transparent
	border-radius: 4px
	cursor: pointer
	@media(max-width: 1024px)
		height: 40px
		line-height: 40px
		font-size: 13px
		transform: translateY(-30%)

.custom-select-trigger
	&::after 
		position: absolute;
		display: block;
		content: '';
		width: 9px; 
		height: 9px;
		top: 50%; 
		right: 10%;
		margin-top: -4px;
		border-bottom: 3px solid green;
		border-right: 3px solid green;
		transform: rotate(45deg);
		transition: all .4s ease-in-out;

.custom-options 
	position: absolute;
	display: block;
	top: 45px; left: 0; right: 0;
	min-width: 100%;
	margin: 15px 0;
	padding: 10px 0;
	border: 3px solid green;
	border-radius: 4px;
	box-sizing: border-box;
	box-shadow: 0 2px 1px rgba(0,0,0,.07);
	background: #fff;
	transition: all .5s ease-in-out;
	white-space: nowrap;
	width: max-content;
	cursor: pointer;
	z-index: 10000;
	
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	@media (max-width: 1300px) 
		left: 50%;
		transform: translateX(-50%);
		top: 40px;
	

	@media (max-width: 1025px) 
		top: 20px;
	
.custom-select
	&.opened 
		&.custom-options 
			opacity: 1;
			visibility: visible;
			pointer-events: all;

.option-hover
	&::before 
		background: #f9f9f9;

.custom-option 
	position: relative;
	display: block;
	z-index: 1;
	padding: 0 22px;
	font-size: 14px;
	font-weight: 600;
	color: purple;
	line-height: 47px;
	cursor: pointer;
	transition: all .2s ease-in-out;

.custom-option
	&:hover
		background: $arc-purple;
		color: white;
.custom-option
	&.selection 
		background: $arc-purple;
		color: white;
	