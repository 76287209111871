.foto-block
    max-width: 1440px
    display: flex
    margin: 0 auto
    .container
        display: flex
        flex-flow: row wrap
        justify-content: space-between
        padding: 0
        margin: 0 100px
        min-width: 85%
        @media (max-width: 768px)
            margin: 0 40px 0 40px
        @media (max-width: 470px)
            flex-flow: column
            justify-content: center
        p.section-title
            font-family: "frank-new", "sans-serif" !important
            font-size: 16px
            line-height: 21px
            font-weight: 700
            color: #411DA9
            margin-bottom: 25px
            width: 100%
        .teem
            display: flex
            flex-flow: column
            .hedmaster-foto
                display: flex
                width: 200px
                height: 200px
                padding: 0
                align-items: flex-start
                @media (max-width: 470px)
                    margin: 0 auto
                    justify-content: center
                img
                    margin: 30px 0 0 0
                    display: flex
                    width: 100%
                    height: 100%
            .notation
                display: flex
                flex-flow: column
                width: 200px
                padding: 0
                margin: 30px 0 0 0
                font-size: 16px
                color: headerTextBlue
                justify-content: center
                @media (max-width: 470px)
                    width: 100%
                    justify-content: center
                .name
                    font-weight: 700 !important
                    font-size: 16px
                    text-align: center
                .position
                    font-weight: 300 !important
                    font-size: 16px
                    height: 25px
                    text-align: center

