.career-popup 
	.shore-popup
		width: 100vw
		height: 100vh
		border: none
		top: 0px
		left: 0px
		background-color: rgba( 17,0,71, -1 )
		background-size: cover
		background-repeat: no-repeat
		overflow:hidden
		display: flex
		z-index: 1
		position: absolute
	.pop-up
		position: fixed
		top: 0
		left: 0
		height: 100%
		width: 100%
		background-color: rgba( 17,0,71, 0.6 )
		z-index: 999
		display: none
		@media (max-width: 980px)
			background-color: rgb( 7,0,71)
		.row
			padding: 0
		.row-sides
			padding-right: 15px
			padding-left: 15px
			width: 80%
			.col-content
				position relative
				.popup-wrapper
					display: flex
					flex-flow: column
					position: absolute
					border-radius: 15px
					width: 100%;
					left: 40%;
					transform: translate(-50%, 10%)
					padding: 10 0
					box-shadow: 3px 2px 0 4px rgba(17,0,71, .0)
					@media (max-width: 768px)
						transform: translate(-50%, 30%)
					@media (max-width: 616px)
						transform: translate(-50%, 5%)
					.popup-media-scroll
						@media (max-width: 980px)
							padding: 0 50px
							overflow-y: auto
							overflow-x: hidden
							max-height: 70vh;
						@media (max-width: 616px)
							max-height: 50vh;
					.nano
						width: 100vw
						min-height: 100vh
						background-color: none
						z-index:1
						@media (max-width: 980px)
							min-height: 650px
						@media (max-width: 616px)
							min-height: 100vh
						.gradeint-popup
							background-image: radial-gradient(circle at 35% 35%, rgba(0,236,194, .3), rgba(17,6,74, .2), rgba(17,0,71, .1))
							position: absolute
							width: 80vw
							min-height: 75%
							border: none
							top: 0px
							left: 0
							-webkit-border-top-right-radius: 40px
							-moz-border-radius-topright: 40px
							border-top-right-radius: 40px
							overflow:hidden
							display: flex
							@media (max-width: 980px)
								height: 100%
								top: -200px
								width: 100vw
							@media (max-width: 616px)
								left: 20px
							@media (max-width: 266px)
								display: none
					.popup-content
						height: 100%
						width: 80%
						padding: 0
						display: flex
						flex-flow: column
						border: none 
						outline: none
						.wrapper-up-content
							display: flex
							flex-flow: column
							padding: 50px
							background-color: headerTextBlue
							top: 50px
							max-width: 100%
							min-height: 700px
							max-height: 3000px
							border-top-right-radius: 20px
							@media (max-width: 980px)
								max-width: 100%
								max-height: 100%
								padding: 0
			
									


// header, subheader

							.subheader
								margin-bottom: 40px


// form

							.awesome-form
								padding: 0
								.form
									margin-bottom: 50px
									.hr-grey
										border-top: solid border-width #110047
										margin-right: 0
										margin-left: 0

									input, textarea
										color: #110047
										&[type="text"],
										&[type="email"]
											border-bottom: solid border-width #110047
									.input-group
										input
											&:focus
												&[type="text"],
												&[type="email"]
													border-bottom: solid border-width #110047
										textarea
											padding-top: 31px
										input:focus + label,
										input.has-value + label
											color: green
										textarea:focus + label,
										textarea.has-value + label
											font-size: 14px
											color: green



// under form

					h3
						font-size: 18px
						font-weight: bold !important
						margin-bottom: 26px

						@media( min-width: 991px )
							font-size: 18px

					.agree
						margin-top: 26px
						@media(max-width: 575px)
							margin-top: 7px
					.mytable
						width: 100%
						.mytd
							display table-cell
						.mynotification
							margin-left: 34px

						&.attachment

							.td
								padding-bottom: 15px
								@media(max-width: 575px)
									padding-bottom: 12px
								input[type="file"]
									display: none

									+ label
									
										&.full-width
											width: 100%

						.td

							&.file-container
								margin-bottom: 15px
								width: 70%
								@media(max-width: 575px)
									margin-bottom: 0

							&.notification
								vertical-align: top

								+ .td
									color: #110047
									font-size: 10px
									margin-bottom: 19px

									label
										font-weight: 400 !important

							input[type="checkbox"]
								display: none
								+ label
									span
										margin-right: 13px

										&.notification-box
											display: block
											width: 21px
											height: 21px
											background: white
											border: 1px solid green
											border-radius: 50%
											cursor: pointer
											transition: all .2s ease-in-out

											span
												display: block
												background: white
												border-radius: 50%
												width: 7px
												height: 7px
												margin: 6px
												border: 1px solid green
								&:checked
									+ label
										span
											&.notification-box
												background: #110047

					@media( min-width:  991px )
						font-size: 14px

			.button-container
				position relative
				bottom: -34px
				left: calc(50% - 87px)



input[type=checkbox] 
	opacity: 0
	cursor: pointer
	z-index: 100

.check1
	position: absolute
	left: 0px
	height: 12px
	width: 12px
	background-color: red    
	border-radius: 50%
	cursor: pointer
	z-index: 100

input:checked ~ .check1
	background-color: pictograms
	z-index: 100

    
.check1:after
	content: ""
	position: absolute
	display: none
	z-index: 100
    

input ~ .check1
	background-color: #ccc
	z-index: 100
    


input:checked ~ .check1:after
	display: block
	z-index: 100
	-webkit-box-shadow: 0px -1px 60px 0px rgba(0,236,194,1);
	-moz-box-shadow: 0px -1px 60px 0px rgba(0,236,194,1);
	box-shadow: 0px -1px 60px 0px rgba(0,236,194,1);


.check1:after
	top: 1px
	left: 1px
	width: 10px
	height: 10px
	border-radius: 50%
	background-color: pictograms
	border: 3px solid headerTextBlue
	z-index: 100
	-webkit-box-shadow: 0px -1px 60px 0px rgba(0,236,194,1);
	-moz-box-shadow: 0px -1px 60px 0px rgba(0,236,194,1);
	box-shadow: 0px -1px 60px 0px rgba(0,236,194,1);


.errors
	display: flex
	flex-flow: column
	padding: 0
	li
		list-style-type none
		padding: 0
		color: errors
		font-weight: 300 !important
		font-size: 12px


.popup2
	height: 100%
	width: 100%
	position: relative
	top: 140px
	display: flex
	justify-content: flex-start
	z-index 999
	@media (max-width: 1024px)
		top: 90px
	@media (max-width: 980px)
		top: 10px
	@media (max-width: 616px)
		padding: 0 20px 0 20px
	.element-content-box
		display: flex
		flex-flow: column
		margin: 0 auto
		background-color: transparent
		.main-header
			position relative
			display: flex
			justify-content: flex-start
			flex-flow: row
			align-items: flex-start
			z-index 100
			width: 100%
			height: auto
			margin: 0 auto
			@media (max-width: 980px)
				margin: 20px auto 0 auto
			@media (max-width: 616px)
				flex-flow: column
				justify-content: flex-start
				margin: 10px auto 0 auto
			@media (max-width: 375px)
				min-height: 160px
			.exit-cross
				position absolute
				top: 10px
				right: 10px
				width: 26px
				height: 26px
				cursor: pointer
				z-index: 100
				@media (max-width: 980px)
					top: 30px
					right: 30px
				@media (max-width: 375px)
					top: 10px
					right: 10px
				&::before
					position: absolute
					right: 12px
					top: 2px
					content: ''
					height: 22px
					width: 2px
					background-color: #fff
					transform: rotate(45deg)
				&::after
					position: absolute
					right: 12px
					top: 2px
					content: ''
					height: 22px
					width: 2px
					background-color: #fff
					transform: rotate(-45deg)
			h1
				margin: 0 43px 0 0px
				color: #fff
				font-size: 52.95px
				font-weight: 100 !important
				position: relative
				@media (max-width: 616px)
					width: 100%
					margin: 0

			.text-header
				width: 100%
				padding: 0 0 20px 0
				display: flex
				flex-flow: column
				h2 
					color: #fff
					align-items: flex-end
					font-weight: 400 !important
					font-size: 36px
					margin: 0 
					padding: 0px 0 0 0 
					@media (max-width: 616px)
						font-size: 30px
				.sub-header
					padding: 0 
					font-family: "frank-new", "sans-serif" !important 
					min-height: 50px
					h1
						color: pictograms !important 
						font-size: 24px !important 
						font-weight: 100 !important
						font-family: "frank-new", "sans-serif" !important
						@media (max-width: 616px)
							font-size: 18px !important 
		.contact-form-wrapper
			margin: 20px 0 0 100px
			@media (max-width: 1024px)
				overflow: auto
			@media (max-width: 616px)
				margin: 20px 0 0 0
			.popup-media-scroll
				@media (max-width: 375px)
					margin: 30px 0 0 0
				label
					display: flex
					justify-content: flex-start
					flex-flow: row
					padding: 5px 0 0 0
					z-index: 100
					font-family: "frank-new", "sans-serif" !important
					@media (max-width: 980px)
						flex-flow: column
						justify-content: flex-start
						text-align: left
					
					p
						min-width: 140px
						padding: 10px 0
						margin: 0 20px 0 0 
						height: 100%
						text-align: right
						color: #fff
						font-family: "frank-new", "sans-serif" !important
						font-size: 15.78px
						font-weight: 700 !important
						position: relative
						z-index: 100
						@media (max-width: 980px)
							text-align: left
					input[type=text]
						height: 31px
						width: 100%
						margin: 0 20px 0 0
						color: pictograms
						background-color: rgba(0, 236, 194, .2)
						font-family: "frank-new", "sans-serif" !important
						border: none
						cursor: pointer
						padding: 0 10px
						font-size: 14px
						font-family: "Frank New"
						outline: none
						box-sizing: border-box
						justify-content: space-between
						font-weight: 300 !important
						z-index: 100
						&::-webkit-input-placeholder
							color: pictograms
						&::-moz-placeholder
							color: pictograms
						&:-ms-input-placeholder
							color: pictograms
						&:-moz-placeholder
							color: pictograms
						&:placeholder
							color: pictograms
						&:focus
							background-color: pictograms
							color: #fff
						@media (max-width: 980px)
							margin: 20px 0 0 
							padding: 10px
					.textarea-popup
						width: 100%
						margin: 0 20px 0 0
						height: 30px
						padding: 5px 0 5px 9px
						font-family: frank-new, sans-serif
						font-size: 14px
						font-weight: 300 !important
						cursor pointer
						-ms-color: headerTextBlue
						color: pictograms
						background-color: rgba(0, 236, 194, .2)
						border: none
						outline: none
						font-weight: 400 !important
						-webkit-box-sizing: border-box;
						box-sizing: border-box
						z-index: 100
						resize: none
						min-height: 30px
						max-height: 162px
						&:focus
							color: #fff !important
							background-color: pictograms 
						&::-webkit-input-placeholder
							color: pictograms
						&::-moz-placeholder
							color: pictograms
						&:-ms-input-placeholder
							color: pictograms
						&:-moz-placeholder
							color: pictograms
						&:placeholder
							color: pictograms
						@media (max-width: 980px)
							height: 50px
				.attachements-elements
					display: flex 
					flex-flow: row
					@media (max-width: 980px)
						flex-flow: column
					.elements	
						width: 100%		
						display: flex
						flex-flow: column
						.add-file-elements
							display: flex
							flex-flow: column
							color: none
							width: calc((100% / 3) - 20px)
							z-index: 100
							margin: 0 0 42px 0 
							position: relative
							border-radius: none
							input[type="file"] 
								border: none 
								background: none
								display: none
							@media (max-width: 1024px)
									width: 96%
							@media (max-width: 768px)
									width: 100%
							.form-control
								background-color: #fff
								border-radius: none
								position: absolute
								top: 0
								height: 31px
								color: pictograms
								background-color: rgba(0, 236, 194, .2)
								font-family: "frank-new", "sans-serif" !important
								border: none
								cursor: pointer
								padding: 5px 10px
								font-size: 14px
								font-family: "Frank New"
								outline: none
								box-sizing: border-box
								justify-content: space-between
								font-weight: 300 !important
								z-index: 100
								@media (max-width: 980px)
									width: 100%
							.custom-file-upload
								background-color: #fff
								border-radius: none
								width: 370px
								position: absolute
								top: 0
								height: 31px
								color: pictograms
								background-color: rgba(0, 236, 194, .2)
								font-family: "frank-new", "sans-serif" !important
								border: none
								cursor: pointer
								padding: 5px 10px
								font-size: 14px
								font-family: "Frank New"
								outline: none
								box-sizing: border-box
								text-align: left
								justify-content: space-between
								font-weight: 300 !important
								z-index: 100
								@media (max-width: 1024px)
									width: 100%
						.add-file-elements2
							display: flex
							flex-flow: column
							color: none
							width: 100%
							z-index: 100
							position: relative
							border-radius: none
		

				.input-radio-popup
					display: flex
					justify-content: flex-start
					flex-flow: row
					font-size: 10px
					z-index: 100
					position: relative
					.check1
						display: flex
						margin: 0 0 0 10px
						position: absolute
						top: 5px
						left: 149px
						@media (max-width: 980px)
							left: 0px
					p
						color: pictograms
						font-family: "frank-new", "sans-serif" !important
						font-size: 10px
						text-align: left
						padding: 0
						margin: 0 0 0 165px
						@media (max-width: 980px)
							margin: 0 0 0 20px
			.button-popup
				padding: 0 0 0 150px
				margin: 0 0 100px 0
				display: flex
				z-index: 100
				@media (max-width: 980px)
					padding: 0
					margin: 0
					flex-flow: flex-start
				@media (max-width: 768px)
					padding: 0
					margin: 0
				@media (max-width: 400px)
					padding: 0
				.applay
					display: flex
					align-items: center !important
					justify-content: center !important
					text-align: center !important
					width: 242px
					height: 42px
					border: 1px solid pictograms
					margin: 30px 0 0 0
					padding: 0
					border-radius: 30px
					background-color: none 
					outline: none
					font-size: 14px
					color: #fff
					font-weight: 400 !important
					z-index: 100
					@media (max-width: 400px)
						width: 192px
						margin: 25px 0 
					&:hover
						background-color: rgba(17, 0, 71, .4)
						-webkit-box-shadow: 0px 0px 33px -4px rgba(0,237,194,1);
						-moz-box-shadow: 0px 0px 33px -4px rgba(0,237,194,1);
						box-shadow: 0px 0px 33px -4px rgba(0,237,194,1);
					button
						text-decoration: none
						display: block
						width: 242px
						color: #fff
						text-align: center
						cursor: pointer
						z-index: 100
						background: none
						border: none 
						outline: none 
						height: 100%
						width: 100%
			.recaptcha-careerform
				margin: 0 0 0 162px
				@media (max-width: 768px)
					margin: 0px

.rc-anchor 
	margin: 0 0 0 162px
						
			