.partners-slider
    margin-top: 100px
    .owl-item
        border: 1px solid #DCDCDC
        border-top: none;
        border-bottom: none
        &:nth-of-type(add)
            border-left: none  
    .owl-dots
        display: none
  