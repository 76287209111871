.users-wrapper
    margin: 0 auto
    width: 80%
    max-width: 1440px
    .container
        border-top-right-radius: 30px
        display: flex
        flex-flow: column
        width: 100%
        margin: 0 auto
        z-index: 100
        padding: 0
        .system-users
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            width: 100%
            margin: 0px auto
            .header
                display: flex
                justify-content: flex-start
                flex-flow: row nowrap
                align-items: flex-start
                padding: 0
                margin: 0
                width: 100%
                @media (max-width: 768px)
                    flex-flow: column
                    width: 100%
                h1
                    margin: 0 35px 0 0
                    color: headerNumbersBlue
                    font-size: 52.95px
                    padding: 0
                    font-weight: 100 !important
                .wrapper
                    h2 
                        display: flex
                        @media (max-width: 768px)
                            padding: 0 
                            justify-content: flex-start
                        p
                            margin: 0
                            padding: 0
                    .description
                        width: 100%
                        font-size: 16px
                        font-weight: 400 !important
                        margin: 42px 0 20px 0
                        color: headerTextBlue
                        display: flex
                        @media (max-width: 768px)
                            margin: 20px 0
    .boxes-wrapper
        border-top-right-radius: 30px
        height: 100%
        display: flex
        flex-flow: column
        width: 90%
        max-width: 1440px
        margin: 0 auto
        z-index: 100
        position: relative
        @media (max-width: 1530px)
            width: 100%
            // min-height: 1800px
            max-height: 5000px
        @media (max-width: 768px)
            width: 100%
            min-height: 1800px
            max-height: 5000px
        @media (max-width: 561px)
            min-height: 1300px
            max-height: 3000px
        .system-users
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            width: 100%
            margin: 0px auto
            .boxes
                display: flex
                justify-content: center
                flex-flow: row wrap
                align-items: flex-start
                margin: 0
                padding:0
                width: 100%
                height: 100%
                @media (max-width: 1584px)
                    width: 120%
                @media (max-width: 1531px)
                    width: 110%
                @media (max-width: 1297px)
                    width: 100%
                .box
                    position relative
                    border-bottom-left-radius: 30px
                    border-bottom-right-radius: 30px
                    margin: 0 10px 10px 0
                    height 422px
                    width: 275px
                    text-align: center
                    @media (max-width: 713px)
                        width: 48% 
                        margin: 3px 2px 
                    @media (max-width: 561px)
                        height 290px
                    &:hover
                        .overlay
                            opacity 1
                            z-index: 100 
                            @media (max-width: 561px)
                                display: none                  
                    .titles
                        font-size: 15.78px
                        font-weight: 700 !important
                        color: #ffffff 
                        margin: 31px 0
                        display: inline-block
                        position: relative
                        background-image: repeating-linear-gradient(to top, headerTextBlue, headerTextBlue 13px, transparent 14px, transparent 40px)
                        @media (max-width: 613px)
                            background-image: repeating-linear-gradient(to top, headerTextBlue, headerTextBlue 13px, transparent 5px, transparent 25px)
                            padding: 0 10px
                        @media (max-width: 561px)
                            width: 100%
                            padding: 0 25px
                        &, *:nth-child(7)
                            @media (max-width: 561px)
                                padding: 0
                        .headers
                            width: 100%
                            display: inline-block
                            text-align: center
                            margin: 0
                            z-index: 100
                                
                    .overlay
                        display: block
                        opacity 0
                        z-index -1
                        position: absolute
                        top: 0px
                        flex-flow: column
                        background-color: rgba(0, 236, 194, .6)
                        width: 100%
                        height: 100%
                        border-bottom-left-radius: 30px
                        border-bottom-right-radius: 30px
                        @media (max-width: 561px)
                            display: none
                        .overlay-description
                            opacity: none
                            display: flex
                            flex-flow: column
                            font-size: 14px
                            font-weight: 400 !important
                            height: 380px
                            width: 100%
                            background-color: pictograms
                            border-bottom-left-radius: 30px
                            border-bottom-right-radius: 30px
                            padding: 40px 27px 0 27px
                            margin: 0
                            color: headerTextBlue
                            text-align: left
                            z-index: 100
                            @media (max-width: 713px)
                                height: 90%
                            @media (max-width: 703px)
                                padding: 40px 0 0 0
                            @media (max-width: 703px)
                                padding: 40px 0 0 0
                            @media (max-width: 613px)
                                padding: 40px 10px 0 10px
                            @media (max-width: 561px)
                                display: none
                            p
                                @media (max-width: 703px)
                                    padding: 15px 27px 0 27px
                            .overlay-titles
                                font-size: 15.78px
                                font-weight: 700 !important
                                color: headerTextBlue 
                                display: inline-block
                                padding: 10px 10px 1px 10px
                                margin: 0 auto 20px auto
                                text-align: center
                                background-image: repeating-linear-gradient(to top, #ffffff, #ffffff 13px, pictograms 14px, pictograms 40px);
                                @media (max-width: 613px)
                                    background-image: repeating-linear-gradient(to top, #ffffff, #ffffff 17px, pictograms 5px, pictograms 28px)
                                    padding: 0 10px

            .button                    
                width: 100%
                padding: 0
                margin: 0
                display: flex
                justify-content: center
                // border: 1px solid pink
                position: absolute
                bottom: -50px
                a
                    text-decoration: none
                    @media (max-width: 303px)
                        text-align: center  
                    .more-about-system
                        display: flex
                        align-items: center
                        justify-content: center
                        width: 100%
                        height: 42px
                        border: 1px solid pictograms
                        margin: 29px 0 0 0
                        padding: 0 30px
                        border-radius: 50px
                        background-color: white
                        font-size: 14px
                        color: headerTextBlue
                        font-weight: 400 !important
                        @media (max-width: 626px)
                            margin: 10px 0 0 0
                        @media (max-width: 303px)
                            justify-content: center
                        &:hover
                            background-color: whiteButtonHover 
                        

    .second-overlay
        color: hoverDescriptionFrontColor
        opacity: 1
        width: 100%
        height: 100%
        padding: 0
        margin: 0
        transition: all 0.3s linear
        *
            transition: all 0.3s linear
        &:nth-child(4)
            @media (max-width: 561px)
                background-position-y: -20px
        @media (max-width: 713px)
            background-position: center
            background-repeat: no-repeat
        @media (max-width: 561px)
            background-position-y: -60px
        @media (max-width: 561px)
            padding: 0 10px

