
// to jest baner do kazdej z podstron

.homebanner 
    font-size: 18px
    font-family: frank-new, sans-serif
    font-weight: 500 !important
    font-style: medium
    margin: 0  
    header 
        height: 880px
        padding: headerPadding
        overflow: hidden
        background-image: linear-gradient(rgba(17, 0, 71, 0.46), rgba(17, 0, 71, 0.46)70%,  rgba(255,255,255,0.5)80%, rgba(255,255,255,0.85)88%, rgba(255,255,255,1)95% ), url(../img/bg_image.png)
        background-size: cover
        position: relative
        &::after
            content: ""
            position: absolute
            bottom: -10px
            left: 0
            /* margin-top: -810px; dddddd*/
            /* display: block; */
            width: 100%

            // -webkit-appearance: none !important
            // -webkit-box-shadow: 0px -40px 190px 160px rgba(255,255,255,1)
            // -moz-box-shadow: 0px -40px 190px 160px rgba(255,255,255,1)
            // box-shadow: 0px -40px 190px 160px rgba(255,255,255,1)
            // background: white
            // height: 1px
            
            height: 300px
            background: linear-gradient( rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%)
        .blendmode
            height: 880px
            width: 100%
            position: absolute
            background: rgba(17,0,71,0.66)
        .header-bg-img 
            .single-slide-seashore2
                background-image: url(../img/banner_seashore.svg)
                background-repeat: no-repeat
                background-position: 50% 105%
                background-size: contain 
                width: 100%
                height: 100%
                position: absolute
                top: 0px
            .title
                color: white
                width: 80%
                max-width: 1440px
                position: absolute
                top: 45%
                transform: translate(-50%, -50%) 
                left: 49%
                h1
                    font-size: 80px
                    margin-bottom: 0px
                    padding: 0px 100px
                h2 
                    font-size: 40px
                    font-weight: 200 !important
                    margin-bottom: 110px         
                    padding: 0px 100px
@media(min-width: 2190px)
    .homebanner
        header
            .header-bg-img
                .single-slide-seashore2
                    background-position: 50% 50% !important
                    background-size: cover !important

@media (min-width: 2048px)
    .homebanner
        header
            .header-bg-img
                .title
                    left: 50%

@media (max-width: 1600px)
    .homebanner 
        header 
            .header-bg-img
                .title
                    left: 49%

@media (max-width: 1024px)
    .homebanner 
        header 
            padding: 0px
            &::after
                margin-top: -850px !important  
            .header-bg-img
                .title
                    position: absolute
                    left: 50%
                    top: 42%
                    h1 
                        font-size: 60px
                        line-height: auto
                        margin-bottom: 15px
                    h2 
                        font-size: 30px
                        padding-bottom: 100px

@media (max-width: 768px)
    .homebanner 
        header 
            .header-bg-img  
                .title
                    width: 90%
                    top: 38%
                    h1
                        padding: 0 40px
                    h2 
                        padding: 0 40px

@media (max-width: 616px)
    .homebanner 
        header  
            .header-bg-img
                .title
                    top: 37%
                    width: 100%
                    h1
                        font-size: 45px
                        line-height: 1.1em
                    h2
                        font-size: 20px
                        

















    




            

    
                                
                                    

                        




            
