.foto-carousel
    max-width: 1440px
    margin: 0 auto
    height: 100%
    @media(max-width: 1024px)
        width: 100%

    .container
        display: flex
        flex-flow: column
        margin: 0 auto
        min-width: 100%
        // min-height: 500px
        padding: 0 100px
        @media (max-width: 1024px)
            padding: 0 0 0 100px
        @media (max-width: 768px)
            padding: 0 0 0 40px
        @media (max-width: 616px)
            padding: 0

        &.p-0
            padding:0
        .carousel
        .carousel-inner
            .carusel-image
                background-position: center
                background-size: contain
                background-repeat: no-repeat
                img
                    width: 100%
                    height: 100%
            .personalized-carousel-numbering
                display: flex
                flex-flow: row
                height: 40px
                justify-content: center
                position: absolute
                width 100%
                cursor pointer
                margin: 0 0 41px 0
                .carousel-indicators-numbers
                    font-family: frank-new, sans-serif
                    position: absolute
                    bottom: -40px
                    left: 0
                    li
                        display:flex
                        margin: 0 5px
                        text-indent: 0
                        color: headerTextBlue
                        margin: 0 2px
                        background-color: transparent
                        width: 30px
                        height: 30px
                        transition: all 0.25s ease
                        font-size: 12px
                        &.active, &:hover
                            width: 30px
                            height: 30px
                            color: pictograms
                        i
                            font-size: 5px
                            color: headerTextBlue
                            .fa-circle
                                &.active, &:hover
                                    width: 30px
                                    height: 30px
                                    color: pictograms
                            .far
                                &.active, &:hover
                                    width: 30px
                                    height: 30px
                                    color: pictograms

.carouselExampleIndicators
    padding: 0
    margin: 0

.blueimp-gallery
    background-color: rgba( 17,0,71, 0.6 ) !important
.blueimp-gallery>.next
        border: 1px solid pictograms !important
        color: pictograms !important
.blueimp-gallery>.prev
        border: 1px solid pictograms !important
        color: pictograms !important
.blueimp-gallery>.close
        color: pictograms !important
.blueimp-gallery>.play-pause
        display: none !important
.blueimp-gallery>.indicator
        display: none !important
