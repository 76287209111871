.standard-wrapper {
  width: 80%;
  max-width: 1140px;
  margin: 0 auto;
}
.blenda {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  background: -moz-linear-gradient(left, #110047 0%, transparent 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #110047), color-stop(100%, transparent));
  background: -webkit-linear-gradient(left, #110047 0%, transparent 100%);
  background: -o-linear-gradient(left, #110047 0%, transparent 100%);
  background: -ms-linear-gradient(left, #110047 0%, transparent 100%);
  background: linear-gradient(to right, #110047 0%, transparent 100%);
}
* {
  box-sizing: border-box;
}
* .sticky {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
a:active,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}
a {
  transition: all 0.3s linear;
}
body {
  font-family: "frank-new", "sans-serif" !important;
}
.display-inline {
  height: 45px !important;
}
.logo-sticky-visible {
  display: inline !important;
  height: 40px;
  margin-top: -10px;
  visibility: visible;
}
.logo-sticky-mobile {
  display: none;
}
.current {
  color: #00edc2;
}
.blue-border-bottom {
  border-bottom: solid 1px #309 !important;
  transition: all 0.3s ease;
}
.pseudo-no-content:after {
  content: none !important;
}
.visible {
  visibility: visible !important;
}
.element-content-box {
  width: 80%;
  max-width: 1440px;
  margin: 0px auto;
  background: #fff;
  margin-top: -400px;
  position: relative;
  border-top-right-radius: 20px;
}
@media (max-width: 616px) {
  .element-content-box {
    width: 100% !important;
    border-top-left-radius: 20px;
  }
}
.element-content-box.containers-status {
  background: none;
  margin: -680px auto 0;
}
.overflowHidden {
  overflow-y: hidden;
  touch-action: none;
}
.big-box {
  width: 2000px !important;
  height: 2000px !important;
}
.opacity100 {
  opacity: 1 !important;
}
.hamburger-1st {
  top: 29px !important;
  width: 0% !important;
  opacity: 0 !important;
}
.hamburger-2nd {
  transform: rotate(45deg) !important;
}
.hamburger-3rd {
  transform: rotate(-45deg) !important;
}
.hamburger-4th {
  top: 29px !important;
  width: 0% !important;
  opacity: 0 !important;
}
. selected-value {
  color: #00edc2 !important;
}
. selected-value:before {
  content: '';
  top: -5px;
  right: calc(50% - 10px);
  width: 20px;
  height: 4px;
  border: solid 1px #00edc2;
  border-color: #00edc2 transparent transparent transparent;
  border-radius: 50%/3px 3px 0 0;
  position: absolute;
  transform: rotate(180deg);
}
.aboutMenu-OnOff {
  display: flex !important;
}
.aboutMenu-borderBottom {
  border-bottom: none !important;
}
.show-up {
  opacity: 1 !important;
  visibility: visible !important;
}
.slider-button-behind {
  z-index: -100 !important;
}
.slider-button-front {
  z-index: 100 !important;
}
.paused {
  -webkit-animation-play-state: paused !important;
  -moz-animation-play-state: paused !important;
  -o-animation-play-state: paused !important;
  animation-play-state: paused !important;
  display: none !important;
}
.bordered {
  border-bottom: 1px solid #fff;
}
.container {
  background-color: none;
}
.container::before {
  display: none;
}
.container::after {
  display: none;
  padding: 0;
}
.activate {
  color: #f00;
}
.margin {
  display: inline-block;
}
.margin.xlarge {
  margin-bottom: 240px;
}
.margin.xsmall {
  margin-bottom: 40px;
}
.margin.large {
  margin-bottom: 120px;
}
.margin.medium {
  margin-bottom: 85px;
}
.margin.small {
  margin-bottom: 60px;
}
.bold {
  font-weight: 700 !important;
}
.number-tresc-header {
  display: flex;
  flex-flow: row;
  width: 80%;
  margin: 0 auto;
}
.number-tresc-header h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
.number-tresc-header h2 {
  color: #110047;
  padding: 20px 0 0 0;
  margin: 0;
  font-weight: 400 !important;
  font-size: 36px;
}
.button * {
  transition: all 0.3s linear;
}
.header {
  width: 90%;
  display: flex;
}
.header h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
.header h2 {
  color: #110047;
  transform: translateY(15%);
  margin: 0;
}
.header h2 p {
  padding: 0;
  margin: 0;
}
.header h2 * {
  font-weight: 400 !important;
  font-size: 36px;
  line-height: 46px;
}
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #110047;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: #fff;
}
input:-moz-input-placeholder,
textarea:-moz-input-placeholder {
  color: #110047;
}
input:focus::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: #fff;
}
input::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: #110047;
}
input:focus::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: #fff;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #110047;
}
input:focus::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #fff;
}
.flex {
  display: flex;
}
.flex.space {
  justify-content: space-between;
}
.mb-40 {
  margin-bottom: 40px;
}
#mapa-statkow,
#ruch-statkow {
  display: block;
  height: 800px;
}
.map-wrapper {
  position: relative;
}
.map-wrapper .loading-blenda {
  z-index: 1;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  background-color: #fff;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.map-wrapper .loading-blenda #pcs-spinner {
  display: block;
}
.map-wrapper .loading-blenda.visible {
  display: block !important;
}
#ruch-statkow {
  height: calc(100vh - 151px);
  position: relative;
}
#pcs-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: transparent;
}
#pcs-spinner.visible {
  display: block !important;
}
.select2-selection__clear {
  z-index: 999;
  margin-right: 20px;
  display: none;
}
.relative {
  position: relative;
}
.object-data {
  padding: 40px 25px 15px 25px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #110047;
  display: flex;
  justify-content: center;
  align-items: center;
}
.object-data .for-img .svg {
  width: 8px;
  height: 8px;
}
.object-data .title {
  margin-bottom: 20px;
}
.object-data .desc {
  font-size: 12px;
  line-height: 17px;
  color: #110047;
  font-size: 12px;
  line-height: 22px;
}
.object-data a.newItem {
  color: unset;
  text-decoration: unset;
  transition: all 0.2s !important;
}
.object-data a.newItem:hover {
  transition: all 0.2s !important;
  color: #00ecc2;
  text-decoration: unset;
}
.gm-style-iw {
  padding: 0 !important;
}
.error-modal {
  background-color: #fff;
  opacity: 0;
  z-index: -1;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
}
.error-modal.visible {
  opacity: 1;
  z-index: 1;
}
#ruch-statkow .gm-style .gm-style-iw-t::after {
  top: -13px;
}
#ruch-statkow .gm-style-iw {
  border-radius: 30px;
  top: -12px;
}
#ruch-statkow .gm-style-iw div {
  overflow: hidden !important;
}
#ruch-statkow .gm-style-iw div .object-data {
  padding: 0 16px;
}
#ruch-statkow .gm-style-iw div .object-data .title {
  color: #411da9;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px;
  margin: 0;
}
#ruch-statkow .gm-style-iw div .object-data .img {
  width: 100%;
  height: 125px;
  margin-bottom: 10px;
}
#ruch-statkow .gm-style-iw div .object-data .desc {
  padding: 10px 10px 10px 10px;
  font-size: 14px;
}
#ruch-statkow .gm-style-iw div .object-data .desc .value {
  display: block !important;
  color: #929292;
}
#ruch-statkow .gm-style-iw button {
  z-index: 10;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
}
#ruch-statkow .gm-style-iw button img {
  display: none !important;
}
#ruch-statkow .gm-style-iw button:before,
#ruch-statkow .gm-style-iw button:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 15px;
  width: 2px;
  top: 10px;
  display: none;
}
#ruch-statkow .gm-style-iw button:before {
  transform: rotate(45deg);
}
#ruch-statkow .gm-style-iw button:after {
  transform: rotate(-45deg);
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #00ecc2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00ecc2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@-moz-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.margin.Extra.Small {
  margin-bottom: 40px;
}
.margin.Small {
  margin-bottom: 60px;
}
.margin.Medium {
  margin-bottom: 85px;
}
@media mobi {
  .margin.Medium {
    margin-bottom: 35px;
  }
}
.margin.Large {
  margin-bottom: 120px;
}
@media scr1024 {
  .margin.Large {
    margin-bottom: 80px;
  }
}
@media mobi {
  .margin.Large {
    margin-bottom: 50px;
  }
}
.margin.Extra.Large {
  margin-bottom: 240px;
}
@media scr1024 {
  .margin.Extra.Large {
    margin-bottom: 120px;
  }
}
@media mobi {
  .margin.Extra.Large {
    margin-bottom: 60px;
  }
}
.career-popup .shore-popup {
  width: 100vw;
  height: 100vh;
  border: none;
  top: 0px;
  left: 0px;
  background-color: rgba(17,0,71,0);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  z-index: 1;
  position: absolute;
}
.career-popup .pop-up {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(17,0,71,0.6);
  z-index: 999;
  display: none;
}
@media (max-width: 980px) {
  .career-popup .pop-up {
    background-color: #070047;
  }
}
.career-popup .pop-up .row {
  padding: 0;
}
.career-popup .pop-up .row-sides {
  padding-right: 15px;
  padding-left: 15px;
  width: 80%;
}
.career-popup .pop-up .row-sides .col-content {
  position: relative;
}
.career-popup .pop-up .row-sides .col-content .popup-wrapper {
  display: flex;
  flex-flow: column;
  position: absolute;
  border-radius: 15px;
  width: 100%;
  left: 40%;
  transform: translate(-50%, 10%);
  padding: 10 0;
  box-shadow: 3px 2px 0 4px rgba(17,0,71,0);
}
@media (max-width: 768px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper {
    transform: translate(-50%, 30%);
  }
}
@media (max-width: 616px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper {
    transform: translate(-50%, 5%);
  }
}
@media (max-width: 980px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .popup-media-scroll {
    padding: 0 50px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
  }
}
@media (max-width: 616px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .popup-media-scroll {
    max-height: 50vh;
  }
}
.career-popup .pop-up .row-sides .col-content .popup-wrapper .nano {
  width: 100vw;
  min-height: 100vh;
  background-color: none;
  z-index: 1;
}
@media (max-width: 980px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .nano {
    min-height: 650px;
  }
}
@media (max-width: 616px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .nano {
    min-height: 100vh;
  }
}
.career-popup .pop-up .row-sides .col-content .popup-wrapper .nano .gradeint-popup {
  background-image: radial-gradient(circle at 35% 35%, rgba(0,236,194,0.3), rgba(17,6,74,0.2), rgba(17,0,71,0.1));
  position: absolute;
  width: 80vw;
  min-height: 75%;
  border: none;
  top: 0px;
  left: 0;
  -webkit-border-top-right-radius: 40px;
  -moz-border-radius-topright: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
}
@media (max-width: 980px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .nano .gradeint-popup {
    height: 100%;
    top: -200px;
    width: 100vw;
  }
}
@media (max-width: 616px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .nano .gradeint-popup {
    left: 20px;
  }
}
@media (max-width: 266px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .nano .gradeint-popup {
    display: none;
  }
}
.career-popup .pop-up .row-sides .col-content .popup-wrapper .popup-content {
  height: 100%;
  width: 80%;
  padding: 0;
  display: flex;
  flex-flow: column;
  border: none;
  outline: none;
}
.career-popup .pop-up .row-sides .col-content .popup-wrapper .popup-content .wrapper-up-content {
  display: flex;
  flex-flow: column;
  padding: 50px;
  background-color: #110047;
  top: 50px;
  max-width: 100%;
  min-height: 700px;
  max-height: 3000px;
  border-top-right-radius: 20px;
}
@media (max-width: 980px) {
  .career-popup .pop-up .row-sides .col-content .popup-wrapper .popup-content .wrapper-up-content {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
  }
}
.subheader {
  margin-bottom: 40px;
}
.awesome-form {
  padding: 0;
}
.awesome-form .form {
  margin-bottom: 50px;
}
.awesome-form .form .hr-grey {
  border-top: solid border-width #110047;
  margin-right: 0;
  margin-left: 0;
}
.awesome-form .form input,
.awesome-form .form textarea {
  color: #110047;
}
.awesome-form .form input[type="text"],
.awesome-form .form textarea[type="text"],
.awesome-form .form input[type="email"],
.awesome-form .form textarea[type="email"] {
  border-bottom: solid border-width #110047;
}
.awesome-form .form .input-group input:focus[type="text"],
.awesome-form .form .input-group input:focus[type="email"] {
  border-bottom: solid border-width #110047;
}
.awesome-form .form .input-group textarea {
  padding-top: 31px;
}
.awesome-form .form .input-group input:focus + label,
.awesome-form .form .input-group input.has-value + label {
  color: #008000;
}
.awesome-form .form .input-group textarea:focus + label,
.awesome-form .form .input-group textarea.has-value + label {
  font-size: 14px;
  color: #008000;
}
h3 {
  font-size: 18px;
  font-weight: bold !important;
  margin-bottom: 26px;
}
@media (min-width: 991px) {
  h3 {
    font-size: 18px;
  }
}
.agree {
  margin-top: 26px;
}
@media (max-width: 575px) {
  .agree {
    margin-top: 7px;
  }
}
.mytable {
  width: 100%;
}
.mytable .mytd {
  display: table-cell;
}
.mytable .mynotification {
  margin-left: 34px;
}
.mytable.attachment .td {
  padding-bottom: 15px;
}
@media (max-width: 575px) {
  .mytable.attachment .td {
    padding-bottom: 12px;
  }
}
.mytable.attachment .td input[type="file"] {
  display: none;
}
.mytable.attachment .td input[type="file"] + label.full-width {
  width: 100%;
}
.mytable .td.file-container {
  margin-bottom: 15px;
  width: 70%;
}
@media (max-width: 575px) {
  .mytable .td.file-container {
    margin-bottom: 0;
  }
}
.mytable .td.notification {
  vertical-align: top;
}
.mytable .td.notification + .td {
  color: #110047;
  font-size: 10px;
  margin-bottom: 19px;
}
.mytable .td.notification + .td label {
  font-weight: 400 !important;
}
.mytable .td input[type="checkbox"] {
  display: none;
}
.mytable .td input[type="checkbox"] + label span {
  margin-right: 13px;
}
.mytable .td input[type="checkbox"] + label span.notification-box {
  display: block;
  width: 21px;
  height: 21px;
  background: #fff;
  border: 1px solid #008000;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.mytable .td input[type="checkbox"] + label span.notification-box span {
  display: block;
  background: #fff;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 6px;
  border: 1px solid #008000;
}
.mytable .td input[type="checkbox"]:checked + label span.notification-box {
  background: #110047;
}
@media (min-width: 991px) {
}
.button-container {
  position: relative;
  bottom: -34px;
  left: calc(50% - 87px);
}
input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
  z-index: 100;
}
.check1 {
  position: absolute;
  left: 0px;
  height: 12px;
  width: 12px;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
}
input:checked ~ .check1 {
  background-color: #00edc2;
  z-index: 100;
}
.check1:after {
  content: "";
  position: absolute;
  display: none;
  z-index: 100;
}
input ~ .check1 {
  background-color: #ccc;
  z-index: 100;
}
input:checked ~ .check1:after {
  display: block;
  z-index: 100;
  -webkit-box-shadow: 0px -1px 60px 0px #00ecc2;
  -moz-box-shadow: 0px -1px 60px 0px #00ecc2;
  box-shadow: 0px -1px 60px 0px #00ecc2;
}
.check1:after {
  top: 1px;
  left: 1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00edc2;
  border: 3px solid #110047;
  z-index: 100;
  -webkit-box-shadow: 0px -1px 60px 0px #00ecc2;
  -moz-box-shadow: 0px -1px 60px 0px #00ecc2;
  box-shadow: 0px -1px 60px 0px #00ecc2;
}
.errors {
  display: flex;
  flex-flow: column;
  padding: 0;
}
.errors li {
  list-style-type: none;
  padding: 0;
  color: #c79494;
  font-weight: 300 !important;
  font-size: 12px;
}
.popup2 {
  height: 100%;
  width: 100%;
  position: relative;
  top: 140px;
  display: flex;
  justify-content: flex-start;
  z-index: 999;
}
@media (max-width: 1024px) {
  .popup2 {
    top: 90px;
  }
}
@media (max-width: 980px) {
  .popup2 {
    top: 10px;
  }
}
@media (max-width: 616px) {
  .popup2 {
    padding: 0 20px 0 20px;
  }
}
.popup2 .element-content-box {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  background-color: transparent;
}
.popup2 .element-content-box .main-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  align-items: flex-start;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .main-header {
    margin: 20px auto 0 auto;
  }
}
@media (max-width: 616px) {
  .popup2 .element-content-box .main-header {
    flex-flow: column;
    justify-content: flex-start;
    margin: 10px auto 0 auto;
  }
}
@media (max-width: 375px) {
  .popup2 .element-content-box .main-header {
    min-height: 160px;
  }
}
.popup2 .element-content-box .main-header .exit-cross {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 100;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .main-header .exit-cross {
    top: 30px;
    right: 30px;
  }
}
@media (max-width: 375px) {
  .popup2 .element-content-box .main-header .exit-cross {
    top: 10px;
    right: 10px;
  }
}
.popup2 .element-content-box .main-header .exit-cross::before {
  position: absolute;
  right: 12px;
  top: 2px;
  content: '';
  height: 22px;
  width: 2px;
  background-color: #fff;
  transform: rotate(45deg);
}
.popup2 .element-content-box .main-header .exit-cross::after {
  position: absolute;
  right: 12px;
  top: 2px;
  content: '';
  height: 22px;
  width: 2px;
  background-color: #fff;
  transform: rotate(-45deg);
}
.popup2 .element-content-box .main-header h1 {
  margin: 0 43px 0 0px;
  color: #fff;
  font-size: 52.95px;
  font-weight: 100 !important;
  position: relative;
}
@media (max-width: 616px) {
  .popup2 .element-content-box .main-header h1 {
    width: 100%;
    margin: 0;
  }
}
.popup2 .element-content-box .main-header .text-header {
  width: 100%;
  padding: 0 0 20px 0;
  display: flex;
  flex-flow: column;
}
.popup2 .element-content-box .main-header .text-header h2 {
  color: #fff;
  align-items: flex-end;
  font-weight: 400 !important;
  font-size: 36px;
  margin: 0;
  padding: 0px 0 0 0;
}
@media (max-width: 616px) {
  .popup2 .element-content-box .main-header .text-header h2 {
    font-size: 30px;
  }
}
.popup2 .element-content-box .main-header .text-header .sub-header {
  padding: 0;
  font-family: "frank-new", "sans-serif" !important;
  min-height: 50px;
}
.popup2 .element-content-box .main-header .text-header .sub-header h1 {
  color: #00edc2 !important;
  font-size: 24px !important;
  font-weight: 100 !important;
  font-family: "frank-new", "sans-serif" !important;
}
@media (max-width: 616px) {
  .popup2 .element-content-box .main-header .text-header .sub-header h1 {
    font-size: 18px !important;
  }
}
.popup2 .element-content-box .contact-form-wrapper {
  margin: 20px 0 0 100px;
}
@media (max-width: 1024px) {
  .popup2 .element-content-box .contact-form-wrapper {
    overflow: auto;
  }
}
@media (max-width: 616px) {
  .popup2 .element-content-box .contact-form-wrapper {
    margin: 20px 0 0 0;
  }
}
@media (max-width: 375px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll {
    margin: 30px 0 0 0;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  padding: 5px 0 0 0;
  z-index: 100;
  font-family: "frank-new", "sans-serif" !important;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label {
    flex-flow: column;
    justify-content: flex-start;
    text-align: left;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label p {
  min-width: 140px;
  padding: 10px 0;
  margin: 0 20px 0 0;
  height: 100%;
  text-align: right;
  color: #fff;
  font-family: "frank-new", "sans-serif" !important;
  font-size: 15.78px;
  font-weight: 700 !important;
  position: relative;
  z-index: 100;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label p {
    text-align: left;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text] {
  height: 31px;
  width: 100%;
  margin: 0 20px 0 0;
  color: #00edc2;
  background-color: rgba(0,236,194,0.2);
  font-family: "frank-new", "sans-serif" !important;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  font-size: 14px;
  font-family: "Frank New";
  outline: none;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: 300 !important;
  z-index: 100;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text]::-webkit-input-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text]::-moz-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text]:-ms-input-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text]:-moz-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text]:placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text]:focus {
  background-color: #00edc2;
  color: #fff;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label input[type=text] {
    margin: 20px 0 0;
    padding: 10px;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup {
  width: 100%;
  margin: 0 20px 0 0;
  height: 30px;
  padding: 5px 0 5px 9px;
  font-family: frank-new, sans-serif;
  font-size: 14px;
  font-weight: 300 !important;
  cursor: pointer;
  -ms-color: #110047;
  color: #00edc2;
  background-color: rgba(0,236,194,0.2);
  border: none;
  outline: none;
  font-weight: 400 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 100;
  resize: none;
  min-height: 30px;
  max-height: 162px;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup:focus {
  color: #fff !important;
  background-color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup::-webkit-input-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup::-moz-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup:-ms-input-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup:-moz-placeholder {
  color: #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup:placeholder {
  color: #00edc2;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll label .textarea-popup {
    height: 50px;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements {
  display: flex;
  flex-flow: row;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements {
    flex-flow: column;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements {
  display: flex;
  flex-flow: column;
  color: none;
  width: calc((100% / 3) - 20px);
  z-index: 100;
  margin: 0 0 42px 0;
  position: relative;
  border-radius: none;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements input[type="file"] {
  border: none;
  background: none;
  display: none;
}
@media (max-width: 1024px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements {
    width: 96%;
  }
}
@media (max-width: 768px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements {
    width: 100%;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements .form-control {
  background-color: #fff;
  border-radius: none;
  position: absolute;
  top: 0;
  height: 31px;
  color: #00edc2;
  background-color: rgba(0,236,194,0.2);
  font-family: "frank-new", "sans-serif" !important;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  font-family: "Frank New";
  outline: none;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: 300 !important;
  z-index: 100;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements .form-control {
    width: 100%;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements .custom-file-upload {
  background-color: #fff;
  border-radius: none;
  width: 370px;
  position: absolute;
  top: 0;
  height: 31px;
  color: #00edc2;
  background-color: rgba(0,236,194,0.2);
  font-family: "frank-new", "sans-serif" !important;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  font-family: "Frank New";
  outline: none;
  box-sizing: border-box;
  text-align: left;
  justify-content: space-between;
  font-weight: 300 !important;
  z-index: 100;
}
@media (max-width: 1024px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements .custom-file-upload {
    width: 100%;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .attachements-elements .elements .add-file-elements2 {
  display: flex;
  flex-flow: column;
  color: none;
  width: 100%;
  z-index: 100;
  position: relative;
  border-radius: none;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .input-radio-popup {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  font-size: 10px;
  z-index: 100;
  position: relative;
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .input-radio-popup .check1 {
  display: flex;
  margin: 0 0 0 10px;
  position: absolute;
  top: 5px;
  left: 149px;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .input-radio-popup .check1 {
    left: 0px;
  }
}
.popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .input-radio-popup p {
  color: #00edc2;
  font-family: "frank-new", "sans-serif" !important;
  font-size: 10px;
  text-align: left;
  padding: 0;
  margin: 0 0 0 165px;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .popup-media-scroll .input-radio-popup p {
    margin: 0 0 0 20px;
  }
}
.popup2 .element-content-box .contact-form-wrapper .button-popup {
  padding: 0 0 0 150px;
  margin: 0 0 100px 0;
  display: flex;
  z-index: 100;
}
@media (max-width: 980px) {
  .popup2 .element-content-box .contact-form-wrapper .button-popup {
    padding: 0;
    margin: 0;
    flex-flow: flex-start;
  }
}
@media (max-width: 768px) {
  .popup2 .element-content-box .contact-form-wrapper .button-popup {
    padding: 0;
    margin: 0;
  }
}
@media (max-width: 400px) {
  .popup2 .element-content-box .contact-form-wrapper .button-popup {
    padding: 0;
  }
}
.popup2 .element-content-box .contact-form-wrapper .button-popup .applay {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  width: 242px;
  height: 42px;
  border: 1px solid #00edc2;
  margin: 30px 0 0 0;
  padding: 0;
  border-radius: 30px;
  background-color: none;
  outline: none;
  font-size: 14px;
  color: #fff;
  font-weight: 400 !important;
  z-index: 100;
}
@media (max-width: 400px) {
  .popup2 .element-content-box .contact-form-wrapper .button-popup .applay {
    width: 192px;
    margin: 25px 0;
  }
}
.popup2 .element-content-box .contact-form-wrapper .button-popup .applay:hover {
  background-color: rgba(17,0,71,0.4);
  -webkit-box-shadow: 0px 0px 33px -4px #00edc2;
  -moz-box-shadow: 0px 0px 33px -4px #00edc2;
  box-shadow: 0px 0px 33px -4px #00edc2;
}
.popup2 .element-content-box .contact-form-wrapper .button-popup .applay button {
  text-decoration: none;
  display: block;
  width: 242px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 100;
  background: none;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}
.popup2 .element-content-box .contact-form-wrapper .recaptcha-careerform {
  margin: 0 0 0 162px;
}
@media (max-width: 768px) {
  .popup2 .element-content-box .contact-form-wrapper .recaptcha-careerform {
    margin: 0px;
  }
}
.rc-anchor {
  margin: 0 0 0 162px;
}
.select2-results__options {
  display: flex;
  width: 100px;
  position: absolute;
  left: -50px;
  top: 14px;
}
.select2-search--dropdown {
  display: none !important;
}
header .header-bg-img .sticky {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 5px 13px -7px rgba(0,0,0,0.5);
  padding-top: 10px;
  align-items: center;
  z-index: 999;
  transition: background-color 0.2s ease;
}
header .header-bg-img nav {
  color: #fff;
  margin: auto;
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  z-index: 100;
  position: relative;
}
header .header-bg-img nav.shadow {
  box-shadow: 0 9px 0 0 rgba(0,0,0,0.1);
  background-color: #fff;
}
header .header-bg-img nav.padding {
  padding: 50px 0 0;
}
header .header-bg-img nav .rwd-nav-stripe {
  position: absolute;
  display: none;
}
header .header-bg-img nav a {
  z-index: 999;
}
header .header-bg-img nav a .logo-sticky {
  display: none;
}
header .header-bg-img nav a .logo {
  height: 85px;
  visibility: visible !important;
  z-index: 1;
  left: -60px;
  position: relative;
  top: -24px;
}
@media (max-width: 1024px) {
  header .header-bg-img nav a .logo {
    top: -3px;
    height: 50px !important;
  }
}
header .header-bg-img nav a .logo.new-styles {
  height: 80px;
}
header .header-bg-img nav a .logo-sticky-mobile {
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  margin-top: -15px;
}
header .header-bg-img nav .menu-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
header .header-bg-img nav .menu-wrapper ul {
  color: #fff;
  font-size: 16px;
  font-family: frank-new, sans-serif;
  font-weight: 300 !important;
  font-style: light;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-bottom: 15px;
  margin-bottom: 0;
  position: relative;
  border-bottom: 1px solid transparent;
}
header .header-bg-img nav .menu-wrapper ul li {
  display: flex;
  justify-content: space-around;
  margin: auto 16px;
}
header .header-bg-img nav .menu-wrapper ul li:first-child {
  margin-left: 0;
}
header .header-bg-img nav .menu-wrapper ul li:last-child {
  margin-right: 0;
}
header .header-bg-img nav .menu-wrapper ul li:hover {
  cursor: pointer;
  color: #00edc2;
}
header .header-bg-img nav .menu-wrapper ul li.active {
  color: #00edc2;
}
header .header-bg-img nav .menu-wrapper ul li a {
  text-decoration: none;
  color: #fff;
  position: relative;
}
header .header-bg-img nav .menu-wrapper ul li a:hover,
header .header-bg-img nav .menu-wrapper ul li a.active {
  color: #00edc2 !important;
}
header .header-bg-img nav .menu-wrapper ul li .white-menu {
  position: absolute;
  width: 200vw;
  height: 0;
  background: #fff;
  top: 45px;
  box-shadow: 0 5px 13px -7px rgba(0,0,0,0.5);
  display: inline-block;
  transition: height 0.2s ease;
}
@media (max-width: 1240px) {
  header .header-bg-img nav .menu-wrapper ul li .white-menu {
    top: 49px;
    height: 0;
  }
}
header .header-bg-img nav .menu-wrapper ul li .dropdown {
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: -25px;
  top: 40px;
  overflow: hidden;
  display: none;
  display: flex;
  flex-direction: row;
  border: none;
  min-width: 5rem;
  padding: 14px 110px 50px 25px;
  transition: all 0.5s ease;
}
header .header-bg-img nav .menu-wrapper ul li .dropdown:after {
  content: '';
  width: 75%;
  height: 0;
  position: absolute;
  right: 9%;
  top: 0;
  box-shadow: 0 0 100px 4px #00edc2;
  border-radius: 50%;
}
header .header-bg-img nav .menu-wrapper ul li .dropdown li {
  width: fit-content !important;
  white-space: nowrap;
}
header .header-bg-img nav .menu-wrapper ul li .dropdown li:before {
  content: '';
  background: transparent;
  width: 0;
}
header .header-bg-img nav .menu-wrapper ul li .dropdown li a {
  position: relative;
}
@media (max-width: 1024px) {
  header .header-bg-img nav .menu-wrapper ul li .dropdown li:first-child {
    width: 100%;
  }
}
header .header-bg-img nav .menu-wrapper .panel-menu {
  margin-left: 40px;
  border: none;
  align-items: center;
  font-size: 14px;
  font-family: frank-new, sans-serif;
  font-weight: 300 !important;
  font-style: light;
}
header .header-bg-img nav .menu-wrapper .panel-menu li {
  transition: all 0.3s linear;
  font-size: 14px;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:hover:before {
  background: transparent;
  border: none;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:first-child {
  margin-right: 0;
  font-size: 16px;
  background-color: #00edc2;
  padding: 5px 15px;
  border: solid 1px #00edc2;
  border-radius: 20px;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:first-child a {
  color: #fff !important;
  font-weight: 500 !important;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:first-child:hover {
  box-shadow: 0px 0px 10px 1px #00edc2;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(2) {
  margin-right: 0;
  background-color: #fff;
  padding: 5px 23px;
  border: solid 1px #00edc2;
  border-radius: 20px;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(2) a {
  font-weight: 400 !important;
  color: #309;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(2):hover {
  box-shadow: 0px 0px 10px 1px #00edc2;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(3) {
  margin-right: 3px;
  height: 33px;
  width: 33px;
  border: solid 1px #00edc2;
  border-radius: 20px;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(3):hover {
  box-shadow: 0px 0px 10px 1px #00edc2;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(4) {
  margin-left: 3px;
  height: 33px;
  width: 33px;
  border: solid 1px #00edc2;
  border-radius: 20px;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(4):hover {
  box-shadow: 0px 0px 10px 1px #00edc2;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) {
  visibility: hidden;
  display: none;
  border-bottom: 1px solid transparent;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-search__field {
  display: none;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container {
  display: inline;
  background: transparent;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container .select2-selection {
  border: none;
  height: 40px;
  background: rgba(0,0,0,0);
  background: url("../img/arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: 16px !important;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container .select2-selection span:first-child {
  color: #fff;
  line-height: 40px;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container .selection {
  display: inline-block;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container .selection span .select2-selection__arrow {
  display: none;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container--open .select2-dropdown .select2-dropdown--below {
  background: rgba(0,0,0,0);
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container--open .select2-dropdown .select2-dropdown--below .select2-results ul li {
  color: #fff;
  display: flex !important;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container--open .select2-dropdown .select2-dropdown--below .select2-dropdown {
  display: inline;
  width: auto;
  background: rgba(0,0,0,0);
  border: none;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) .select2-container--open .select2-dropdown .select2-dropdown--below .select2-search input:first-child {
  display: none;
}
header .header-bg-img nav .menu-wrapper .panel-menu li:nth-child(5) span.select2-selection.select2-selection--single:focus {
  outline: none;
}
header .header-bg-img nav .menu-wrapper .panel-menu li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
header .header-bg-img nav .menu-wrapper .panel-menu li a .svg {
  width: 42%;
  height: 42%;
  fill: #fff;
}
.blue-menu a {
  color: #411da9 !important;
}
@media (max-width: 1024px) {
  .blue-menu a {
    color: #fff !important;
  }
}
@media (max-width: 1600px) {
  header .header-bg-img nav .menu-wrapper ul.main-menu {
    margin-right: 45px;
  }
  header .header-bg-img nav .menu-wrapper ul.panel-menu {
    margin-right: auto;
  }
}
@media (max-width: 1440px) {
  header .header-bg-img nav .menu-wrapper ul.main-menu {
    margin-right: 0;
  }
  header .header-bg-img nav .menu-wrapper ul.panel-menu {
    margin-right: auto;
    margin-left: 20px;
  }
}
@media (max-width: 1245px) {
  body header .header-bg-img {
    background-position: 50% 105%;
    background-size: auto;
  }
  body header .header-bg-img nav {
    padding-top: 25px;
    margin-bottom: 46px;
    justify-content: space-around;
  }
  body header .header-bg-img nav .logo {
    height: 50px;
    left: -60px !important;
    top: -7px !important;
  }
  body header .header-bg-img nav ul.main-menu {
    margin-right: auto;
    font-size: 14px;
  }
  body header .header-bg-img nav ul.main-menu li {
    margin: auto 12px;
  }
  body header .header-bg-img nav ul.main-menu li .dropdown {
    font-size: 12px;
  }
  body header .header-bg-img nav ul.panel-menu {
    font-size: 13px;
    margin-left: 15px;
  }
  body header .header-bg-img nav ul.panel-menu li:nth-child(3) {
    margin-right: 1px;
  }
  body header .header-bg-img nav ul.panel-menu li:nth-child(4) {
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  header {
    padding: 0;
  }
  header .header-bg-img {
    background-position: 72% 104%;
    background-size: auto;
  }
  header .header-bg-img .bottomstrip {
    bottom: 0;
  }
  header .header-bg-img nav {
    visibility: hidden;
    justify-content: space-around !important;
    background: transparent;
    z-index: 10;
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    padding-top: 25px !important;
  }
  header .header-bg-img nav #box {
    height: 100vh;
    width: 2px;
    top: -20px;
    position: absolute;
    z-index: 0;
    background: #110047 !important;
    transition: height 0.3s linear;
  }
  header .header-bg-img nav .rwd-nav-stripe {
    width: 100%;
    display: flex;
    visibility: hidden;
    top: 0;
    height: 60px;
    background: #110047;
  }
  header .header-bg-img nav img.logo {
    visibility: visible !important;
    margin-right: 0;
    z-index: 1 !important;
  }
  header .header-bg-img nav .menu-wrapper {
    flex-direction: column;
    opacity: 0;
    transition: all 0.3s;
  }
  header .header-bg-img nav .menu-wrapper ul.main-menu {
    flex-direction: column;
    align-self: flex-start;
    margin: 15vh 0 0 10%;
    padding-right: 2em;
    font-size: 16px;
    box-sizing: border-box;
  }
  header .header-bg-img nav .menu-wrapper ul.main-menu li {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: start;
  }
  header .header-bg-img nav .menu-wrapper ul.main-menu li:hover:before {
    background: transparent;
    border: none;
  }
  header .header-bg-img nav .menu-wrapper ul.main-menu li .dropdown {
    border-left: solid 1px #fff;
    flex-direction: column;
    padding: 0 0 0 30px;
    font-size: 16px;
    top: 0;
    margin-left: 135px;
  }
  header .header-bg-img nav .menu-wrapper ul.main-menu li .dropdown:after {
    box-shadow: none;
  }
  header .header-bg-img nav .menu-wrapper ul.panel-menu {
    margin-left: 0;
  }
  header .header-bg-img nav .menu-wrapper ul.panel-menu li:first-child {
    margin-left: 0;
  }
  .selected-value {
    color: #fff;
  }
  .selected-value:before {
    content: none;
  }
}
@media (max-width: 1024px) {
  body header .header-bg-img nav .logo {
    height: 35px;
    left: 10% !important;
    margin-right: 0;
    transform: translateY(11px);
  }
  body header .header-bg-img .menu-wrapper .panel-menu {
    height: 140px;
    background: #110047;
    bottom: 25px;
    padding: 0;
    justify-content: center;
  }
  body header .header-bg-img .menu-wrapper .main-menu {
    height: calc(100vh - 335px);
    overflow: scroll;
    justify-content: flex-start;
  }
}
@media (max-width: 540px) {
  body header .header-bg-img .border-top ul li:nth-child(2n) {
    visibility: hidden;
  }
}
#dropdown-login-panel {
  color: #309;
  position: relative;
  height: 100%;
}
#dropdown-login-panel:hover .log-in-text {
  color: #00edc2 !important;
}
#dropdown-login-panel .log-in-text {
  font-size: 16px;
  font-family: frank-new !important;
  font-weight: 400;
  transition: all 0.3s;
}
.dropdown-panel-visible .dropdown-panel {
  pointer-events: unset !important;
  height: 140px;
  opacity: 1 !important;
}
.dropdown-panel-visible .dropdown-panel a {
  opacity: 1;
  pointer-events: unset !important;
  z-index: unset;
}
.dropdown-panel-visible .dropdown-panel a:hover {
  color: #00edc2 !important;
}
.dropdown-panel {
  pointer-events: none !important;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 23px;
  top: 45px;
  right: 0;
  background-color: #fff;
  border: 1px solid #00edc2;
  border-radius: 17px;
  width: fit-content !important;
  transition: all 0.3s;
  font-size: 12px;
  font-family: frank-new !important;
  height: 0;
  opacity: 0;
}
@media (max-width: 1024px) {
  .dropdown-panel {
    top: -150px !important;
  }
}
.dropdown-panel .panel-links {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.dropdown-panel a {
  justify-content: flex-end !important;
  padding: 5px 0;
  color: #309 !important;
  width: fit-content !important;
  height: fit-content !important;
  white-space: nowrap;
  text-align: right;
  transition: all 0.3s;
  pointer-events: none;
  font-weight: 400;
  opacity: 0;
  z-index: -1;
}
.dropdown-panel:hover {
  box-shadow: 0px 0px 10px 1px #00edc2;
}
@media (min-width: 1025px) {
  div.sidebar-menu-toggle {
    display: none !important;
  }
}
div.sidebar-menu-toggle {
  position: absolute;
  display: flex;
  visibility: visible;
  cursor: pointer;
  overflow: hidden;
  opacity: 1;
  width: 29px;
  height: 36px;
  top: 42px;
  right: 10%;
  z-index: 9999;
  transition: all 0.3s;
}
div.sidebar-menu-toggle div.sidebar-menu-btn {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  height: 100%;
  width: 100%;
}
div.sidebar-menu-toggle div.sidebar-menu-btn div.sidebar-menu-btn-line {
  display: block;
  background: #00edc2;
  transition: all 0.3s;
  width: 100%;
  height: 7px;
}
div.sidebar-menu-toggle div.sidebar-menu-btn div.sidebar-menu-btn-line.btn-line-3 {
  position: relative;
  z-index: 8;
  width: 7px;
  overflow: hidden;
  background: transparent;
  transform: translateX(22px) rotate(90deg);
}
div.sidebar-menu-toggle div.sidebar-menu-btn div.sidebar-menu-btn-line.btn-line-3 .sidebar-menu-btn-triangle {
  transition: all 0.3s;
  position: absolute;
  height: 100%;
  width: 15px;
  right: 0;
  background: #00edc2;
  transform: rotate(45deg);
}
div.sidebar-menu-toggle.active div.sidebar-menu-btn div.sidebar-menu-btn-line.btn-line-1 {
  transform: translateX(21px);
}
div.sidebar-menu-toggle.active div.sidebar-menu-btn div.sidebar-menu-btn-line.btn-line-3 {
  transform: translateX(0px) rotate(90deg);
}
.logo-refs {
  position: absolute;
  left: 10%;
}
.logo-refs img {
  margin: 0 !important;
}
@media (max-width: 1024px) {
  nav div.menu-wrapper {
    margin: 0 !important;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    top: 0 !important;
  }
  nav div.menu-wrapper ul.main-menu {
    width: 90%;
  }
  nav div.menu-wrapper ul.main-menu li {
    border-bottom: 1px solid #2d1b67;
  }
  nav div.menu-wrapper ul.main-menu li:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
  nav div.menu-wrapper ul.main-menu li.main-menu-items {
    flex-direction: column !important;
  }
  nav div.menu-wrapper ul.main-menu li.main-menu-items a.first-link {
    padding-bottom: 11px;
  }
  nav div.menu-wrapper ul.main-menu li.main-menu-items ul.dropdown {
    border-top: 1px solid #2d1b67 !important;
    border-bottom: none;
    border-left: none;
    position: unset !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.3s;
    width: 100%;
    height: 0;
    max-height: 0;
    margin-left: 0 !important;
  }
  nav div.menu-wrapper ul.main-menu li.main-menu-items ul.dropdown li {
    padding: 10px 0;
    margin: 0 !important;
    border-bottom: none;
    position: relative;
  }
  nav div.menu-wrapper ul.main-menu li.main-menu-items ul.dropdown li::before {
    content: '';
    background-color: #2d1b67;
    width: 10px;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    left: -30px;
  }
  nav div.menu-wrapper ul.main-menu li.main-menu-items ul.dropdown.show-up {
    height: fit-content !important;
    max-height: 300px;
  }
  nav div.menu-wrapper ul.panel-menu {
    position: absolute;
    z-index: 9999999;
    bottom: 5%;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start !important;
    flex-direction: row-reverse;
    padding: 0 10%;
  }
  nav div.menu-wrapper ul.panel-menu li {
    white-space: nowrap;
    width: 170px;
    height: 32px !important;
    margin: 0 !important;
    border-bottom: 1px solid #2d1b67;
  }
  nav div.menu-wrapper ul.panel-menu li#dropdown-login-panel {
    margin-right: 10px !important;
    align-items: center;
  }
  nav div.menu-wrapper ul.panel-menu li#dropdown-login-panel span.log-in-text {
    height: fit-content;
  }
}
.homebanner {
  font-size: 18px;
  font-family: frank-new, sans-serif;
  font-weight: 500 !important;
  font-style: medium;
  margin: 0;
}
.homebanner header {
  height: 880px;
  padding: 20px;
  overflow: hidden;
  background-image: linear-gradient(rgba(17,0,71,0.46), rgba(17,0,71,0.46) 70%, rgba(255,255,255,0.5) 80%, rgba(255,255,255,0.85) 88%, #fff 95%), url("../img/bg_image.png");
  background-size: cover;
  position: relative;
}
.homebanner header::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
/* margin-top: -810px; dddddd*/
/* display: block; */
  width: 100%;
  height: 300px;
  background: linear-gradient(rgba(255,255,255,0) 0%, #fff 60%, #fff 100%);
}
.homebanner header .blendmode {
  height: 880px;
  width: 100%;
  position: absolute;
  background: rgba(17,0,71,0.66);
}
.homebanner header .header-bg-img .single-slide-seashore2 {
  background-image: url("../img/banner_seashore.svg");
  background-repeat: no-repeat;
  background-position: 50% 105%;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}
.homebanner header .header-bg-img .title {
  color: #fff;
  width: 80%;
  max-width: 1440px;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  left: 49%;
}
.homebanner header .header-bg-img .title h1 {
  font-size: 80px;
  margin-bottom: 0px;
  padding: 0px 100px;
}
.homebanner header .header-bg-img .title h2 {
  font-size: 40px;
  font-weight: 200 !important;
  margin-bottom: 110px;
  padding: 0px 100px;
}
@media (min-width: 2190px) {
  .homebanner header .header-bg-img .single-slide-seashore2 {
    background-position: 50% 50% !important;
    background-size: cover !important;
  }
}
@media (min-width: 2048px) {
  .homebanner header .header-bg-img .title {
    left: 50%;
  }
}
@media (max-width: 1600px) {
  .homebanner header .header-bg-img .title {
    left: 49%;
  }
}
@media (max-width: 1024px) {
  .homebanner header {
    padding: 0px;
  }
  .homebanner header::after {
    margin-top: -850px !important;
  }
  .homebanner header .header-bg-img .title {
    position: absolute;
    left: 50%;
    top: 42%;
  }
  .homebanner header .header-bg-img .title h1 {
    font-size: 60px;
    line-height: auto;
    margin-bottom: 15px;
  }
  .homebanner header .header-bg-img .title h2 {
    font-size: 30px;
    padding-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .homebanner header .header-bg-img .title {
    width: 90%;
    top: 38%;
  }
  .homebanner header .header-bg-img .title h1 {
    padding: 0 40px;
  }
  .homebanner header .header-bg-img .title h2 {
    padding: 0 40px;
  }
}
@media (max-width: 616px) {
  .homebanner header .header-bg-img .title {
    top: 37%;
    width: 100%;
  }
  .homebanner header .header-bg-img .title h1 {
    font-size: 45px;
    line-height: 1.1em;
  }
  .homebanner header .header-bg-img .title h2 {
    font-size: 20px;
  }
}
.light-green {
  border-radius: 20px;
  background-color: #00ecc2;
  padding: 10px 25px;
  max-width: 190px;
  border: none;
  outline: none;
}
.light-green * {
  font-size: 14px;
  color: #110047;
}
.light-green a:hover {
  text-decoration: none;
}
.dark {
  border-radius: 20px;
  background-color: #110047;
  padding: 10px 25px;
  max-width: 120px;
  border: none;
  outline: none;
}
.dark * {
  font-size: 14px;
  color: #00ecc2;
}
.dark a:hover {
  text-decoration: none;
}
.input-field {
  width: 100%;
  max-width: 250px;
}
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 100%;
  top: -1px;
}
.custom-select-wrapper select {
  display: none;
}
.custom-select {
  position: relative;
  display: inline-block;
  height: 0;
  border: none;
  padding: 0;
  width: 100%;
}
.custom-select.opened.custom-select-trigger:after {
  transform: rotate(-135deg);
}
.custom-select.opened.custom-select-trigger .custom-select-trigger {
  position: relative;
  transform: translateY(-20%);
  display: flex;
  font-family: 'BasierCircle-SemiBold';
  align-items: center;
  width: 100%;
  padding: 0 22px 0 22px;
  font-size: 16px;
  font-weight: 300;
  border: 3px solid #008000;
  color: #4c31d5;
  background-color: #fff;
  line-height: 60px;
  max-height: 60px;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .custom-select.opened.custom-select-trigger .custom-select-trigger {
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    transform: translateY(-30%);
  }
}
.custom-select.opened.custom-select-trigger .custom-select-trigger::after {
  position: absolute;
  display: block;
  content: '';
  width: 9px;
  height: 9px;
  top: 50%;
  right: 10%;
  margin-top: -4px;
  border-bottom: 3px solid #008000;
  border-right: 3px solid #008000;
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
}
.custom-select.opened.custom-select-trigger .custom-options {
  position: absolute;
  display: block;
  top: 45px;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: 15px 0;
  padding: 10px 0;
  border: 3px solid #008000;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0,0,0,0.07);
  background: #fff;
  transition: all 0.5s ease-in-out;
  white-space: nowrap;
  width: max-content;
  cursor: pointer;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
@media (max-width: 1300px) {
  .custom-select.opened.custom-select-trigger .custom-options {
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
  }
}
@media (max-width: 1025px) {
  .custom-select.opened.custom-select-trigger .custom-options {
    top: 20px;
  }
}
.custom-select.opened.custom-select-trigger .custom-select.opened.custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.custom-select.opened.custom-select-trigger .option-hover::before {
  background: #f9f9f9;
}
.custom-select.opened.custom-select-trigger .custom-option {
  position: relative;
  display: block;
  z-index: 1;
  padding: 0 22px;
  font-size: 14px;
  font-weight: 600;
  color: #800080;
  line-height: 47px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.custom-select.opened.custom-select-trigger .custom-option:hover {
  background: $arc-purple;
  color: #fff;
}
.custom-select.opened.custom-select-trigger .custom-option.selection {
  background: $arc-purple;
  color: #fff;
}
.header-wrapper-home {
  margin: 90px auto 0 auto;
  width: 80% !important;
  max-width: 1140px;
  padding: 0;
  position: relative;
}
@media (max-width: 768px) {
  .header-wrapper-home {
    width: 100% !important;
  }
}
.operations-wrapper {
  margin: 90px auto 0 auto;
  width: 80% !important;
  max-width: 1140px;
  padding: 0;
  position: relative;
}
.operations-wrapper .header {
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .operations-wrapper .header {
    width: 80%;
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .operations-wrapper {
    width: 100%;
  }
}
.operations-wrapper .operations {
  display: flex;
  margin-top: 50px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media (max-width: 768px) {
  .operations-wrapper .operations {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.operations-wrapper .operations .input-field {
  max-width: 390px;
  margin-top: 35px;
}
.operations-wrapper .operations .input-field .select-box label {
  font-size: 14px;
  line-height: 30px;
  margin: 0 0 0 5px;
  color: #110047;
  font-weight: 400;
}
.operations-wrapper .operations .input-field .select2 .companies-select {
  background-color: #fff;
  border: none !important;
}
.operations-wrapper .operations .text-wrapper {
  padding: 80px 100px 100px;
  background-color: #f1f1f1;
  flex: 1;
  border-bottom-left-radius: 20px;
}
@media (max-width: 768px) {
  .operations-wrapper .operations .text-wrapper {
    border-bottom-left-radius: 0px;
    padding: 34px 10% 80px 10%;
  }
}
@media (max-width: 768px) {
  .operations-wrapper .operations .text-wrapper .text {
    flex-direction: column;
    align-items: flex-start;
  }
}
.operations-wrapper .operations .text-wrapper .picto {
  margin-right: 20px;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.operations-wrapper .operations .text-wrapper .picto img {
  width: 91px;
  height: 91px;
}
.operations-wrapper .operations .text-wrapper p {
  margin-top: 20px;
  font-size: 16px;
  color: #110047;
  font-weight: 400;
}
.operations-wrapper .operations .text-wrapper button.dark {
  margin-top: 25px;
  max-width: 100%;
}
.operations-wrapper .operations .image {
  flex: 1;
}
@media (max-width: 992px) {
  .operations-wrapper .operations .image {
    display: none;
  }
}
.operation-companies-wrapper {
  width: 80%;
  max-width: 1440px;
  margin: -400px auto 0;
  background: #fff;
  position: relative;
  border-top-right-radius: 20px;
}
@media (max-width: 616px) {
  .operation-companies-wrapper {
    width: 100% !important;
    border-top-left-radius: 20px;
  }
}
.operation-companies-wrapper .container {
  padding: 70px 100px 60px;
  flex-direction: column;
  color: #110047;
}
@media (max-width: 768px) {
  .operation-companies-wrapper .container {
    padding: 30px 40px 35px;
  }
}
.operation-companies-wrapper .container #top {
  margin: 0 !important;
}
.operation-companies-wrapper .container #top .container {
  padding: 0 !important;
}
.operation-companies-wrapper .container #top .container .wrapper-top {
  padding: 0 !important;
  margin-bottom: 30px;
}
.operation-companies-wrapper .container #top .container .wrapper-top .headers {
  line-height: 28px !important;
  word-spacing: 2px;
}
.operation-companies-wrapper .container #top .container .menu-intro {
  display: none !important;
}
.operation-companies-wrapper .container .text {
  font-size: 16px;
  line-height: 28px;
  width: 100%;
  font-weight: normal;
  margin-bottom: 65px;
}
@media (max-width: 685px) {
  .operation-companies-wrapper .container .text {
    padding: 0px 45px 0px;
  }
}
.operation-companies-wrapper .container .selects {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1023px) {
  .operation-companies-wrapper .container .selects {
    flex-wrap: wrap;
  }
}
.operation-companies-wrapper .container .selects .select-box {
  margin-left: 20px;
  width: 300px;
}
@media (max-width: 1012px) {
  .operation-companies-wrapper .container .selects .select-box {
    margin-left: 0;
  }
}
.operation-companies-wrapper .container .selects .select-box:nth-child(1) {
  margin-left: 0;
}
.operation-companies-wrapper .container .selects .select-box:nth-child(2) {
  width: 300px;
}
.operation-companies-wrapper .container .selects .select-box:nth-child(3) {
  margin-top: 33px;
  width: 400px;
  display: flex;
  align-items: center;
}
.operation-companies-wrapper .container .selects .select-box:nth-child(3) .button-refresh-filter {
  padding: 0;
}
.operation-companies-wrapper .container .selects .select-box:nth-child(4) {
  display: flex;
  align-items: center;
}
.operation-companies-wrapper .container .selects .select-box label {
  font-size: 14px;
}
.operation-companies-wrapper .container .selects .select-box .show-button {
  max-width: 200px !important;
  height: 40px;
  padding: 0 20px;
  padding-bottom: 5px;
}
.operation-companies-wrapper .container .selects .select-box .button-refresh-filter {
  color: #0cedc7;
  font-size: 14px;
  font-weight: bold;
  padding-top: 30px;
  cursor: pointer;
}
.operation-companies-wrapper .container .categories {
  display: flex;
  margin: 45px 0 5px 13px;
}
@media (max-width: 590px) {
  .operation-companies-wrapper .container .categories {
    flex-wrap: wrap;
  }
}
.operation-companies-wrapper .container .categories .category {
  display: flex;
  margin-right: 30px;
}
@media (max-width: 850px) {
  .operation-companies-wrapper .container .categories .category {
    font-size: 14px;
  }
}
@media (max-width: 590px) {
  .operation-companies-wrapper .container .categories .category {
    margin-top: 20px;
  }
}
.operation-companies-wrapper .container .categories .category .symbol {
  margin-right: 20px;
  position: relative;
  z-index: 1;
  font-weight: bold;
}
.operation-companies-wrapper .container .categories .category .symbol:before {
  content: '';
  position: absolute;
  left: calc(50% - 15px);
  z-index: -1;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: #0cedc7;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
}
@media (max-width: 850px) {
  .operation-companies-wrapper .container .categories .category .symbol:before {
    top: -5px;
  }
}
.operation-companies-wrapper .container .info {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid #110047;
  border-bottom: 1px solid #110047;
  margin-top: 45px;
  padding: 25px 0;
  position: relative;
}
.operation-companies-wrapper .container .info .info-details {
  font-size: 18px;
  margin-right: 30px;
}
@media (max-width: 850px) {
  .operation-companies-wrapper .container .info .info-details {
    margin-right: 10px;
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .operation-companies-wrapper .container .info .info-details {
    width: 45%;
    font-size: 13px;
  }
}
.operation-companies-wrapper .container .info .info-details span {
  color: #00ecc2;
}
.operation-companies-wrapper .container .info .sort-symbols {
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}
.operation-companies-wrapper .container .info .sort-symbols .arrow-down {
  transform: rotate(180deg);
}
.select2 .selection .companies-select {
  border: 2px solid #411da9 !important;
  height: 48px !important;
  border-radius: 100px !important;
  padding: 4px;
  padding-left: 10px;
  position: relative !important;
  z-index: 4 !important;
}
@media (max-width: 420px) {
  .select2 .selection .companies-select {
    width: 270px !important;
  }
}
.select2 .selection .companies-select:focus {
  outline: none !important;
}
.select2 .selection .companies-select .select2-selection__rendered {
  color: #411da9 !important;
  font-size: 14px !important;
  font-weight: 500;
  padding-left: 15px !important;
  line-height: 36px;
}
.select2 .selection .companies-select .select2-selection__arrow {
  top: 6px !important;
  right: 13px !important;
}
.select2 .selection .companies-select .select2-selection__arrow b {
  background-image: url("../img/arrow-down.png");
  width: 35px !important;
  height: 30px !important;
  top: 3px;
  left: 0;
  border: none;
}
.select2-results__options::-webkit-scrollbar {
  width: 16px;
  background-clip: padding-box;
}
.select2-container--open .select2-dropdown {
  z-index: 3;
}
.select2-container--open b {
  transform: rotate(180deg);
  top: 0px !important;
  left: -1px !important;
}
.select2-container .companies-select-dropdown.no-border .select2-results {
  border: none !important;
}
.select2-container .companies-select-dropdown .select2-results {
  display: flex;
  height: auto;
  border: 2px solid #411da9 !important;
  border-top: none !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top: none;
  position: absolute;
  width: 100%;
  top: -26px;
  padding: 13px 0 0;
}
@media (max-width: 420px) {
  .select2-container .companies-select-dropdown .select2-results {
    width: 269px;
  }
}
.select2-container .companies-select-dropdown .select2-results ul {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: left;
  position: relative;
  left: 0;
  background-color: #fff;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top: none;
  max-height: 250px;
  padding: 10px 20px 10px;
  margin-bottom: 14px;
}
@media (max-width: 420px) {
  .select2-container .companies-select-dropdown .select2-results ul {
    width: 239px;
  }
}
.select2-container .companies-select-dropdown .select2-results ul li {
  color: #411da9 !important;
}
.select2-container .companies-select-dropdown .select2-results ul li.select2-results__option--highlighted {
  font-weight: bold;
}
.companies-wrapper {
  min-height: 300px;
}
@media (max-width: 890px) {
  .companies-wrapper {
    justify-content: space-between;
  }
}
.companies-wrapper .company-wrapper {
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  color: #110047;
  margin-top: 50px;
  padding: 25px 30px 20px 35px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 30% !important;
  margin-right: 3%;
  transition: background-color 1s ease-out;
}
@media (max-width: 1145px) {
  .companies-wrapper .company-wrapper {
    width: 47% !important;
  }
}
@media (max-width: 890px) {
  .companies-wrapper .company-wrapper {
    width: 49% !important;
    margin-right: 0;
  }
}
@media (max-width: 425px) {
  .companies-wrapper .company-wrapper {
    padding: 20px;
  }
}
.companies-wrapper .company-wrapper .title {
  font-size: 16px;
  font-weight: bold;
}
.companies-wrapper .company-wrapper .logo {
  width: 40px;
  height: 40px;
}
.companies-wrapper .company-wrapper .description {
  margin-top: 20px;
  font-size: 12px;
  line-height: 22px;
}
.companies-wrapper .company-wrapper a.newItem {
  color: unset;
  text-decoration: unset;
  transition: all 0.2s !important;
}
.companies-wrapper .company-wrapper a.newItem:hover {
  transition: all 0.2s !important;
  color: #00ecc2;
  text-decoration: unset;
}
.companies-wrapper .company-wrapper .map-marker {
  padding-top: 10px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
}
.companies-wrapper .company-wrapper .map-marker:hover {
  transition: all 0.2s;
  color: #00ecc2;
}
.companies-wrapper .company-wrapper .map-marker:hover svg {
  transition: all 0.2s;
}
.companies-wrapper .company-wrapper .map-marker:hover path {
  transition: all 0.2s;
  fill: #110047;
}
.companies-wrapper .company-wrapper .map-marker svg,
.companies-wrapper .company-wrapper .map-marker img {
  transition: all 0.2s;
  padding-right: 5px;
  width: 15px;
  height: 24px;
  padding-top: 10px;
}
.companies-wrapper .company-wrapper .map-marker svg path,
.companies-wrapper .company-wrapper .map-marker img path {
  transition: all 0.2s;
  fill: #00ecc2;
}
.newsPagination {
  margin-top: 65px;
  text-align: center;
  font-size: 12px;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.newsPagination::before {
  content: '';
  background: #00edc2;
  width: 100px;
  height: 1px;
  position: absolute;
  left: calc(50% - 50px);
  top: 44%;
  opacity: 0.3;
}
.newsPagination a {
  color: #110047 !important;
  padding: 4px 8px;
  font-weight: normal;
}
.newsPagination .disabled,
.newsPagination a:last-child {
  color: #00edc2 !important;
}
@media (max-width: 991px) {
  .z-index {
    position: relative;
    z-index: 2;
  }
}
.ships-movement-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .ships-movement-wrapper .container {
    padding: 30px 40px 35px !important;
  }
}
@media (max-width: 685px) {
  .ships-movement-wrapper .text {
    padding: 0 !important;
  }
}
.ships-movement-wrapper .box-button {
  align-self: flex-end;
}
@media (max-width: 1012px) {
  .ships-movement-wrapper .box-button {
    margin-top: 25px;
  }
}
.content-title {
  margin-top: 20px;
}
@media (max-width: 425px) {
  .content-title {
    font-size: 18px;
  }
}
.content-title span {
  font-weight: bold;
}
.ship-legend {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.ship-legend .legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
}
.ship-legend .legend-item label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #411da9;
}
.ship-legend .legend-item .svg {
  margin-right: 10px;
  width: 27px;
  height: 11px;
}
.ship-legend .legend-item .svg path {
  fill: inherit;
}
.ship-movement-info {
  margin-top: 10px !important;
}
.pictogram-wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: 1440px;
}
.pictogram-wrapper .container {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
}
.pictogram-wrapper .container .why-worth {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
}
.pictogram-wrapper .container .why-worth .header {
  width: 100%;
  display: flex;
}
@media (max-width: 768px) {
  .pictogram-wrapper .container .why-worth .header {
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 15px 0;
    padding: 0;
  }
}
.pictogram-wrapper .container .why-worth .header h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  padding: 0;
  font-weight: 100 !important;
}
.pictogram-wrapper .container .why-worth .header h2 {
  color: #110047;
  padding: 0px 0 0 0;
  margin: 0;
  height: 100%;
  font-weight: 400 !important;
}
@media (max-width: 768px) {
  .pictogram-wrapper .container .why-worth .header h2 {
    padding: 0 0 0 3px;
  }
}
.pictogram-wrapper .container .why-worth .header h2 p {
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
}
.pictogram-wrapper .container .why-worth .boxes {
  display: flex;
  flex-flow: wrap;
  padding: 0;
  margin: 0;
}
@media (max-width: 599px) {
  .pictogram-wrapper .container .why-worth .boxes {
    width: 100%;
  }
}
.pictogram-wrapper .container .why-worth .boxes .box {
  width: 33.3%;
  display: flex;
  flex-flow: column;
  color: #110047;
  justify-content: flex-start;
  align-items: center;
  padding: 81px 10px 0 10px;
  margin: 0;
}
@media (max-width: 768px) {
  .pictogram-wrapper .container .why-worth .boxes .box {
    padding: 40px 10px 0 10px;
  }
}
@media (max-width: 599px) {
  .pictogram-wrapper .container .why-worth .boxes .box {
    width: 100%;
    margin: 30px auto 0 auto;
    flex-flow: row;
    justify-content: flex-start;
    padding: 0;
  }
}
.pictogram-wrapper .container .why-worth .boxes .box svg,
.pictogram-wrapper .container .why-worth .boxes .box .svg {
  width: 200px;
  height: auto;
  margin: 0 0 30px 0;
}
@media (max-width: 599px) {
  .pictogram-wrapper .container .why-worth .boxes .box svg,
  .pictogram-wrapper .container .why-worth .boxes .box .svg {
    margin: 0 30px 0 0;
  }
}
.pictogram-wrapper .container .why-worth .boxes .box div {
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  padding: 0 10px;
  margin: 0;
}
@media (max-width: 599px) {
  .pictogram-wrapper .container .why-worth .boxes .box div {
    width: 100%;
    text-align: left;
  }
}
.general-mockup-wrapper {
  width: 80%;
  margin: -200px auto 0 auto;
  max-width: 1440px;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper {
    margin: 0 auto;
  }
}
.general-mockup-wrapper .container {
  padding: 0;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.general-mockup-wrapper .container .what-is-pcs {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  max-height: 3000px;
}
.general-mockup-wrapper .container .what-is-pcs .left-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: flex-start;
  width: 54%;
  position: relative;
  margin: 0 0 20px 0;
  min-height: 1000px;
  z-index: 100;
}
@media (max-width: 4000px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box {
    min-height: 1400px;
  }
}
@media (max-width: 3000px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box {
    min-height: 900px;
  }
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box {
    min-height: 400px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box {
    margin: 0;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
  position: absolute;
  left: 75%;
  top: 0;
  width: 54vw;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 0;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
    display: none;
  }
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
    width: 100%;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  align-items: flex-start;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
  background-image: url("../../dist/img/polokrag_zielony.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  position: relative;
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header {
    background-image: none;
    flex-flow: column;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header h1 {
  margin: 0 43px 0 0px;
  color: #26009e;
  font-size: 52.95px;
  padding: 110px 0 0 0;
  font-weight: 100 !important;
  position: relative;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header h1 {
    padding: 65px 0 0 0;
  }
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header h1 {
    margin: 0;
    padding: 0;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper {
  width: 90%;
  display: flex;
  flex-flow: column;
  margin: 0 0 0px 0;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper {
    width: 100%;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper h2 {
  color: #110047;
  font-weight: 400 !important;
  font-size: 36px;
  padding: 80px 0 0 0;
  margin: 0;
  height: 100%;
  width: 40%;
  line-height: 46.02px;
  transform: translateY(7%);
}
@media (max-width: 1332px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper h2 {
    width: 50%;
  }
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper h2 {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper h2 {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper .h2 {
  font-size: 16px;
  font-weight: 400 !important;
  width: 80%;
  z-index: 100;
  line-height: 2em;
  margin: 30px 0 80px 0;
  color: #110047;
  padding: 22px 0 40px 0;
  hyphens: auto;
  position: relative;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper .h2 {
    width: 100%;
  }
}
@media (max-width: 1240) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper .h2 {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper .h2 {
    margin: 0;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  max-width: 530px;
  min-width: 530px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;
  font-weight: 500 !important;
  top: 0;
  left: 0;
}
@media (max-width: 1230px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button {
    height: 90%;
    max-width: 450px;
    min-width: 458px;
    position: absolute;
  }
}
@media (max-width: 757px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button {
    max-width: 400px;
    height: 100%;
    justify-content: center;
    position: absolute;
  }
}
@media (max-width: 768px) {
  .general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button {
    min-width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
  }
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button .button {
  margin: 100px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  z-index: 5;
  transition: all 0.3s linear;
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button .button a {
  text-decoration: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 42px;
  border: 1px solid #00edc2;
  padding: 0;
  border-radius: 50px;
  background-color: #fff;
  font-size: 14px;
  color: #110047;
  font-weight: 400 !important;
  margin: 0;
}
.general-mockup-wrapper .container .what-is-pcs .left-box .header-to-button .button a:hover {
  background-color: #c2ffef;
}
.rightBox2 {
  width: 100%;
  min-height: 100px;
  margin: 0 auto;
  z-index: 100;
  display: none;
}
@media (max-width: 1230px) {
  .rightBox2 {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 50px auto 0 auto;
  }
  .rightBox2 img {
    width: 100%;
    height: 100%;
  }
}
.users-wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: 1440px;
}
.users-wrapper .container {
  border-top-right-radius: 30px;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  padding: 0;
}
.users-wrapper .container .system-users {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
}
.users-wrapper .container .system-users .header {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .users-wrapper .container .system-users .header {
    flex-flow: column;
    width: 100%;
  }
}
.users-wrapper .container .system-users .header h1 {
  margin: 0 35px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
.users-wrapper .container .system-users .header .wrapper h2 {
  display: flex;
}
@media (max-width: 768px) {
  .users-wrapper .container .system-users .header .wrapper h2 {
    padding: 0;
    justify-content: flex-start;
  }
}
.users-wrapper .container .system-users .header .wrapper h2 p {
  margin: 0;
  padding: 0;
}
.users-wrapper .container .system-users .header .wrapper .description {
  width: 100%;
  font-size: 16px;
  font-weight: 400 !important;
  margin: 42px 0 20px 0;
  color: #110047;
  display: flex;
}
@media (max-width: 768px) {
  .users-wrapper .container .system-users .header .wrapper .description {
    margin: 20px 0;
  }
}
.users-wrapper .boxes-wrapper {
  border-top-right-radius: 30px;
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 100;
  position: relative;
}
@media (max-width: 1530px) {
  .users-wrapper .boxes-wrapper {
    width: 100%;
    max-height: 5000px;
  }
}
@media (max-width: 768px) {
  .users-wrapper .boxes-wrapper {
    width: 100%;
    min-height: 1800px;
    max-height: 5000px;
  }
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper {
    min-height: 1300px;
    max-height: 3000px;
  }
}
.users-wrapper .boxes-wrapper .system-users {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
}
.users-wrapper .boxes-wrapper .system-users .boxes {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1584px) {
  .users-wrapper .boxes-wrapper .system-users .boxes {
    width: 120%;
  }
}
@media (max-width: 1531px) {
  .users-wrapper .boxes-wrapper .system-users .boxes {
    width: 110%;
  }
}
@media (max-width: 1297px) {
  .users-wrapper .boxes-wrapper .system-users .boxes {
    width: 100%;
  }
}
.users-wrapper .boxes-wrapper .system-users .boxes .box {
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 0 10px 10px 0;
  height: 422px;
  width: 275px;
  text-align: center;
}
@media (max-width: 713px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box {
    width: 48%;
    margin: 3px 2px;
  }
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box {
    height: 290px;
  }
}
.users-wrapper .boxes-wrapper .system-users .boxes .box:hover .overlay {
  opacity: 1;
  z-index: 100;
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box:hover .overlay {
    display: none;
  }
}
.users-wrapper .boxes-wrapper .system-users .boxes .box .titles {
  font-size: 15.78px;
  font-weight: 700 !important;
  color: #fff;
  margin: 31px 0;
  display: inline-block;
  position: relative;
  background-image: repeating-linear-gradient(to top, #110047, #110047 13px, transparent 14px, transparent 40px);
}
@media (max-width: 613px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .titles {
    background-image: repeating-linear-gradient(to top, #110047, #110047 13px, transparent 5px, transparent 25px);
    padding: 0 10px;
  }
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .titles {
    width: 100%;
    padding: 0 25px;
  }
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .titles,
  .users-wrapper .boxes-wrapper .system-users .boxes .box .titles *:nth-child(7) {
    padding: 0;
  }
}
.users-wrapper .boxes-wrapper .system-users .boxes .box .titles .headers {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0;
  z-index: 100;
}
.users-wrapper .boxes-wrapper .system-users .boxes .box .overlay {
  display: block;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0px;
  flex-flow: column;
  background-color: rgba(0,236,194,0.6);
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay {
    display: none;
  }
}
.users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description {
  opacity: none;
  display: flex;
  flex-flow: column;
  font-size: 14px;
  font-weight: 400 !important;
  height: 380px;
  width: 100%;
  background-color: #00edc2;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 40px 27px 0 27px;
  margin: 0;
  color: #110047;
  text-align: left;
  z-index: 100;
}
@media (max-width: 713px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description {
    height: 90%;
  }
}
@media (max-width: 703px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description {
    padding: 40px 0 0 0;
  }
}
@media (max-width: 703px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description {
    padding: 40px 0 0 0;
  }
}
@media (max-width: 613px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description {
    padding: 40px 10px 0 10px;
  }
}
@media (max-width: 561px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description {
    display: none;
  }
}
@media (max-width: 703px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description p {
    padding: 15px 27px 0 27px;
  }
}
.users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description .overlay-titles {
  font-size: 15.78px;
  font-weight: 700 !important;
  color: #110047;
  display: inline-block;
  padding: 10px 10px 1px 10px;
  margin: 0 auto 20px auto;
  text-align: center;
  background-image: repeating-linear-gradient(to top, #fff, #fff 13px, #00edc2 14px, #00edc2 40px);
}
@media (max-width: 613px) {
  .users-wrapper .boxes-wrapper .system-users .boxes .box .overlay .overlay-description .overlay-titles {
    background-image: repeating-linear-gradient(to top, #fff, #fff 17px, #00edc2 5px, #00edc2 28px);
    padding: 0 10px;
  }
}
.users-wrapper .boxes-wrapper .system-users .button {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -50px;
}
.users-wrapper .boxes-wrapper .system-users .button a {
  text-decoration: none;
}
@media (max-width: 303px) {
  .users-wrapper .boxes-wrapper .system-users .button a {
    text-align: center;
  }
}
.users-wrapper .boxes-wrapper .system-users .button a .more-about-system {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  border: 1px solid #00edc2;
  margin: 29px 0 0 0;
  padding: 0 30px;
  border-radius: 50px;
  background-color: #fff;
  font-size: 14px;
  color: #110047;
  font-weight: 400 !important;
}
@media (max-width: 626px) {
  .users-wrapper .boxes-wrapper .system-users .button a .more-about-system {
    margin: 10px 0 0 0;
  }
}
@media (max-width: 303px) {
  .users-wrapper .boxes-wrapper .system-users .button a .more-about-system {
    justify-content: center;
  }
}
.users-wrapper .boxes-wrapper .system-users .button a .more-about-system:hover {
  background-color: #c2ffef;
}
.users-wrapper .second-overlay {
  color: hoverDescriptionFrontColor;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  transition: all 0.3s linear;
}
.users-wrapper .second-overlay * {
  transition: all 0.3s linear;
}
@media (max-width: 561px) {
  .users-wrapper .second-overlay:nth-child(4) {
    background-position-y: -20px;
  }
}
@media (max-width: 713px) {
  .users-wrapper .second-overlay {
    background-position: center;
    background-repeat: no-repeat;
  }
}
@media (max-width: 561px) {
  .users-wrapper .second-overlay {
    background-position-y: -60px;
  }
}
@media (max-width: 561px) {
  .users-wrapper .second-overlay {
    padding: 0 10px;
  }
}
.image-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 900px;
  position: relative;
  border-left: 16px solid #fff;
  border-right: 16px solid #fff;
  margin: 100px 0 0 0;
  font-family: frank-new, sans-serif;
}
.image-wrapper.hidden {
  display: none;
  visibility: hidden;
}
@media (max-width: 1024px) {
  .image-wrapper {
    border: none;
  }
}
.image-wrapper .footer-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 100%;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 937px) {
  .image-wrapper .footer-image {
    background-size: contain;
    background-position: bottom;
    bottom: 0;
  }
}
.image-wrapper .general-wrapper {
  margin: 0 auto;
  width: 80%;
  min-height: 900px;
  max-width: 1440px;
  position: relative;
  display: flex;
  flex-flow: row;
  padding: 0 0 0 5%;
}
@media (max-width: 937px) {
  .image-wrapper .general-wrapper {
    padding: 0;
  }
}
@media (max-width: 616px) {
  .image-wrapper .general-wrapper {
    width: 80%;
  }
}
.image-wrapper .general-wrapper .footer {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  flex-flow: row;
}
.image-wrapper .general-wrapper .footer .container {
  display: flex;
  flex-flow: row;
  min-width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}
@media (max-width: 937px) {
  .image-wrapper .general-wrapper .footer .container {
    flex-flow: column;
    justify-content: flex-start;
  }
}
@media (max-width: 616px) {
  .image-wrapper .general-wrapper .footer .container {
    padding: 0;
  }
}
.image-wrapper .general-wrapper .footer .container .footer-elements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
@media (max-width: 937px) {
  .image-wrapper .general-wrapper .footer .container .footer-elements {
    min-height: 150px;
  }
}
.image-wrapper .general-wrapper .footer .container .footer-elements .kontakt {
  margin: 0;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .header-wrapper1 {
  width: 70%;
  margin: 0 0 20px 0;
}
@media (max-width: 937px) {
  .image-wrapper .general-wrapper .footer .container .footer-elements .header-wrapper1 {
    width: 100%;
  }
}
.image-wrapper .general-wrapper .footer .container .footer-elements .header-wrapper1 h1 {
  font-size: 15.78px;
  color: #110047;
  padding: 0 0 20px 0;
  margin: 0;
  font-weight: 700 !important;
  width: 100%;
  border-bottom: 1px solid #26009e;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .header-wrapper {
  width: 70%;
  margin: 0 50px 20px 0;
}
@media (max-width: 937px) {
  .image-wrapper .general-wrapper .footer .container .footer-elements .header-wrapper {
    width: 100%;
    margin: 50px 0 0 0;
  }
}
.image-wrapper .general-wrapper .footer .container .footer-elements .header-wrapper h1 {
  font-size: 15.78px;
  color: #110047;
  padding: 0 0 20px 0;
  margin: 0;
  font-weight: 700 !important;
  width: 100%;
  border-bottom: 1px solid #26009e;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper {
  width: 40%;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper a {
  text-decoration: none;
  color: #110047;
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-weight: 400 !important;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper2 {
  width: 80%;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper2 a {
  text-decoration: none;
  color: #110047;
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-weight: 400 !important;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 {
  width: 100%;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 p,
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 a {
  color: #110047;
  font-size: 14px;
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: 400 !important;
  text-decoration: none;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 p:hover,
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 a:hover {
  color: #110047;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 .footer-btn {
  margin-right: 0;
  background-color: #00edc2;
  padding: 5px 15px;
  border: 1px;
  solid: #00edc2;
  border-radius: 20px;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .item-wrapper3 .footer-btn .footer-btn-name {
  color: #fff;
  font-size: 14px;
  padding: 0;
  margin: 0;
  width: 100%;
  font-weight: 400 !important;
  text-decoration: none;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .social-wrapper {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin: 20px 10px 0 0 0;
  display: flex;
  flex-flow: row;
  border: 1px solid #00edc2;
  justify-content: center;
  align-items: center;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .social-wrapper .svg {
  width: 50%;
  height: 50%;
}
.image-wrapper .general-wrapper .footer .container .footer-elements .social-wrapper .svg,
.image-wrapper .general-wrapper .footer .container .footer-elements .social-wrapper .svg * {
  fill: #26009e;
}
.bottom-line {
  height: 26px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #110047;
}
.bottom-logo {
  position: absolute;
  padding: 3px 3px;
  right: 4vw;
  height: 37px;
  width: 58px;
  bottom: 10px;
  border-radius: 50px;
  text-align: center;
  background-color: #110047;
}
.bottom-logo img {
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  justify-content: flex-end;
}
.copyright {
  font-size: 9px;
  font-weight: 400 !important;
  text-align: right;
  padding: 0 16px 0 0;
  color: #110047;
  width: 100%;
}
@media (max-width: 1024px) {
  .copyright {
    padding: 0;
  }
}
.foto-block {
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
}
.foto-block .container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0 100px;
  min-width: 85%;
}
@media (max-width: 768px) {
  .foto-block .container {
    margin: 0 40px 0 40px;
  }
}
@media (max-width: 470px) {
  .foto-block .container {
    flex-flow: column;
    justify-content: center;
  }
}
.foto-block .container p.section-title {
  font-family: "frank-new", "sans-serif" !important;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  color: #411da9;
  margin-bottom: 25px;
  width: 100%;
}
.foto-block .container .teem {
  display: flex;
  flex-flow: column;
}
.foto-block .container .teem .hedmaster-foto {
  display: flex;
  width: 200px;
  height: 200px;
  padding: 0;
  align-items: flex-start;
}
@media (max-width: 470px) {
  .foto-block .container .teem .hedmaster-foto {
    margin: 0 auto;
    justify-content: center;
  }
}
.foto-block .container .teem .hedmaster-foto img {
  margin: 30px 0 0 0;
  display: flex;
  width: 100%;
  height: 100%;
}
.foto-block .container .teem .notation {
  display: flex;
  flex-flow: column;
  width: 200px;
  padding: 0;
  margin: 30px 0 0 0;
  font-size: 16px;
  color: #110047;
  justify-content: center;
}
@media (max-width: 470px) {
  .foto-block .container .teem .notation {
    width: 100%;
    justify-content: center;
  }
}
.foto-block .container .teem .notation .name {
  font-weight: 700 !important;
  font-size: 16px;
  text-align: center;
}
.foto-block .container .teem .notation .position {
  font-weight: 300 !important;
  font-size: 16px;
  height: 25px;
  text-align: center;
}
.career {
  margin: 0 auto;
  font-family: frank-new, sans-serif;
  max-width: 1440px;
  height: 100%;
  position: relative;
  z-index: 0;
}
@media (max-width: 768px) {
  .career {
    margin: 0 40px;
  }
}
.career .container {
  border-top-right-radius: 20px;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  z-index: 100;
  min-width: 100%;
}
.career .container .offers {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.career .container .offers .one-offer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
}
.career .container .offers .one-offer.expand .offer-details-description {
  height: auto;
  font-weight: 400 !important;
  opacity: 1;
  padding: 0 100px;
  transition: all 0.2 linear;
  max-height: 4000px;
  font-family: "frank-new", "sans-serif" !important;
}
@media (max-width: 768px) {
  .career .container .offers .one-offer.expand .offer-details-description {
    padding: 0;
    margin: 0 auto;
  }
}
@media (max-width: 616px) {
  .career .container .offers .one-offer.expand .offer-details-description {
    padding: 0;
  }
}
.career .container .offers .one-offer.expand .offer-details-description ul {
  padding: 0 0 0 20px;
  list-style-type: none;
  font-size: 16px;
  font-weight: 400 !important;
  margin: 10px 0 0 0;
}
.career .container .offers .one-offer.expand .offer-details-description ul li {
  line-height: 2em;
  list-style-type: none;
  text-align: left;
}
.career .container .offers .one-offer.expand .offer-details-description ul li::before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin: 10px 10px 0 -20px;
  border-radius: 50%;
  border: 1px solid #00edc2;
  position: absolute;
}
.career .container .offers .one-offer.expand * {
  height: auto;
  opacity: 1;
  display: block;
  transition: all 0.2 linear;
}
.career .container .offers .one-offer a {
  text-decoration: none;
  display: block;
  width: 100%;
}
.career .container .offers .one-offer a .bar {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 50px 0;
  margin: 0;
  cursor: pointer;
}
@media (max-width: 768px) {
  .career .container .offers .one-offer a .bar {
    flex-flow: column;
  }
}
.career .container .offers .one-offer a .bar h1 {
  margin: 0 43px 0 0px;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
  position: relative;
}
@media (max-width: 616px) {
  .career .container .offers .one-offer a .bar h1 {
    flex-flow: column;
  }
}
.career .container .offers .one-offer a .bar .offer-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  width: 100%;
  margin: 0 100px 0 0;
  padding: 0 0 50px 0;
  border-bottom: 1px solid #26009e;
}
.career .container .offers .one-offer a .bar .offer-headers h2 {
  color: #110047;
  font-weight: 400 !important;
  font-size: 36px;
  padding: 0px 0 0 0;
  margin: 0;
  line-height: 46.02px;
}
@media (max-width: 404px) {
  .career .container .offers .one-offer a .bar .offer-headers h2 {
    font-size: 30px;
  }
}
@media (max-width: 350px) {
  .career .container .offers .one-offer a .bar .offer-headers h2 {
    font-size: 26px;
  }
}
.career .container .offers .one-offer a .bar .offer-headers .arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  position: relative;
  width: 12px;
  height: 30px;
  transform: rotate(270deg);
  transition: all 0.3s linear;
}
@media (max-width: 616px) {
  .career .container .offers .one-offer a .bar .offer-headers .arrow {
    padding: 30px;
  }
}
.career .container .offers .one-offer a .bar .offer-headers .arrow img {
  display: flex;
  align-items: center;
}
.career .container .offers .one-offer a .bar .offer-headers .arrow * {
  height: 100%;
}
.career .container .offers .offer-details {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.career .container .offers .offer-details .offer-details-header {
  width: 100%;
  display: flex;
}
.career .container .offers .offer-details-description {
  width: 100%;
  max-height: 3000px;
  display: flex;
  flex-flow: column;
  height: 0;
  opacity: 0;
  font-size: 16px;
  font-weight: 300 !important;
  color: #110047;
  transition: all 0.2 linear;
  font-family: "frank-new", "sans-serif" !important;
}
.career .container .offers .offer-details-description * {
  height: 0;
  opacity: 0;
  margin-bottom: 0;
  transition: all 0.2 linear;
}
.career .container .offers .offer-details-description p {
  width: 100%;
}
.career .container .offers .offer-details-description .apply-button {
  width: 100%;
  margin: 60px 0 0 0;
  display: flex;
  justify-content: flex-start !important;
}
.career .container .offers .offer-details-description .apply-button .apply-btn {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 41px;
  width: 170px;
  border-radius: 50px;
  border: 1px solid #00edc2;
  font-size: 14px;
  background: #fff;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #110047;
  text-align: center !important;
  display: block;
  padding: 0;
}
.career .container .offers .offer-details-description .apply-button .apply-btn:hover {
  background-color: #c2ffef;
}
.color-open-career-box {
  color: #00edc2 !important;
}
.arrow-up {
  position: absolute;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  transform: rotate(90deg) !important;
  display: flex;
  align-items: center;
  display: none;
}
.textes {
  max-width: 1440px;
  margin: 0 auto;
  font-family: frank-new, sans-serif;
  z-index: 100;
}
@media (max-width: 768px) {
  .textes {
    margin: 0 40px;
  }
}
.textes .container {
  display: flex;
  flex-flow: column;
  min-width: 100%;
  margin: 0;
  padding: 0;
}
@media (max-width: 616px) {
  .textes .container {
    margin: 0;
    padding: 0;
  }
}
.textes .container .text-wrapper {
  width: 100%;
  font-size: 16px;
  color: #110047;
  font-weight: 400 !important;
  word-spacing: 8px;
  line-height: 1.5em;
  padding: 0 100px;
}
@media (max-width: 1024px) {
  .textes .container .text-wrapper {
    padding: 0 0 0 100px;
  }
}
@media (max-width: 768px) {
  .textes .container .text-wrapper {
    padding: 0;
    margin: 0;
  }
}
.textes .container .text-wrapper ul {
  padding: 0 0 0 20px;
  list-style-type: none;
  font-size: 16px;
  font-weight: 0;
}
.textes .container .text-wrapper ul li {
  line-height: 2em;
  list-style-type: none;
  text-align: left;
}
.textes .container .text-wrapper ul li::before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin: 10px 10px 0 -20px;
  border-radius: 50%;
  border: 1px solid #00edc2;
  position: absolute;
}
.foto-carousel {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 1024px) {
  .foto-carousel {
    width: 100%;
  }
}
.foto-carousel .container {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  min-width: 100%;
  padding: 0 100px;
}
@media (max-width: 1024px) {
  .foto-carousel .container {
    padding: 0 0 0 100px;
  }
}
@media (max-width: 768px) {
  .foto-carousel .container {
    padding: 0 0 0 40px;
  }
}
@media (max-width: 616px) {
  .foto-carousel .container {
    padding: 0;
  }
}
.foto-carousel .container.p-0 {
  padding: 0;
}
.foto-carousel .container .carousel .carusel-image,
.foto-carousel .container .carousel-inner .carusel-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.foto-carousel .container .carousel .carusel-image img,
.foto-carousel .container .carousel-inner .carusel-image img {
  width: 100%;
  height: 100%;
}
.foto-carousel .container .carousel .personalized-carousel-numbering,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering {
  display: flex;
  flex-flow: row;
  height: 40px;
  justify-content: center;
  position: absolute;
  width: 100%;
  cursor: pointer;
  margin: 0 0 41px 0;
}
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers {
  font-family: frank-new, sans-serif;
  position: absolute;
  bottom: -40px;
  left: 0;
}
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li {
  display: flex;
  margin: 0 5px;
  text-indent: 0;
  color: #110047;
  margin: 0 2px;
  background-color: transparent;
  width: 30px;
  height: 30px;
  transition: all 0.25s ease;
  font-size: 12px;
}
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li.active,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li.active,
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li:hover,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li:hover {
  width: 30px;
  height: 30px;
  color: #00edc2;
}
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li i,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li i {
  font-size: 5px;
  color: #110047;
}
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li i .fa-circle.active,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li i .fa-circle.active,
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li i .fa-circle:hover,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li i .fa-circle:hover {
  width: 30px;
  height: 30px;
  color: #00edc2;
}
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li i .far.active,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li i .far.active,
.foto-carousel .container .carousel .personalized-carousel-numbering .carousel-indicators-numbers li i .far:hover,
.foto-carousel .container .carousel-inner .personalized-carousel-numbering .carousel-indicators-numbers li i .far:hover {
  width: 30px;
  height: 30px;
  color: #00edc2;
}
.carouselExampleIndicators {
  padding: 0;
  margin: 0;
}
.blueimp-gallery {
  background-color: rgba(17,0,71,0.6) !important;
}
.blueimp-gallery>.next {
  border: 1px solid #00edc2 !important;
  color: #00edc2 !important;
}
.blueimp-gallery>.prev {
  border: 1px solid #00edc2 !important;
  color: #00edc2 !important;
}
.blueimp-gallery>.close {
  color: #00edc2 !important;
}
.blueimp-gallery>.play-pause {
  display: none !important;
}
.blueimp-gallery>.indicator {
  display: none !important;
}
.knows-more {
  font-family: frank-new, sans-serif;
  width: 80%;
  max-width: 1440px;
  margin: 30px auto;
}
.knows-more .container {
  width: 100%;
  display: flex;
}
.knows-more .container .info-wrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
}
.knows-more .container .info-wrapper .general-foto-wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0 0;
}
.knows-more .container .info-wrapper .general-foto-wrapper .foto-wrapper {
  border-radius: 50%;
  height: 94px;
  width: 94px;
  position: relative;
}
@media (max-width: 954px) {
  .knows-more .container .info-wrapper .general-foto-wrapper .foto-wrapper {
    display: none;
  }
}
.knows-more .container .info-wrapper .general-foto-wrapper .foto-wrapper .patron-picture {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
}
.knows-more .container .info-wrapper .general-foto-wrapper .foto-wrapper .patron-picture-circle {
  width: 101%;
  height: 102%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -2px;
  right: -6px;
}
.knows-more .container .info-wrapper a {
  text-decoration: none;
}
.knows-more .container .info-wrapper a .dialgo-box {
  border: 1px solid #d2d4d6;
  border-bottom-right-radius: 30px;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 43px 20px 43px 20px;
  position: relative;
  text-align: center;
}
.knows-more .container .info-wrapper a .dialgo-box h1 {
  font-size: 36px;
  font-weight: 800 !important;
  color: #00edc2;
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (max-width: 396px) {
  .knows-more .container .info-wrapper a .dialgo-box h1 {
    font-size: 26px;
  }
}
.knows-more .container .info-wrapper a .dialgo-box h2 {
  font-size: 24px;
  font-weight: 500 !important;
  color: #26009e;
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (max-width: 396px) {
  .knows-more .container .info-wrapper a .dialgo-box h2 {
    font-size: 14px;
  }
}
.knows-more .container .info-wrapper a .dialgo-box #triangle {
  transform: rotate(-90deg);
  position: absolute;
  top: 80px;
  left: -22px;
}
.knows-more .container .info-wrapper a .dialgo-box #triangle:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: 2px;
  margin-left: 2px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}
.knows-more .container .info-wrapper a .dialgo-box #triangle:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
  border-bottom: 22px solid #d2d4d6;
}
#top {
  margin: -260px auto 0 auto;
  position: relative;
  font-family: frank-new, sans-serif;
  padding: 0;
  max-width: 1440px;
  z-index: 0;
  border-top-right-radius: 20px;
  margin: 0px auto;
  background-color: #fff;
}
@media (max-width: 768px) {
  #top {
    border-top-right-radius: 20px;
  }
}
@media (max-width: 616px) {
  #top {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100% !important;
  }
}
#top.centered .wrapper-top .headers {
  display: block !important;
}
#top.centered * {
  text-align: center;
}
#top.centered button {
  display: block !important;
  max-width: 100%;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}
#top.centered p {
  margin: 0;
}
#top.centered h2 {
  margin-top: 50px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 30px;
}
#top .container {
  border-top-right-radius: 20px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-width: 100%;
  padding: 0;
  margin: 0 auto;
  z-index: 100;
  border-top-right-radius: 20px;
}
@media (max-width: 1024px) {
  #top .container {
    border-top-right-radius: 0;
  }
}
@media (max-width: 616px) {
  #top .container {
    width: 80%;
  }
}
#top .container .wrapper-top {
  display: flex;
  flex-flow: column;
  padding: 70px 100px 0 100px;
  width: 100%;
  position: relative;
  border-top-right-radius: 20px;
}
@media (max-width: 768px) {
  #top .container .wrapper-top {
    padding: 30px 40px 0;
  }
}
#top .container .wrapper-top .dark {
  display: none;
}
#top .container .wrapper-top .headers {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0px 0 0 0;
  font-size: 16px;
  color: #110047;
  font-weight: 400 !important;
  word-spacing: 8px;
  line-height: 1.5em;
}
#top .container .wrapper-top .headers ol {
  list-style-type: decimal;
}
#top .container .wrapper-top .headers p {
  width: 100%;
}
#top .container .wrapper-top .headers ul {
  padding: 0 0 0 20px;
  list-style-type: none;
  font-size: 16px;
  font-weight: 400 !important;
}
#top .container .wrapper-top .headers ul li {
  line-height: 2em;
  list-style-type: none;
  text-align: left;
}
#top .container .wrapper-top .headers ul li::before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin: 10px 10px 0 -20px;
  border-radius: 50%;
  border: 1px solid #00edc2;
  position: absolute;
}
#top .container .wrapper-top .menu-intro {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
@media (max-width: 1024px) {
  #top .container .wrapper-top .menu-intro {
    display: none;
  }
}
#top .container .wrapper-top .menu-intro .aboutMenuArrow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  display: none;
}
#top .container .wrapper-top .menu-intro .aboutMenuArrow img {
  padding-right: 15px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  #top .container .wrapper-top .menu-intro .aboutMenuArrow {
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 85px 0 0 0;
    display: flex;
    border-top: 1px solid #309;
    border-bottom: 1px solid #e5e3eb;
  }
}
#top .container .wrapper-top .menu-intro ul {
  list-style-type: none;
  width: 100%;
  margin-top: 85px;
  padding: 20px 0px;
  margin-top: 80px;
  display: flex;
  border-top: 1px solid #309;
  border-bottom: 1px solid #e5e3eb;
}
@media (max-width: 1024px) {
  #top .container .wrapper-top .menu-intro ul {
    position: relative;
    flex-direction: column;
    margin: 0;
    display: none;
  }
}
#top .container .wrapper-top .menu-intro ul li {
  padding: 10px 50px 10px 0px;
  font-size: 14px;
  color: #26009e;
  cursor: pointer;
  display: flex;
  position: relative;
}
#top .container .wrapper-top .menu-intro ul li.active a {
  position: relative !important;
  max-height: 21px;
  color: #00edc2;
}
#top .container .wrapper-top .menu-intro ul li.active a::after {
  content: '';
  display: block;
  background-image: url("../img/polokrag_zielony.svg");
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  position: absolute;
  right: -12px;
  top: 0.1px;
}
#top .container .wrapper-top .menu-intro ul li a {
  text-decoration: none;
  color: #26009e;
  font-size: 14px;
  display: flex;
}
#top .container .wrapper-top .menu-intro ul li a:hover {
  color: #00edc2;
}
#tekstIMockupPoDole {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 768px) {
  #tekstIMockupPoDole {
    display: column;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 616px) {
  #tekstIMockupPoDole {
    margin: 0;
    padding: 0 40px;
  }
}
#tekstIMockupPoDole .header {
  max-width: 1440px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  align-items: flex-start;
  height: auto;
  width: 100%;
  padding: 0;
  position: relative;
}
@media (max-width: 768px) {
  #tekstIMockupPoDole .header {
    display: column;
    margin: 0;
  }
}
@media (max-width: 616px) {
  #tekstIMockupPoDole .header {
    margin: 0;
  }
}
#tekstIMockupPoDole .header h1 {
  margin: 0 43px 0 0px;
  color: #26009e;
  font-size: 52.95px;
  font-weight: 100 !important;
  position: relative;
}
#tekstIMockupPoDole .header .wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
}
#tekstIMockupPoDole .header .wrapper h2 {
  color: #110047;
  font-weight: 400 !important;
  font-size: 36px;
  margin: 0;
  height: 100%;
  line-height: 46.02px;
}
@media (max-width: 1024px) {
  #tekstIMockupPoDole .header .wrapper h2 {
    margin: 15px 0 0 0;
  }
}
#tekstIMockupPoDole .header .wrapper .h2 {
  font-size: 16px;
  font-weight: 100 !important;
  width: 100%;
  z-index: 100;
  line-height: 2em;
  margin: 30px 0 0 0;
  color: #110047;
  padding: 22px 0 40px 0;
  hyphens: auto;
}
@media (max-width: 1230px) {
  #tekstIMockupPoDole .header .wrapper .h2 {
    width: 100%;
  }
}
#tekstIMockupPoDole .header .wrapper .h2 p {
  font-weight: 400;
  width: 100%;
}
@media (max-width: 1230px) {
  #tekstIMockupPoDole .header .wrapper .h2 p {
    width: 100%;
  }
}
#tekstIMockupPoDole .header .wrapper .h2 p ul li:before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin: 0 10px 0 0;
  border-radius: 50%;
  border: 1px solid #00edc2;
}
#tekstIMockupPoDole .header .wrapper .h2 .button {
  display: none;
}
#tekstIMockupPoDole .header .wrapper .bottom-box-outline .bottom-box {
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 1024px) {
  #tekstIMockupPoDole .header h1 {
    padding: 0px;
  }
  #tekstIMockupPoDole .header .wrapper h2 {
    padding: 0px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  #tekstIMockupPoDole .header {
    flex-direction: column;
  }
}
#tekstIMockupPoLewej {
  position: relative;
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1230px) {
  #tekstIMockupPoLewej {
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  #tekstIMockupPoLewej {
    padding: 0 40px;
  }
}
#tekstIMockupPoLewej .left-box-outline {
  position: absolute;
  right: 50%;
  top: 80px;
}
@media (max-width: 1230px) {
  #tekstIMockupPoLewej .left-box-outline {
    display: none;
  }
}
#tekstIMockupPoLewej .header {
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  padding-left: 30px;
}
@media (max-width: 1230px) {
  #tekstIMockupPoLewej .header {
    max-width: 100%;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  #tekstIMockupPoLewej .header {
    padding: 0;
  }
}
@media (max-width: 768px) {
  #tekstIMockupPoLewej .header {
    display: column;
    margin: 0 40px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  #tekstIMockupPoLewej .header {
    margin: 0;
  }
}
#tekstIMockupPoLewej .header h1 {
  margin: 0 43px 0 0px;
  color: #26009e;
  font-size: 52.95px;
  padding: 110px 0 0 0;
  font-weight: 100 !important;
  position: relative;
}
@media (max-width: 1230px) {
  #tekstIMockupPoLewej .header h1 {
    padding: 110px 0 0 0;
  }
}
@media (max-width: 1024px) {
  #tekstIMockupPoLewej .header h1 {
    padding: 50px 0 0 0;
  }
}
#tekstIMockupPoLewej .header .wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 0 0 0px 0;
}
#tekstIMockupPoLewej .header .wrapper h2 {
  color: #110047;
  font-weight: 400 !important;
  font-size: 36px;
  padding: 80px 0 0 0;
  margin: 0;
  height: 100%;
  line-height: 46.02px;
}
@media (max-width: 1230px) {
  #tekstIMockupPoLewej .header .wrapper h2 {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  #tekstIMockupPoLewej .header .wrapper h2 {
    margin: 15px 0 0 0;
  }
}
#tekstIMockupPoLewej .header .wrapper .h2 {
  font-size: 16px;
  font-weight: 100 !important;
  z-index: 100;
  line-height: 2em;
  margin: 30px 0 80px 0;
  color: #110047;
  padding: 22px 0 40px 0;
  hyphens: auto;
}
@media (max-width: 1024px) {
  #tekstIMockupPoLewej .header .wrapper .h2 {
    margin: 0;
    width: 100%;
  }
}
#tekstIMockupPoLewej .header .wrapper .h2 p {
  font-weight: 400;
  width: 100%;
}
#tekstIMockupPoLewej .header .wrapper .h2 .button {
  display: none;
}
@media (max-width: 1024px) {
  #tekstIMockupPoLewej {
    width: 100%;
  }
  #tekstIMockupPoLewej .header h1 h2,
  #tekstIMockupPoLewej .header .wrapper h2 {
    padding: 0px;
    width: 100%;
  }
  #tekstIMockupPoLewej .left-box {
    display: none;
  }
}
@media (max-width: 768px) {
  #tekstIMockupPoLewej .header {
    flex-direction: column;
  }
}
.left-box-outline2 {
  width: 100%;
  min-height: 100px;
  margin: 0 auto;
  z-index: 100;
  display: none;
}
@media (max-width: 1230px) {
  .left-box-outline2 {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .left-box-outline2 img {
    width: 100%;
    height: 100%;
  }
}
.general-wrapper {
  width: 100%;
  position: relative;
  min-height: 900px;
  max-height: 5000px;
  overflow: hidden;
}
@media (max-width: 616px) {
  .general-wrapper {
    min-height: 1000px;
  }
}
@media (max-width: 482px) {
  .general-wrapper {
    min-height: 1250px;
  }
}
@media (max-width: 374px) {
  .general-wrapper {
    min-height: 1250px;
  }
}
@media (max-width: 321px) {
  .general-wrapper {
    min-height: 1350px;
  }
}
.general-wrapper .shore {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
}
@media (max-width: 1240px) {
  .general-wrapper .shore {
    width: 100%;
  }
}
.general-wrapper .navy {
  background-color: #110047;
  position: absolute;
  height: 100%;
  width: 90%;
  max-height: 1700px;
  top: 0px;
  left: 16px;
  -webkit-border-top-right-radius: 40px;
  -moz-border-radius-topright: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
}
@media (max-width: 1240px) {
  .general-wrapper .navy {
    width: 100%;
    margin: 0;
    border-radius: 0;
    left: 0;
  }
}
.general-wrapper .gradeint {
  position: absolute;
  width: 99%;
  margin: 0 auto;
  height: 100%;
  border: none;
  top: 0px;
  left: 16px;
  max-height: 1700px;
  -webkit-border-top-right-radius: 40px;
  -moz-border-radius-topright: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
  padding: 70px 0 0 0;
  max-height: 3000px;
}
@media (max-width: 1240px) {
  .general-wrapper .gradeint {
    width: 100%;
    left: 0;
  }
}
.general-wrapper .gradeint .gradeint1 {
  background-image: radial-gradient(circle at 35% 35%, rgba(0,236,194,0.3), rgba(17,6,74,0.2), rgba(17,0,71,0.1));
  position: absolute;
  width: 80%;
  height: 100%;
  border: none;
  top: 0px;
  left: 0;
  max-height: 1700px;
  -webkit-border-top-right-radius: 40px;
  -moz-border-radius-topright: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
}
@media (max-width: 1240px) {
  .general-wrapper .gradeint .gradeint1 {
    width: 100%;
    left: 0;
  }
}
.general-wrapper .gradeint .current-wrapper {
  width: 80%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0;
}
.general-wrapper .gradeint .current-wrapper .container {
  width: 100%;
  height: 100%;
  max-height: 1700px;
  display: flex;
  flex-flow: column;
  padding: 0;
}
.general-wrapper .gradeint .current-wrapper .container .news {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 1700px;
  z-index: 100;
}
@media (max-width: 1240px) {
  .general-wrapper .gradeint .current-wrapper .container .news {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .general-wrapper .gradeint .current-wrapper .container .news {
    flex-flow: column;
    min-height: 100%;
    padding: 0;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news h1 {
  margin: 0 43px 0 0;
  color: #fff;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
  align-items: flex-start;
  min-height: 70px;
  max-height: 70px;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 700px;
  max-height: 5000px;
  padding: 1px;
  position: relative;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper h2 {
  color: #fff;
  padding: 0px 0 0 0;
  margin: 0;
  font-weight: 400 !important;
  display: flex;
  transform: translateY(15%);
}
@media (max-width: 768px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper h2 {
    padding: 0 0 0 3px;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper h2 p {
  padding: 0;
  margin: 0;
  display: flex;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper h2 * {
  font-size: 36px;
  line-height: 46px;
  font-weight: 400 !important;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts {
  display: flex;
  flex-flow: column;
  margin: 103px 0 0 0;
  min-height: 100%;
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts {
    width: 100%;
    margin: 50px 0 0 0;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts:hover {
  color: #00edc2;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post {
  display: flex;
  flex-flow: row;
  width: 100%;
  margin: 0 0 100px 0;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post:hover {
  color: #00edc2;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news {
  display: flex;
  font-size: 15px;
  font-weight: 400 !important;
  color: #fff;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news:hover .text a {
  color: #00edc2;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data {
  display: flex;
  flex-flow: row;
  min-width: 100px;
  max-width: 150px;
  font-size: 15px;
  font-weight: 400 !important;
  color: #fff;
  padding: 5px 5px 0 0;
  background-color: transparent;
  position: relative;
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data {
    margin: 0 0 0 10px;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data::after {
  content: "";
  position: absolute;
  top: calc(50% + 15px);
  right: 0;
  background-color: #26009e;
  height: 1px;
  width: 90vw;
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data::after {
    display: none;
  }
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data {
    width: 100%;
    padding: 0 5px 0 0;
    border-bottom: 1px solid #00edc2;
  }
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data p {
    display: flex;
    flex-flow: row;
    min-width: 200px;
    max-width: 200px;
    position: relative;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .data .lines {
  border: 1px solid #fff;
  display: flex;
  width: 100%;
  height: 100%;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text {
  display: flex;
  flex-flow: row;
  font-size: 16px;
  width: 100%;
  padding: 0 0 0 10px;
  font-weight: 300 !important;
  line-height: 2em;
  color: #fff;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text p {
  padding: 0 30px 0 0;
  margin: 0;
  width: 100%;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text p a {
  color: #00edc2;
  text-decoration: none;
}
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text p a:active,
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text p a:visited,
.general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text p a:focus {
  color: #00edc2;
  text-decoration: none;
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news .text p {
    margin: 10px 0 0 0;
  }
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .wrapper .posts .post .one-news {
    flex-flow: column;
    width: 100%;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .button {
  padding: 0;
  width: 100%;
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .button {
    justify-content: flex-start;
    margin: 0;
    width: 100%;
    align-items: flex-end;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .button .see-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 242px;
  height: 42px;
  border: 1px solid #00edc2;
  border-radius: 30px;
  background-color: rgba(32,24,85,0.5);
  font-size: 14px;
  color: #fff;
  font-weight: 400 !important;
  padding: 0;
  margin: 0 0 0 121px;
}
@media (max-width: 780px) {
  .general-wrapper .gradeint .current-wrapper .container .news .button .see-more {
    margin: 0 0 0 11px;
  }
}
.general-wrapper .gradeint .current-wrapper .container .news .button .see-more:hover {
  -webkit-box-shadow: 0px 0px 10px 1px #00edc2;
  -moz-box-shadow: 0px 0px 10px 1px #00edc2;
  box-shadow: 0px 0px 10px 1px #00edc2;
}
.general-wrapper .gradeint .current-wrapper .container .news .button .see-more a {
  text-decoration: none;
  width: 242px;
  color: #fff;
  text-align: center;
}
.headers {
  max-width: 1440px;
  margin: 0 auto;
  font-family: frank-new, sans-serif;
}
@media (max-width: 768px) {
  .headers {
    margin: 0 40px;
  }
}
.headers .container {
  display: flex;
  flex-flow: column;
  min-width: 100%;
  margin: 0;
  padding: 0;
}
@media (max-width: 616px) {
  .headers .container {
    margin: 0;
    padding: 0;
  }
}
.headers .container .number-tresc-header {
  width: 100%;
}
@media (max-width: 768px) {
  .headers .container .number-tresc-header {
    flex-flow: column;
    align-items: flex-start;
    padding: 0;
  }
}
.headers .container .number-tresc-header h1 {
  margin: 0 35px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
.headers .container .number-tresc-header h2 {
  color: #110047;
  padding: 10px 0 0 0;
  margin: 0;
}
@media (max-width: 768px) {
  .headers .container .number-tresc-header h2 {
    padding: 0;
  }
}
.headers .container .number-tresc-header h2 * {
  font-weight: 400 !important;
  font-size: 36px;
  line-height: 46px;
}
.newsletter-wrapper {
  margin: 90px auto 0 auto;
  width: 80%;
  max-width: 1140px;
  padding: 0;
  position: relative;
}
@media (max-width: 768px) {
  .newsletter-wrapper {
    width: 100%;
  }
}
.newsletter-wrapper .newsletter-container {
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  position: relative;
}
.newsletter-wrapper .newsletter-container .newsletter-header {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-header {
    width: 80%;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-header .header {
  padding: 0 0 43px 0;
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-header .header {
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-header .header h1 {
  margin: 0 35px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
.newsletter-wrapper .newsletter-container .newsletter-header .header .wrapper h2 {
  display: flex;
  transform: translateY(0%);
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-header .header .wrapper h2 {
    padding: 0;
    justify-content: flex-start;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0px;
  display: flex;
  padding: 0;
  margin: 0;
  background-color: #00ecc2;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  position: relative;
}
@media (max-width: 992px) {
  .newsletter-wrapper .newsletter-container .newsletter-content {
    border-bottom-right-radius: 20px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box {
    width: 80%;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements {
  padding: 80px 100px 19px 100px;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements {
    padding: 34px 0 19px 0;
    align-items: flex-start;
    flex-flow: column;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements .piktogram {
  width: 91px;
  height: 100%;
  padding: 0;
  margin: 0 21px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements .piktogram {
    margin: 0 21px 34px 0;
  }
}
@media (max-width: 375px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements .piktogram {
    width: 65px;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements .piktogram .svg {
  width: 91px;
  height: 91px;
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements .opis {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #11064a;
  font-size: 16px;
  font-weight: 400;
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .top-elements .opis p {
  width: 100%;
  padding: 0;
  margin: 0;
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .form {
  width: 100%;
  padding: 19px 100px 80px 100px;
  font-size: 14px;
  font-weight: 100;
  color: #110047;
}
@media (max-width: 768px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box .form {
    padding: 19px 0 80px 0;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .form label {
  width: 100%;
  line-height: 3;
  font-size: 14px;
  color: #11064a;
  padding: 0;
  margin: 0;
  font-weight: 400;
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .form form {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  color: #110047;
  font-weight: 100;
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .form form input[type="email"] {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: none;
  outline: none;
  color: #11064a;
  font-weight: 100;
  padding: 0 23px;
  margin: 10px 0 23px 0;
}
@media (max-width: 992px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box .form form input[type="email"] {
    width: 390px;
  }
}
@media (max-width: 798px) {
  .newsletter-wrapper .newsletter-container .newsletter-content .left-box .form form input[type="email"] {
    width: 100%;
  }
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .form form input::placeholder {
  color: #00edc2;
  font-size: 14px;
  color: #c5c5c5;
  font-weight: 400;
}
.newsletter-wrapper .newsletter-container .newsletter-content .left-box .form form input[type="submit"] {
  font-weight: 400;
  width: 124px;
  height: 40px;
  border-radius: 20px;
  background-color: #11064a;
  color: #00edc2;
  border: none;
  outline: none;
  font-size: 14px;
  cursor: pointer;
}
.newsletter-wrapper .newsletter-container .right-box-image {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
}
@media (max-width: 992px) {
  .newsletter-wrapper .newsletter-container .right-box-image {
    display: none;
  }
}
.newsletter-wrapper .newsletter-container .right-box-image img {
  border-bottom-right-radius: 20px;
  width: 100%;
  height: 100%;
}
.email-input {
  border: 1px solid #f00;
}
.partnerzy-content {
  height: 430px;
  width: 100%;
  position: relative;
}
@media (max-width: 1443px) {
  .partnerzy-content {
    height: auto;
  }
}
@media (max-width: 992px) {
  .partnerzy-content {
    border-bottom-right-radius: 20px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .partnerzy-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.partnerzy-content .aktywny {
  width: 1140px !important;
  height: 430px !important;
  border-radius: 20px !important;
  object-fit: fill !important;
}
@media (max-width: 1443px) {
  .partnerzy-content .aktywny {
    width: 100% !important;
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .partnerzy-content .aktywny {
    border-radius: 0px !important;
  }
}
.partnerzy-content .carousel-pagination {
  padding-top: 45px;
}
.partnerzy-content .carousel-pagination .carousel-indicators li {
  width: 9px !important;
  height: 9px !important;
  border-radius: 50% !important;
  background-color: rgba(17,0,71,0.141);
  margin: 0 3.5px;
  cursor: pointer;
}
.partnerzy-content .carousel-pagination .carousel-indicators li.active {
  background-color: #00edc2;
}
.ships-wrapper {
  width: calc(100% - 16px);
  z-index: 1;
  position: relative;
  padding-top: 70px;
  padding-bottom: 8%;
  min-height: 500px;
  margin-left: 16px;
}
@media (max-width: 1200px) {
  .ships-wrapper {
    background-size: 100% 100%;
    margin-left: 0px;
  }
}
.ships-wrapper .background-element {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(90% + 16px);
  border-top-right-radius: 20px;
}
@media (max-width: 1240px) {
  .ships-wrapper .background-element {
    width: calc(100% + 16px);
  }
}
.ships-wrapper .blenda {
  width: 90%;
}
.ships-wrapper .light-green {
  margin-left: 100px;
}
@media (max-width: 768px) {
  .ships-wrapper .light-green {
    margin-left: 0;
  }
}
.ships-wrapper .standard-wrapper {
  position: relative;
  z-index: 10;
}
.ships-wrapper .header {
  margin-bottom: 10%;
}
.ships-wrapper .header h2 p {
  font-weight: 300 !important;
}
.ships-wrapper .header *,
.ships-wrapper .text * {
  color: #fff;
}
.ships-wrapper .text {
  max-width: 280px;
  margin-left: 25px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}
.ships-wrapper .text p {
  margin-bottom: 0;
}
.ships-wrapper .picto {
  margin-left: 105px;
  display: flex;
  align-items: center;
}
.ships-wrapper .picto img {
  height: 82px;
  width: 82px;
}
@media (max-width: 768px) {
  .ships-wrapper .picto {
    margin-left: 0;
  }
  .ships-wrapper .picto img {
    width: 62px;
    height: 62px;
  }
}
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .header h2 {
    padding: 0;
  }
}
.community-wrapper {
  margin-left: 105px;
  margin-right: 95px;
  margin-top: 10%;
  color: #110047;
}
@media (max-width: 768px) {
  .community-wrapper {
    margin-left: 0px;
    flex-direction: column-reverse;
  }
}
@media (max-width: 600px) {
  .community-wrapper {
    margin-top: 70px;
  }
}
.community-wrapper h1 {
  font-size: 60px;
  line-height: 65px;
  font-weight: 700;
}
@media (max-width: 1024px) {
  .community-wrapper h1 {
    font-size: 30px;
    line-height: 35px;
  }
}
.community-wrapper p {
  font-size: 29px;
  line-height: 35px;
  font-weight: 400;
}
@media (max-width: 1024px) {
  .community-wrapper p {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 600) {
  .community-wrapper p {
    margin-bottom: 70px;
  }
}
.community-wrapper .text-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  flex: 2;
}
.community-wrapper .picto-wrapper {
  display: flex;
  align-items: flex-start;
  flex: 0;
}
@media (max-width: 768px) {
  .community-wrapper .picto-wrapper {
    margin-bottom: 40px;
  }
}
.community-wrapper .picto-wrapper img {
  height: 190px;
  width: 190px;
}
@media (max-width: 1024px) {
  .community-wrapper .picto-wrapper img {
    height: 120px;
    width: 120px;
  }
}
.video-wrapper {
  padding-top: 50px;
  margin-bottom: 30px;
  height: 650px;
}
@media (min-width: 1200px) {
  .video-wrapper {
    height: 1184px;
  }
}
@media (min-width: 992px) {
  .video-wrapper {
    height: 984px;
  }
}
@media (min-width: 768px) {
  .video-wrapper {
    height: 708px;
  }
}
.video-wrapper .video-box {
  height: 640px;
  width: 100%;
}
@media (min-width: 400px) {
  .video-wrapper .video-box {
    height: 300px;
  }
}
@media (min-width: 550px) {
  .video-wrapper .video-box {
    height: 460px;
  }
}
@media (min-width: 992px) {
  .video-wrapper .video-box {
    height: 640px;
  }
}
.choose-method {
  padding: 80px 100px;
}
.choose-method h1 {
  color: #00edc2;
  font-weight: 400;
}
@media (max-width: 768px) {
  .choose-method {
    padding: 40px;
  }
}
.choose-method .choose-method-header-wrapper {
  display: flex;
  width: 100%;
  gap: 100px;
  padding: 0;
}
@media (max-width: 768px) {
  .choose-method .choose-method-header-wrapper {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.choose-method .choose-method-header-wrapper .wrapper-item h2 {
  color: #309;
  font-size: 14px;
}
.choose-method .choose-method-header-wrapper .wrapper-item .method-button {
  margin-top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  background-color: #00edc2;
  color: #fff;
  border-radius: 20px;
  font-size: 16px;
  padding: 5px 15px;
  cursor: pointer;
  transition-duration: 0.2s;
}
.choose-method .choose-method-header-wrapper .wrapper-item .method-button:hover {
  box-shadow: 0px 0px 10px 1px #00edc2;
}
.choose-method .choose-method-content-wrapper h2,
.choose-method .choose-method-content-wrapper p {
  color: #110047;
  font-weight: 400;
}
.choose-method .choose-method-content-wrapper h2 {
  margin-bottom: 40px;
  font-size: 40px;
  line-height: 48px;
}
.choose-method .choose-method-content-wrapper .content-header {
  margin-top: 80px;
}
#contact {
  margin: -400px auto 0px auto;
  background-color: #fff;
  min-height: 400px;
  max-width: 1440px;
  position: relative;
  z-index: 0;
  border-top-right-radius: 20px;
  width: 80%;
}
@media (max-width: 1024px) {
  #contact {
    margin: -400px auto 10px auto;
    min-height: 450px;
    max-height: 6000px;
  }
}
@media (max-width: 768px) {
  #contact {
    border-top-left-radius: 20px;
  }
}
@media (max-width: 616px) {
  #contact {
    width: 100%;
  }
}
#contact .container {
  display: flex;
  background-color: #fff;
  min-width: 100%;
  z-index: 100;
  position: relative;
  border-top-right-radius: 20px;
  padding: 0;
  margin: 0;
}
@media (max-width: 1024px) {
  #contact .container {
    width: 80%;
  }
}
@media (max-width: 768px) {
  #contact .container {
    border-top-left-radius: 20px;
    padding: 0;
    margin: 0;
  }
}
#contact .container .infos {
  width: 100%;
  display: flex;
  padding: 70px 100px 0 100px;
  justify-content: space-between;
  z-index: 100;
  grid-gap: 20px;
}
@media (max-width: 1024px) {
  #contact .container .infos {
    padding: 100px 100px 0 100px;
  }
}
@media (max-width: 922px) {
  #contact .container .infos {
    flex-flow: column;
    padding: 70px 100px 0 100px;
    text-align: center;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  #contact .container .infos {
    padding: 30px 40px 0 40px;
  }
}
#contact .container .infos .header-boxes h1 {
  font-size: 15.78px;
  color: #00edc2;
  font-weight: 700 !important;
  border-bottom: 1px solid #26009e;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes h1 {
    margin: 10px 0 0 0;
    text-align: left;
    margin: 0;
  }
}
#contact .container .infos .header-boxes .text-box {
  font-size: 16px;
  color: #110047;
  font-weight: 400 !important;
  padding: 15px 0 0 0;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes .text-box {
    flex-flow: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    text-align: left;
    margin: 0;
  }
}
#contact .container .infos .header-boxes .text-box a {
  text-decoration: none;
  font-weight: 700 !important;
  color: #26009e;
}
#contact .container .infos .header-boxes2 h1 {
  font-size: 15.78px;
  color: #00edc2;
  font-weight: 700 !important;
  border-bottom: 1px solid #26009e;
}
@media (max-width: 922px) {
  #contact .container .infos .header-boxes2 h1 {
    text-align: left;
  }
}
#contact .container .infos .header-boxes2 a {
  color: #26009e;
}
#contact .container .infos .header-boxes2 .text-box {
  font-size: 16px;
  color: #110047;
  font-weight: 400 !important;
  padding: 0px 0 0 0;
  display: flex;
  flex-flow: row nowrap;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes2 .text-box {
    flex-flow: column;
    text-align: center;
    justify-content: center;
    text-align: left;
    margin: 0;
  }
}
#contact .container .infos .header-boxes2 .text-box a {
  text-decoration: none;
  font-weight: 700 !important;
}
#contact .container .infos .header-boxes2 .text-box .arrow {
  margin: -3px 0 0 12px;
  padding: 0;
  width: 10px;
  height: 30px;
  transform: rotate(180deg);
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes2 .text-box .arrow {
    display: none;
  }
}
#contact .container .infos .header-boxes2 .text-box .arrow img {
  height: 10px;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes3 {
    justify-content: center;
    text-align: center;
  }
}
@media (max-width: 768px) {
  #contact .container .infos .header-boxes3 {
    text-align: left;
    margin: 0;
  }
}
#contact .container .infos .header-boxes3 .wrapper {
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes3 .wrapper {
    flex-flow: column;
    text-align: center;
    justify-content: center;
  }
}
#contact .container .infos .header-boxes3 .wrapper .foto-wrapper {
  border-radius: 50%;
  height: 162px;
  width: 162px;
  position: relative;
  margin: 0 30px 0 0;
  opacity: 0;
}
@media (max-width: 1280px) {
  #contact .container .infos .header-boxes3 .wrapper .foto-wrapper {
    display: none;
  }
}
#contact .container .infos .header-boxes3 .wrapper .foto-wrapper .patron-picture {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
}
#contact .container .infos .header-boxes3 .wrapper .foto-wrapper .patron-picture img {
  width: 100%;
  height: 100%;
}
#contact .container .infos .header-boxes3 .wrapper .foto-wrapper .patron-picture-circle {
  width: 102%;
  height: 102%;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -1px;
  left: -2px;
  background-position-x: 10px;
}
#contact .container .infos .header-boxes3 .wrapper .patron-data {
  padding: 0;
  margin: 0;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes3 .wrapper .patron-data {
    justify-content: center;
    text-align: center;
    text-align: left;
    margin: 0;
  }
}
#contact .container .infos .header-boxes3 .wrapper .patron-data h1 {
  font-size: 15.78px;
  color: #00edc2;
  font-weight: 700 !important;
  border-bottom: 1px solid #26009e;
}
#contact .container .infos .header-boxes3 .wrapper .patron-data .text-box {
  font-size: 16px;
  color: #110047;
  font-weight: 400 !important;
  padding: 15px 0 0 0;
  display: flex;
  flex-flow: row nowrap;
}
@media (max-width: 1024px) {
  #contact .container .infos .header-boxes3 .wrapper .patron-data .text-box {
    flex-flow: column;
    text-align: center;
    justify-content: center;
    text-align: left;
    margin: 0;
  }
}
#contact .container .infos .header-boxes3 .wrapper .patron-data .text-box a {
  text-decoration: none;
  font-weight: 700 !important;
}
#form {
  margin: 0 auto 35px;
}
#form,
#cargo-form {
  max-width: 1440px;
  font-family: "frank-new", "sans-serif" !important;
  width: 80%;
}
@media (max-width: 616px) {
  #form,
  #cargo-form {
    width: 100%;
  }
}
#form .container,
#cargo-form .container {
  display: flex;
  flex-flow: column;
  min-width: 100%;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  #form .container,
  #cargo-form .container {
    padding: 0 40px;
  }
}
#form .container .form-header,
#cargo-form .container .form-header {
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  width: 100%;
}
@media (max-width: 768px) {
  #form .container .form-header,
  #cargo-form .container .form-header {
    flex-flow: column;
    align-items: flex-start;
  }
}
#form .container .form-header h1,
#cargo-form .container .form-header h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
#form .container .form-header .h2-elements,
#cargo-form .container .form-header .h2-elements {
  display: flex;
  flex-flow: column;
  width: 100%;
}
#form .container .form-header .h2-elements h2,
#cargo-form .container .form-header .h2-elements h2 {
  color: #110047;
  padding: 20px 0 0 0;
  margin: 0;
  font-weight: 400 !important;
  font-size: 36px;
  line-height: 46px;
}
@media (max-width: 768px) {
  #form .container .form-header .h2-elements h2,
  #cargo-form .container .form-header .h2-elements h2 {
    padding: 0 0 0 3px;
  }
}
#form .container .form-header .h2-elements .contact-form-sub-header,
#cargo-form .container .form-header .h2-elements .contact-form-sub-header {
  padding: 0 100px 0 0;
  color: #110047;
  font-family: "frank-new", "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 14px;
  margin-top: 20px;
  width: 100%;
  position: relative;
}
@media (max-width: 768px) {
  #form .container .form-header .h2-elements .contact-form-sub-header,
  #cargo-form .container .form-header .h2-elements .contact-form-sub-header {
    padding: 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper {
  min-height: 400px;
  width: 100%;
  padding: 0 100px 0 0;
}
@media (max-width: 768px) {
  #form .container .form-header .h2-elements .contact-form-wrapper,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper {
    padding: 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements {
  display: flex;
  flex-flow: row;
  margin: 0 0 5px 0;
  justify-content: space-between;
}
@media (max-width: 1060px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .form-elements,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements {
    flex-flow: column;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form {
  width: 100%;
  margin: 30px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
@media (max-width: 1060px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form {
    margin: 22px 0 0 0;
  }
}
@media (max-width: 835px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form {
    flex-flow: column;
    margin: 16px 0 0 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form :nth-child(2),
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form :nth-child(2) {
  margin: 0;
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input {
  height: 31px;
  width: 49%;
  border-radius: 0 !important;
  margin: 0 10px 0 0;
  color: #110047 !important;
  background-color: #e3fdf8;
  font-family: "frank-new", "sans-serif" !important;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  font-size: 14px;
  font-family: "Frank New";
  outline: none;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: 400 !important;
}
@media (max-width: 835px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input {
    width: 100%;
    margin: 0 0 15px 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input:focus,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input:focus {
  background-color: #00edc2 !important;
  color: #fff;
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input::-webkit-outer-spin-button,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input::-webkit-outer-spin-button,
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input::-webkit-inner-spin-button,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input[type=number],
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form input[type=number] {
  -moz-appearance: textfield;
}
#form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form .second-input,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form .second-input {
  margin: 0 10px 0 0;
}
@media (max-width: 1060px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form .second-input,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .form-elements .contact-form .second-input {
    margin: 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second {
  width: 100%;
  color: #110047;
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second #message,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second #message {
  margin: 15px 0 0 0;
}
@media (max-width: 835px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second #message,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second #message {
    margin: 10px 0 0 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea {
  width: 100%;
  height: 30px;
  margin: 17px 10px 0px 0;
  padding: 5px 0 0px 9px;
  font-family: "frank-new", "sans-serif" !important;
  font-size: 14px;
  font-weight: 400 !important;
  color: #110047;
  -ms-color: #110047;
  background-color: #e3fdf8;
  border: none;
  outline: none;
  font-weight: 400 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea {
    margin: 17px 10px 10px 0;
  }
}
@media (max-width: 835px) {
  #form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea,
  #cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea {
    margin: 11px 0 0 0;
  }
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea:focus,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea:focus {
  background-color: #00edc2;
  color: #fff;
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea .form-control,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second textarea .form-control {
  height: none;
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second .input-radio,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second .input-radio {
  color: #110047;
  font-family: "frank-new", "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 14px;
  margin-top: 20px;
  width: 100%;
  position: relative;
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second .input-radio a,
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second .input-radio a {
  text-decoration: none;
  color: #00edc2;
}
#form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second .input-radio input[type=checkbox],
#cargo-form .container .form-header .h2-elements .contact-form-wrapper .contact-form-second .input-radio input[type=checkbox] {
  height: fit-content;
  width: 3%;
}
#form .form-button,
#cargo-form .form-button {
  width: 100%;
  text-align: center !important;
  margin: 15px auto 0;
  display: flex;
  justify-content: center !important;
}
#form .form-button button,
#cargo-form .form-button button {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 41px;
  width: 170px;
  border-radius: 50px;
  border: 1px solid #00edc2;
  font-size: 14px;
  background: #fff;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #110047;
  text-align: center !important;
  display: block;
  padding: 0;
  margin: 0 auto;
}
#form .form-button button:hover,
#cargo-form .form-button button:hover,
#form .form-button button *,
#cargo-form .form-button button * {
  background-color: #c2ffef;
}
input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
}
.check {
  position: absolute;
  top: 3px;
  left: 1px;
  height: 12px;
  width: 12px;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
}
input:checked ~ .check {
  background-color: #00edc2;
}
.check:after {
  content: "";
  position: absolute;
  display: none;
}
input ~ .check {
  background-color: #ccc;
}
input:checked ~ .check:after {
  display: block;
}
.check:after {
  top: 1px;
  left: 1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00edc2;
  border: 2px solid #fff;
}
.errors {
  display: flex;
  flex-flow: column;
  padding: 0;
}
.errors li {
  list-style-type: none;
  padding: 0;
  color: #c79494;
  font-weight: 400 !important;
  font-size: 12px;
}
@media (min-width: 768px) {
  #cargo-form {
    margin: 0 100px;
  }
}
#cargo-form .form-button {
  justify-content: start !important;
}
#cargo-form .form-button button {
  margin: 0;
}
#form {
  margin: 0 auto 35px;
}
#form,
#cargo-form {
  max-width: 1440px;
  font-family: "frank-new", "sans-serif" !important;
  width: 80%;
}
@media (max-width: 616px) {
  #form,
  #cargo-form {
    width: 100%;
  }
}
#form .container-authorities,
#cargo-form .container-authorities {
  display: flex;
  flex-flow: column;
  min-width: 100%;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  #form .container-authorities,
  #cargo-form .container-authorities {
    padding: 0 40px;
  }
}
#form .container-authorities .form-header,
#cargo-form .container-authorities .form-header {
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  width: 100%;
}
@media (max-width: 768px) {
  #form .container-authorities .form-header,
  #cargo-form .container-authorities .form-header {
    flex-flow: column;
    align-items: flex-start;
  }
}
#form .container-authorities .form-header h1,
#cargo-form .container-authorities .form-header h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
}
#form .container-authorities .form-header .h2-elements,
#cargo-form .container-authorities .form-header .h2-elements {
  display: flex;
  flex-flow: column;
  width: 100%;
}
#form .container-authorities .form-header .h2-elements h2,
#cargo-form .container-authorities .form-header .h2-elements h2 {
  color: #110047;
  padding: 20px 0 0 0;
  margin: 0;
  font-weight: 400 !important;
  font-size: 36px;
  line-height: 46px;
}
@media (max-width: 768px) {
  #form .container-authorities .form-header .h2-elements h2,
  #cargo-form .container-authorities .form-header .h2-elements h2 {
    padding: 0 0 0 3px;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper {
  min-height: 400px;
  width: 100%;
  padding: 0 100px 0 0;
}
@media (max-width: 768px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper {
    padding: 0;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements {
  display: flex;
  flex-flow: row;
  margin: 0 0 5px 0;
  justify-content: space-between;
}
@media (max-width: 1060px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements {
    flex-flow: column;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form {
  width: 100%;
  margin: 30px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
@media (max-width: 1060px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form {
    margin: 22px 0 0 0;
  }
}
@media (max-width: 835px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form {
    flex-flow: column;
    margin: 16px 0 0 0;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form :nth-child(2),
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form :nth-child(2) {
  margin: 0;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input {
  height: 31px;
  width: 49%;
  border-radius: 0 !important;
  margin: 0 10px 0 0;
  color: #110047 !important;
  background-color: #e3fdf8;
  font-family: "frank-new", "sans-serif" !important;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  font-size: 14px;
  font-family: "Frank New";
  outline: none;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: 400 !important;
}
@media (max-width: 835px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input {
    width: 100%;
    margin: 0 0 15px 0;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input:focus,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input:focus {
  background-color: #00edc2 !important;
  color: #fff;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input::-webkit-outer-spin-button,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input::-webkit-outer-spin-button,
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input::-webkit-inner-spin-button,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input[type=number],
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form input[type=number] {
  -moz-appearance: textfield;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form .second-input,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form .second-input {
  margin: 0 10px 0 0;
}
@media (max-width: 1060px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form .second-input,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .form-elements .contact-form .second-input {
    margin: 0;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second {
  width: 100%;
  color: #110047;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second #message,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second #message {
  margin: 15px 0 0 0;
}
@media (max-width: 835px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second #message,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second #message {
    margin: 10px 0 0 0;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea {
  width: 100%;
  height: 30px;
  margin: 17px 10px 0px 0;
  padding: 5px 0 0px 9px;
  font-family: "frank-new", "sans-serif" !important;
  font-size: 14px;
  font-weight: 400 !important;
  color: #110047;
  -ms-color: #110047;
  background-color: #e3fdf8;
  border: none;
  outline: none;
  font-weight: 400 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea {
    margin: 17px 10px 10px 0;
  }
}
@media (max-width: 835px) {
  #form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea,
  #cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea {
    margin: 11px 0 0 0;
  }
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea:focus,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea:focus {
  background-color: #00edc2;
  color: #fff;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea .form-control,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second textarea .form-control {
  height: none;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second .input-radio,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second .input-radio {
  color: #110047;
  font-family: "frank-new", "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 14px;
  margin-top: 20px;
  width: 100%;
  position: relative;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second .input-radio a,
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second .input-radio a {
  text-decoration: none;
  color: #00edc2;
}
#form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second .input-radio input[type=checkbox],
#cargo-form .container-authorities .form-header .h2-elements .contact-for-authorities-form-wrapper .contact-form-second .input-radio input[type=checkbox] {
  height: fit-content;
  width: 3%;
}
#form .form-button,
#cargo-form .form-button {
  width: 100%;
  text-align: center !important;
  margin: 15px auto 0;
  display: flex;
  justify-content: center !important;
}
#form .form-button button,
#cargo-form .form-button button {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 41px;
  width: 170px;
  border-radius: 50px;
  border: 1px solid #00edc2;
  font-size: 14px;
  background: #fff;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #110047;
  text-align: center !important;
  display: block;
  padding: 0;
  margin: 0 auto;
}
#form .form-button button:hover,
#cargo-form .form-button button:hover,
#form .form-button button *,
#cargo-form .form-button button * {
  background-color: #c2ffef;
}
input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
}
.check {
  position: absolute;
  top: 3px;
  left: 1px;
  height: 12px;
  width: 12px;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
}
input:checked ~ .check {
  background-color: #00edc2;
}
.check:after {
  content: "";
  position: absolute;
  display: none;
}
input ~ .check {
  background-color: #ccc;
}
input:checked ~ .check:after {
  display: block;
}
.check:after {
  top: 1px;
  left: 1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00edc2;
  border: 2px solid #fff;
}
.errors {
  display: flex;
  flex-flow: column;
  padding: 0;
}
.errors li {
  list-style-type: none;
  padding: 0;
  color: #c79494;
  font-weight: 400 !important;
  font-size: 12px;
}
@media (min-width: 768px) {
  #cargo-form {
    margin: 0 100px;
  }
}
#cargo-form .form-button {
  justify-content: start !important;
}
#cargo-form .form-button button {
  margin: 0;
}
.general-contact-wrapper {
  height: 660px;
  position: relative;
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .general-contact-wrapper {
    padding: 0 40px;
  }
}
@media (max-width: 616px) {
  .general-contact-wrapper {
    padding: 0;
    width: 100%;
  }
}
.general-contact-wrapper .google-map {
  position: absolute;
  width: 85%;
  height: 760px;
  top: -10px;
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
}
.general-contact-wrapper .map-wrapper {
  position: relative;
  padding: 0;
  margin: 50px auto 0 auto;
}
@media (max-width: 616px) {
  .general-contact-wrapper .map-wrapper {
    margin: 0 40px;
  }
}
.general-contact-wrapper .map-wrapper .container {
  min-width: 100%;
  margin: 0 auto;
  padding: 50px 0 0 0;
}
.general-contact-wrapper .map-wrapper .container .map {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  z-index: 100;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .container .map {
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 15px 0;
    padding: 0;
  }
}
.general-contact-wrapper .map-wrapper .container .map h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100 !important;
  align-items: flex-start;
}
.general-contact-wrapper .map-wrapper .container .map .right-map-part {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1152px) {
  .general-contact-wrapper .map-wrapper .container .map .right-map-part {
    flex-flow: column;
  }
}
.general-contact-wrapper .map-wrapper .container .map .right-map-part h2 {
  color: #110047;
  padding: 18px 0 0 0;
  margin: 0;
  display: flex;
  font-weight: 400 !important;
  font-size: 36px;
  line-height: 46px;
  flex-flow: flex-end;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .container .map .right-map-part h2 {
    padding: 0 0 0 3px;
  }
}
.general-contact-wrapper .map-wrapper .map-header {
  display: flex;
  width: 35%;
  flex-flow: row;
  justify-content: flex-end;
  text-align: center;
  position: absolute;
  right: 0;
  z-index: 100;
}
@media (max-width: 936px) {
  .general-contact-wrapper .map-wrapper .map-header {
    flex-flow: column;
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header {
    width: 100%;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text {
  border: 1px solid #d2d4d6;
  border-bottom-right-radius: 30px;
  width: 380px;
  margin: 20px 0;
  display: flex;
  flex-flow: column;
  padding: 43px 0;
  background-color: #fff;
  position: relative;
}
@media (max-width: 936px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text {
    background: transparent;
  }
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text {
    width: 100%;
    border: none;
    margin: 0 auto;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text #triangle {
  transform: rotate(-90deg);
  position: absolute;
  top: 75px;
  left: -22px;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text #triangle {
    display: none;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text #triangle:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: 2px;
  margin-left: 2px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}
.general-contact-wrapper .map-wrapper .map-header .map-text #triangle:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 22px solid transparent;
  border-right: 22px solid transparent;
  border-bottom: 22px solid #d2d4d6;
}
.general-contact-wrapper .map-wrapper .map-header .map-text .adress {
  border-bottom: 1px solid #00edc2;
  width: 50%;
  min-height: 230px;
  padding: 20px 0;
  display: none;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  text-align: left;
  line-height: 2;
  position: relative;
  font-size: 16px;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text .adress {
    width: 100%;
    margin: 0;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text .adress :first-child {
  font-weight: 700 !important;
}
.general-contact-wrapper .map-wrapper .map-header .map-text .adress :nth-child(2) {
  margin: 50px 0 0;
}
.general-contact-wrapper .map-wrapper .map-header .map-text .adress p {
  padding: 0;
  margin: 0;
  font-weight: 400 !important;
  color: #110047;
  font-size: 16px;
}
.general-contact-wrapper .map-wrapper .map-header .map-text .adress h1 {
  font-size: 14px;
  color: #110047;
  margin: 0 0 50px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text .adress h1 {
    font-size: 16px;
    padding: 0 0 10px 0;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text .visible {
  display: flex;
}
.general-contact-wrapper .map-wrapper .map-header .map-text .lokalization {
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: left;
  margin: 30px 0 0 0;
  padding: 30px 0 30px 63px;
  width: 70%;
  margin: 0 auto;
  line-height: 2;
  position: relative;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text .lokalization {
    width: 100%;
    border: none;
    margin: 0;
    font-size: 16px;
    padding: 30px 0 30px 30px;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text .lokalization .input-map {
  width: 100%;
  font-size: 14px;
  color: #110047;
  font-weight: 400 !important;
  position: relative;
  font-size: 16px;
}
.general-contact-wrapper .map-wrapper .map-header .map-text .lokalization-go .map-center {
  background: none;
  outline: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  text-align: left;
  margin: 0 0 0 0;
  padding: 30px 0 30px 0;
  width: 70%;
  margin: 0 auto;
  align-items: center;
  text-decoration: none;
  color: #26009e;
  font-size: 14px;
  font-weight: 700 !important;
}
@media (max-width: 768px) {
  .general-contact-wrapper .map-wrapper .map-header .map-text .lokalization-go .map-center {
    font-size: 16px;
  }
}
.general-contact-wrapper .map-wrapper .map-header .map-text .lokalization-go .arrow {
  padding: 0;
  display: flex;
}
.google-map2 {
  width: 65%;
  margin: -662px 0 0 0;
}
@media (max-width: 2560px) {
  .google-map2 {
    width: 70%;
  }
}
@media (max-width: 2560px) {
  .google-map2 {
    width: 75%;
  }
}
@media (max-width: 2000px) {
  .google-map2 {
    margin: -650px 0 0 0;
  }
}
@media (max-width: 936px) {
  .google-map2 {
    margin: 100px 0 0 0;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .google-map2 {
    margin: 0px 0 0 0;
  }
}
input[type=radio] {
  display: none;
  cursor: pointer;
}
.checkmark,
.checkmarked {
  position: absolute;
  top: 5px;
  left: -25px;
  height: 15px;
  width: 15px;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
}
.checkmarked {
  position: absolute;
  top: 70px;
  left: 0px;
  height: 15px;
  width: 15px;
  background-color: #f00;
  border-radius: 50%;
  cursor: pointer;
}
input:checked ~ .checkmark {
  background-color: #00edc2;
}
input:checked ~ .checkmarked {
  background-color: #00edc2;
}
.checkmark:after,
.checkmarked:after {
  content: "";
  position: absolute;
  display: none;
}
input ~ .checkmark {
  background-color: #ccc;
}
input ~ .checkmarked {
  background-color: #ccc;
}
input:checked ~ .checkmark:after {
  display: block;
}
input:checked ~ .checkmarked:after {
  display: block;
}
.checkmark:after,
.checkmarked:after {
  top: 2px;
  left: 1.8px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #00edc2;
  border: 2px solid #fff;
}
.contact-popup .pop-up,
.home-popup .pop-up {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(17,0,71,0.6);
  z-index: 999;
}
.contact-popup .pop-up .popup-wrapper,
.home-popup .pop-up .popup-wrapper {
  display: flex;
  flex-flow: column;
  position: absolute;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  padding: 10 0;
  box-shadow: 3px 2px 0 4px rgba(17,0,71,0);
}
.contact-popup .pop-up .popup-wrapper .exit-cross,
.home-popup .pop-up .popup-wrapper .exit-cross {
  position: absolute;
  top: -140px;
  right: -70px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
}
@media (max-width: 768px) {
  .contact-popup .pop-up .popup-wrapper .exit-cross,
  .home-popup .pop-up .popup-wrapper .exit-cross {
    top: -150px;
    right: -170px;
  }
}
@media (max-width: 500px) {
  .contact-popup .pop-up .popup-wrapper .exit-cross,
  .home-popup .pop-up .popup-wrapper .exit-cross {
    top: 0px;
    right: 0px;
  }
}
@media (max-width: 500px) {
  .contact-popup .pop-up .popup-wrapper .exit-cross,
  .home-popup .pop-up .popup-wrapper .exit-cross {
    top: -50px;
    right: 0px;
  }
}
.contact-popup .pop-up .popup-wrapper .exit-cross::before,
.home-popup .pop-up .popup-wrapper .exit-cross::before {
  position: absolute;
  right: 12px;
  top: 2px;
  content: '';
  height: 30px;
  width: 1px;
  background-color: #fff;
  transform: rotate(45deg);
}
.contact-popup .pop-up .popup-wrapper .exit-cross::after,
.home-popup .pop-up .popup-wrapper .exit-cross::after {
  position: absolute;
  right: 12px;
  top: 2px;
  content: '';
  height: 30px;
  width: 1px;
  background-color: #fff;
  transform: rotate(-45deg);
}
.contact-popup .shore-popup,
.home-popup .shore-popup {
  width: 100vw;
  height: 70vh;
  border: none;
  bottom: 10%;
  left: -20px;
  background-color: rgba(17,0,71,0);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  z-index: 1;
  position: absolute;
}
@media (max-width: 980px) {
  .contact-popup .shore-popup,
  .home-popup .shore-popup {
    background-size: contain;
    top: 100px;
    left: 0;
  }
}
@media (max-width: 616px) {
  .contact-popup .shore-popup,
  .home-popup .shore-popup {
    background-size: contain;
    top: 200px;
    left: 0;
  }
}
.contact-popup .popup-content,
.home-popup .popup-content {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-flow: column;
  border: none;
  outline: none;
  justify-content: center;
}
.contact-popup .popup-content .wrapper-up-content,
.home-popup .popup-content .wrapper-up-content {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 100px 50px;
  position: relative;
  background-color: #110047;
  max-width: 50%;
  left: 50%;
  transform: translateX(-50%);
  max-height: 1500px;
  border-top-right-radius: 20px;
}
@media (max-width: 1024px) {
  .contact-popup .popup-content .wrapper-up-content,
  .home-popup .popup-content .wrapper-up-content {
    max-width: 70%;
    max-height: 100%;
  }
}
@media (max-width: 500px) {
  .contact-popup .popup-content .wrapper-up-content,
  .home-popup .popup-content .wrapper-up-content {
    max-width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: flex-start;
  }
}
.contact-popup .popup-content .wrapper-up-content.home-wrapper,
.home-popup .popup-content .wrapper-up-content.home-wrapper {
  z-index: 9999999999;
}
.contact-popup .popup-content .wrapper-up-content.home-wrapper .main-header,
.home-popup .popup-content .wrapper-up-content.home-wrapper .main-header {
  width: 100% !important;
}
.contact-popup .popup-content .wrapper-up-content .gradeint-popup,
.home-popup .popup-content .wrapper-up-content .gradeint-popup {
  background-image: radial-gradient(circle at 35% 35%, rgba(0,236,194,0.3), rgba(17,6,74,0.2), rgba(17,0,71,0.1));
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 60%;
  border: none;
  top: 0;
  left: 0;
  -webkit-border-top-right-radius: 40px;
  -moz-border-radius-topright: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
}
@media (max-width: 500px) {
  .contact-popup .popup-content .wrapper-up-content .gradeint-popup,
  .home-popup .popup-content .wrapper-up-content .gradeint-popup {
    width: 100%;
    height: 70vh;
    top: -90px;
    border-top-right-radius: 0;
  }
}
.contact-popup .popup-content .wrapper-up-content .gradeint-popup.home-gradient,
.home-popup .popup-content .wrapper-up-content .gradeint-popup.home-gradient {
  background-image: radial-gradient(circle at 55% 50%, rgba(0,236,194,0.2), rgba(17,6,74,0.2), rgba(17,0,71,0.1)) !important;
}
.contact-popup .popup-content .wrapper-up-content .main-header,
.home-popup .popup-content .wrapper-up-content .main-header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-flow: row;
  align-items: flex-end;
  z-index: 100;
  width: 60%;
  height: 100%;
  margin: 0;
  border-top-right-radius: 20px;
  z-index: 100;
}
@media (max-width: 768px) {
  .contact-popup .popup-content .wrapper-up-content .main-header,
  .home-popup .popup-content .wrapper-up-content .main-header {
    flex-flow: column;
  }
}
@media (max-width: 500px) {
  .contact-popup .popup-content .wrapper-up-content .main-header,
  .home-popup .popup-content .wrapper-up-content .main-header {
    width: 100%;
    height: 50%;
    padding: 0;
    margin: 0;
  }
}
.contact-popup .popup-content .wrapper-up-content .main-header h1,
.home-popup .popup-content .wrapper-up-content .main-header h1 {
  margin: 0 43px 0 0px;
  color: #fff;
  font-size: 52.95px;
  font-weight: 100 !important;
  position: relative;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 20px 0 0 0;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header h2,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header h2 {
  color: #fff;
  margin: 0;
  font-weight: 400 !important;
  display: flex;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .message,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .message {
  color: #00edc2 !important;
  width: 100%;
  font-size: 20px !important;
  font-weight: 100 !important;
  font-family: "frank-new", "sans-serif" !important;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .message.error,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .message.error {
  display: flex;
  flex-flow: column;
  padding: 0;
  color: #00edc2 !important;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .message.error li,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .message.error li {
  list-style-type: none;
  padding: 0;
  color: #00edc2;
  font-weight: 300 !important;
  font-size: 10px !important;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .notice,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .notice {
  margin: 0 43px 0 0px;
  color: #fff;
  font-size: 52.95px;
  font-weight: 100 !important;
  position: relative;
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-header,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-header {
  font-size: 40px !important;
  letter-spacing: 0px !important;
  color: #fff !important;
  opacity: 1 !important;
}
@media (max-width: 768px) {
  .contact-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-header,
  .home-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-header {
    font-size: 30px !important;
  }
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-message,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-message {
  font-size: 17px !important;
  letter-spacing: 0px !important;
  color: #fff !important;
}
@media (max-width: 768px) {
  .contact-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-message,
  .home-popup .popup-content .wrapper-up-content .main-header .notice-header .home-popup-message {
    font-size: 14px !important;
  }
}
.contact-popup .popup-content .wrapper-up-content .main-header .notice-header .green-text,
.home-popup .popup-content .wrapper-up-content .main-header .notice-header .green-text {
  font-size: 17px !important;
  letter-spacing: 0px !important;
  color: #25e3bd !important;
}
@media (max-width: 768px) {
  .contact-popup .popup-content .wrapper-up-content .main-header .notice-header .green-text,
  .home-popup .popup-content .wrapper-up-content .main-header .notice-header .green-text {
    font-size: 14px !important;
  }
}
.home-popup .home-wrapper {
  position: relative !important;
  min-height: 50% !important;
  padding: 80px 60px !important;
}
.home-popup .exit-cross {
  top: -60px !important;
  right: -25px !important;
}
.home-popup .main-header {
  -webkit-box-pack: unset !important;
  -ms-flex-pack: unset !important;
  justify-content: unset !important;
  -webkit-box-orient: unset !important;
  -webkit-box-direction: unset !important;
  -ms-flex-flow: unset !important;
  flex-flow: unset !important;
  -webkit-box-align: unset !important;
  -ms-flex-align: unset !important;
  align-items: unset !important;
}
.home-popup .additional-info {
  padding-top: 20px !important;
}
.home-popup .home-popup-header {
  padding-bottom: 20px !important;
}
.home-popup .notice-header {
  padding: 0 !important;
}
*,
*:focus,
*:hover {
  outline: none;
}
.containers-status-background-1 {
  overflow-x: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
@media (max-width: 1439px) {
  .containers-status-background-1 {
    display: none;
  }
}
.containers-status-background-1::before {
  width: 550px;
  height: 550px;
  background-image: url("../img/status-kontenerow/background.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 10px;
  right: -25px;
  margin-right: 0;
  content: '';
  position: absolute;
}
@media (max-width: 1600px) {
  .containers-status-background-1::before {
    right: -120px;
  }
}
.containers-status-background-2 {
  overflow-x: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
@media (max-width: 1439px) {
  .containers-status-background-2 {
    display: none;
  }
}
.containers-status-background-2::before {
  width: 550px;
  height: 550px;
  background-image: url("../img/status-kontenerow/background2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 10px;
  right: -25px;
  margin-right: 0;
  content: '';
  position: absolute;
}
.containers-status-container {
  width: 80%;
  max-width: 1440px;
  margin: 0px auto;
  background: none;
  margin: -80vh auto 0;
  z-index: 1;
  position: relative;
}
@media (max-width: 1300px), (max-height: 860px) {
  .containers-status-container {
    margin: -80vh auto 0;
  }
}
@media (max-width: 1100px) {
  .containers-status-container {
    margin: -80vh auto 0;
  }
}
@media (max-width: 768px) {
  .containers-status-container.smaller {
    width: 90%;
  }
}
.containers-status-container .container-status h2 {
  text-align: left;
  font-weight: bold;
  font-size: 60px;
  line-height: 78px;
  letter-spacing: 0px;
  color: #411da9;
}
@media (max-width: 768px) {
  .containers-status-container .container-status h2 {
    font-size: 36px;
    line-height: 48px;
  }
}
.containers-status-container .container-status h3 {
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  font-weight: medium;
  letter-spacing: 0px;
  color: #00edc2;
}
@media (max-width: 2000px) {
  .containers-status-container .container-status h3 {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 1600px) {
  .containers-status-container .container-status h3 {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (max-width: 1200px) {
  .containers-status-container .container-status h3 {
    font-size: 12px;
    line-height: 12px;
    overflow: scroll;
  }
}
.containers-status-container .container-status h4 {
  text-align: left;
  font-weight: 100;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0px;
  color: #411da9;
}
@media (max-width: 768px) {
  .containers-status-container .container-status h4 {
    font-size: 24px;
    line-height: 31px;
  }
}
.containers-status-container .container-status p {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #411da9;
  margin: 60px 0;
  max-width: 500px;
}
.containers-status-container .container-status .search-selector-wrapper {
  width: 100%;
  display: flex;
}
.containers-status-container .container-status .search-selector-wrapper div {
  width: auto;
  cursor: pointer;
  font-size: 30px;
  line-height: 39px;
  color: #00edc2;
  opacity: 0.2;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
}
.containers-status-container .container-status .search-selector-wrapper div:nth-of-type(1) {
  margin-right: 30px;
}
.containers-status-container .container-status .search-selector-wrapper div.selected {
  opacity: 1;
}
.containers-status-container .container-status .search-selector-wrapper div.selected:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #00edc2;
  left: 0;
}
.containers-status-container .container-status .wrapper {
  display: flex;
  width: 100%;
}
@media (max-width: 1600px) {
  .containers-status-container .container-status .wrapper {
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .containers-status-container .container-status .wrapper {
    flex-direction: column;
  }
}
.containers-status-container .container-status .wrapper .checkbox-wrapper {
  display: flex;
}
.containers-status-container .container-status .wrapper .container-input {
  display: none;
  position: relative;
  margin-left: 25px;
  font-size: 12px;
  color: #411da9;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 20px;
  line-height: 18px;
}
.containers-status-container .container-status .wrapper .container-input.show {
  display: flex;
}
.containers-status-container .container-status .wrapper .container-input:nth-of-type(2) {
  margin-left: 50px;
}
.containers-status-container .container-status .wrapper .container-input input ~ .checkmark {
  top: 0;
  background-color: transparent;
  border: 2px solid #411da9;
  width: 18px;
  height: 18px;
}
.containers-status-container .container-status .wrapper .container-input .checkmark:after,
.containers-status-container .container-status .wrapper .container-input .checkmarked:after {
  background-color: #00edc2;
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.containers-status-container .container-status .left-side {
  width: 50%;
}
@media (max-width: 768px) {
  .containers-status-container .container-status .left-side {
    width: 100%;
  }
}
.containers-status-container .container-status .left-side .search-description {
  display: none;
}
.containers-status-container .container-status .left-side .search-description p {
  display: none;
}
.containers-status-container .container-status .left-side .search-description.show {
  display: flex;
}
.containers-status-container .container-status .left-side .search-description.show p {
  display: flex;
}
.containers-status-container .container-status .right-side {
  width: 45%;
  display: none;
}
.containers-status-container .container-status .right-side.show {
  display: block;
}
@media (max-width: 768px) {
  .containers-status-container .container-status .right-side {
    width: 100%;
  }
}
.containers-status-container .container-status .box {
  background: #411da9 0% 0% no-repeat;
  border-radius: 0px 20px;
  padding: 50px;
  margin-top: 20%;
  position: relative;
  z-index: 2;
}
@media (max-width: 1024px) {
  .containers-status-container .container-status .box {
    padding: 32px;
  }
}
.containers-status-container .container-status .box::after {
  width: 112px;
  height: 68px;
  background-image: url("../img/status-kontenerow/kontener.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: -40px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  content: '';
  z-index: 2;
  position: absolute;
}
.containers-status-container .container-status .box::before {
  width: 138px;
  height: 63px;
  background-image: url("../img/status-kontenerow/statek-port.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 30%;
  left: -90px;
  content: '';
  z-index: -2;
  position: absolute;
}
@media (max-width: 1024px) {
  .containers-status-container .container-status .box::before {
    display: none;
  }
}
.containers-status-container .container-status .box p {
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  margin: 8px 0;
}
.containers-status-container .container-status .box .wrapper-text {
  display: flex;
}
@media (max-width: 1600px) {
  .containers-status-container .container-status .box .wrapper-text {
    flex-direction: column;
  }
}
.containers-status-container .container-status .box .wrapper-text p {
  font-size: 32px;
  line-height: 41px;
  font-weight: bold;
  letter-spacing: 14px;
}
@media (max-width: 1439px) {
  .containers-status-container .container-status .box .wrapper-text p {
    letter-spacing: 5px;
  }
}
.containers-status-container .container-status .box .wrapper-text .small {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
}
.containers-status-container .container-status .box .wrapper-text .mrn-number div {
  color: #fff;
  letter-spacing: 7px;
  font-size: 32px;
}
.containers-status-container .container-status .box .wrapper-text .mrn-number div span {
  color: #00edc2;
}
.containers-status-container .container-status .box .wrapper-text .line {
  border-bottom: 1px solid #00edc2;
  position: relative;
}
.containers-status-container .container-status .box .wrapper-text .margin-right {
  margin-right: 24px;
}
.containers-status-container .container-status .box .green {
  color: #00edc2;
}
.containers-status-container .container-status .input-wrapper {
  margin-top: 24px;
  display: flex;
  position: relative;
  max-width: 500px;
}
.containers-status-container .container-status .input-wrapper::after {
  width: 310px;
  height: 85px;
  background-image: url("../img/status-kontenerow/statek-holownik.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 100px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  content: '';
  z-index: 2;
  position: absolute;
}
@media (max-width: 768px) {
  .containers-status-container .container-status .input-wrapper::after {
    display: none;
  }
}
.containers-status-container .container-status input {
  background: #fff 0% 0% no-repeat;
  border: 2px solid #411da9;
  border-radius: 100px;
  width: 500px;
  height: 48px;
  padding-left: 24px;
  position: relative;
  color: #00edc2;
  font-weight: bold;
  padding-right: 43px;
}
.containers-status-container .container-status .button-new {
  background: #00edc2 0% 0% no-repeat;
  border: 2px solid #32efcc;
  border-radius: 100px;
  opacity: 1;
  padding: 7px 32px 9px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #fff;
  text-decoration: none;
}
.containers-status-container .container-status .button-input {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 17px;
  border: 0;
  right: 18px;
  padding: 0;
  cursor: pointer;
  outline: none;
  background: 0 0;
}
.containers-status-container .container-status .button-input::before {
  width: 15px;
  height: 15px;
  background-image: url("../img/status-kontenerow/arrow-button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 0px;
  right: 0px;
  margin-right: 0;
  content: '';
  position: absolute;
}
.containers-status-container .container-status .bold {
  font-weight: 800;
}
.containers-status-container .container-status .ships-under-table {
  margin: 40px 0;
  max-width: 100%;
  height: 85px;
}
.containers-status-container .container-status .table-wrapper {
  margin-top: 40px;
  background: none;
  border: 2px solid #dedede;
  border-radius: 23px;
  background-color: #fff;
  padding: 8px 32px;
  width: 100%;
}
.containers-status-container .container-status .table-wrapper.hide {
  display: none;
}
.containers-status-container .container-status .table-wrapper:nth-of-type(3) {
  padding: 32px;
}
@media (max-width: 768px) {
  .containers-status-container .container-status .table-wrapper:nth-of-type(3) {
    padding: 16px;
  }
}
.containers-status-container .container-status .table-wrapper .table-containers {
  width: 100%;
  margin-bottom: 0px;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody.second-table tr:last-of-type {
  display: none;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr {
  margin: 45px 0;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-line {
  margin: 20px;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item {
  width: 25%;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #411da9;
  border-top: 0;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item.rowspan {
  padding: 0;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item.rowspan div {
  padding: 4px 0;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item.hide {
  display: none;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output {
  border-bottom: none;
  padding: 4px 0;
}
@media (max-width: 768px) {
  .containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output {
    padding: 6px;
    font-size: 14px;
  }
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output-1 {
  width: 15%;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output-2 {
  width: 20%;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output-3 {
  width: 20%;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output-4 {
  width: 35%;
}
.containers-status-container .container-status .table-wrapper .table-containers tbody tr .table-item-output-5 {
  width: 10%;
}
.ship-movement-container {
  width: 100%;
  margin: 0px auto;
  padding: 0px 20px;
  background: none;
  margin: -88vh auto 0;
  margin-top: calc(-100vh + 111px);
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.ship-movement-container ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  background-color: #fff;
}
.ship-movement-container ::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  background-color: #fff;
  border-radius: 10px;
}
.ship-movement-container ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e2e2e2;
  background-color: #fff;
}
.ship-movement-container .sidebar-ship-movement-wrapper {
  padding: 30px 40px;
  position: absolute;
  background: #fff;
  width: 500px;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: flex-end;
  transform: translateX(0px);
  transition: all 0.5s;
}
.ship-movement-container .sidebar-ship-movement-wrapper.button-click {
  transform: translateX(-480px);
  transition: all 0.5s;
}
.ship-movement-container .sidebar-ship-movement-wrapper .select2-selection__placeholder {
  color: #411da9 !important;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-button-wrapper {
  position: absolute;
  top: 32px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #fff;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-button {
  width: 27px;
  height: 27px;
  border-radius: 100px;
  background: none;
  border: 0;
  top: 0;
  left: 6px;
  bottom: 0;
  right: 0;
  position: relative;
  cursor: pointer;
  outline: 0;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
  transition: all 0.5s;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-button::after {
  position: absolute;
  content: '';
  background-image: url("../img/ruch-statkow/arrow-button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 14px;
  height: 12px;
  transform: rotate(0deg);
  transition: all 0.5s;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-button.rotate-icon {
  transition: all 0.5s;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-button.rotate-icon::after {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-content-wrapper {
  border: 2px solid #f5f5f5;
  border-radius: 22px;
  width: 100%;
  padding: 50px 30px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1450px) {
  .ship-movement-container .sidebar-ship-movement-wrapper .sidebar-content-wrapper {
    padding: 30px;
  }
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-content-wrapper .content-after-port-choose {
  padding: 0 20px;
  display: block;
  height: 100%;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-content-wrapper .content-after-port-choose.hidden {
  display: none;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-content-wrapper .content-after-port-choose .overflow-content {
  height: 100%;
  overflow-y: auto;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper {
  background-color: #fff;
  position: absolute;
  width: 100%;
  border-radius: 22px;
  height: 100%;
  top: 0;
  z-index: 10;
  right: 0;
  overflow-x: hidden;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-image {
  width: 100%;
  height: 260px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper {
  padding: 30px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper .ship-info-close {
  font-size: 12px;
  line-height: 16px;
  color: #00edc2;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  margin-left: 16px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper .ship-info-close::before {
  position: absolute;
  content: '';
  background-image: url("../img/ruch-statkow/arrow-button-close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  left: -16px;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper .ship-info-name {
  font-size: 32px;
  line-height: 43px;
  color: #411da9;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper .ship-info-type-wrapper {
  display: flex;
  align-items: center;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper .ship-info-type-wrapper svg {
  width: 10px;
  height: 10px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-info-wrapper .ship-info-text-wrapper .ship-info-type-wrapper p {
  font-size: 12px;
  line-height: 16px;
  color: #411da9;
  margin-left: 8px;
  margin-bottom: 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper select.ship-movement {
  border: 2px solid #411da9 !important;
  border-radius: 100px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .sidebar-label {
  font-size: 32px;
  line-height: 43px;
  color: #411da9;
  font-weight: 400;
  margin-bottom: 16px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper {
  height: unset;
  visibility: visible;
}
@media (max-width: 1450px) {
  .ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper {
    height: unset;
  }
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper.hidden {
  visibility: hidden;
  height: 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper.more-ship {
  height: 304px;
  overflow-y: scroll;
  visibility: visible;
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 5;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper > div {
  height: 100%;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper .legend-item-port {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper .legend-item-port.hidden {
  display: none;
}
@media (max-width: 440px) {
  .ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper .legend-item-port {
    font-size: 12px;
  }
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper .legend-item-port .btn-add {
  width: 8px;
  height: 8px;
  transform: rotate(133deg);
  margin-right: 16px;
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper .legend-item-port .text-wrapper label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #411da9;
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-wrapper .legend-item-port .text-wrapper .svg {
  margin-right: 10px;
  width: 27px;
  height: 11px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper {
  height: auto;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper.margin-top {
  padding-top: 16px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper.line {
  position: relative;
  margin-bottom: 24px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper.line::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #eee;
  bottom: 0;
  margin: -10px auto;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper .legend-item-port {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 440px) {
  .ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper .legend-item-port {
    font-size: 12px;
  }
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper .legend-item-port .text-wrapper label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #411da9;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper .legend-item-port .text-wrapper .svg {
  margin-right: 10px;
  width: 27px;
  height: 11px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .selected-categories-wrapper .legend-item-port .btn-delete-from-filtered {
  width: 8px;
  height: 8px;
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 0;
  display: block;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.blue {
  color: #411da9;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.green {
  color: #00edc2;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.ships-in-port {
  margin-bottom: 8px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.clean {
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.hidden {
  display: none;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.filter {
  position: relative;
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.filter::after {
  content: '';
  position: absolute;
  background-color: #00edc2;
  top: 0;
  right: -16px;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 2px;
  transform: rotate(0);
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.filter::before {
  content: '';
  position: absolute;
  background-color: #00edc2;
  top: 0;
  right: -16px;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 2px;
  transform: rotate(90deg);
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.filter-close {
  position: relative;
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-movement-text.filter-close::after {
  content: '';
  position: absolute;
  background-color: #00edc2;
  top: 0;
  right: -16px;
  bottom: 0;
  margin: auto;
  width: 8px;
  height: 2px;
  transform: rotate(0);
  cursor: pointer;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-text-wrapper.hidden {
  display: none;
}
.ship-movement-container .sidebar-ship-movement-wrapper .line-ship-movement {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin: 24px 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .line-ship-movement.first-line {
  margin: 4px 0 24px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .active-ships-in-port p {
  font-size: 18px;
  line-height: 19px;
  color: #411da9;
  font-weight: 500;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-list-svg {
  width: 10px;
  height: 10px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-port-visbility {
  height: 100%;
  display: block;
  visibility: visible;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-port-visbility.hidden {
  display: none;
  visibility: hidden;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-names-list {
  font-size: 12px;
  line-height: 18px;
  color: #411da9;
  height: 100%;
  font-weight: 400;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-names-list p {
  margin-bottom: 4px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-port {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-port .legend-item-port {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 100%;
}
@media (max-width: 440px) {
  .ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-port .legend-item-port {
    font-size: 12px;
  }
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-port .legend-item-port label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #411da9;
}
.ship-movement-container .sidebar-ship-movement-wrapper .ship-legend-port .legend-item-port .svg {
  margin-right: 10px;
  width: 27px;
  height: 11px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module p.loading-weather {
  font-size: 18px;
  line-height: 19px;
  color: #411da9;
  margin-bottom: 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements {
  display: flex;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .temperature {
  font-size: 43px;
  color: #411da9;
  position: relative;
  margin-bottom: 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .temperature::before {
  content: '';
  position: absolute;
  top: 15px;
  right: -8px;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  border: 2px solid #411da9;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .city-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 16px;
  padding-right: 24px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .city-wrapper .city {
  font-size: 18px;
  line-height: 19px;
  color: #411da9;
  margin-bottom: 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .city-wrapper .wind-wrapper {
  display: flex;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .city-wrapper .wind-wrapper .wind {
  font-size: 11px;
  line-height: 13px;
  margin-left: 8px;
  color: #411da9;
  position: relative;
  margin-top: 3px;
  margin-bottom: 0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .text-elements .city-wrapper .wind-wrapper .wind-icon {
  background-image: url("../img/ruch-statkow/send.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  width: 10px;
  height: 10px;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .icon-wrapper {
  position: relative;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .icon-wrapper::before {
  content: '';
  position: absolute;
  left: -16px;
  bottom: -8px;
  margin: auto;
  width: 1px;
  height: 63px;
  background-color: #e0e0e0;
}
.ship-movement-container .sidebar-ship-movement-wrapper .weather-module .icon-wrapper .weather-icon {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  position: relative;
}
body {
  font-size: 18px;
  font-family: frank-new, sans-serif;
  font-weight: 500 !important;
  font-style: medium;
  margin: 0;
}
body header {
  height: 100vh;
  margin-bottom: 50px;
  padding: 20px;
  overflow: hidden;
}
body header.no-margin {
  margin-bottom: 40px;
  padding-bottom: 0;
}
body header .header-bg-img {
  height: 100%;
  position: relative;
}
body header .header-bg-img .border-container {
  z-index: 999;
}
body header .header-bg-img .border-container.border-top {
  position: absolute;
  background-color: #fff;
  height: 20px;
  width: 100%;
  top: -20px;
  z-index: 2;
}
body header .header-bg-img .border-container.border-top ul {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
body header .header-bg-img .border-container.border-left {
  position: absolute;
  background-color: #fff;
  height: calc(100% + 40px);
  width: 20px;
  top: -20px;
  left: -20px;
}
body header .header-bg-img .border-container.border-left ul li {
  transform: rotate(270deg);
}
body header .header-bg-img .border-container.border-right {
  position: absolute;
  left: 100%;
  background-color: #fff;
  height: calc(100% + 40px);
  top: -20px;
  width: 20px;
}
body header .header-bg-img .border-container.border-right ul li {
  transform: rotate(90deg);
}
body header .header-bg-img .border-container ul {
  list-style-type: none;
  font-family: frank-new, sans-serif;
  font-weight: 500 !important;
  font-size: 12px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 10px;
  padding: 0;
  height: 100%;
}
.circle-loader {
  position: absolute;
  z-index: 10;
  width: 30px;
  height: 30px;
  bottom: 22px;
  left: calc(50% - 70px);
}
.circle-loader .circle-loader-wrap {
  overflow: hidden;
  position: relative;
  margin-top: -10px;
  width: 30px;
  height: 30px;
  background-color: #20194c;
  border: solid 2px #00edc2;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 1px #00edc2;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
.circle-loader .circle-loader-wrap .loader {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #11727b;
  z-index: 50;
}
.circle-loader .circle-loader-wrap .left-wrap {
  left: 0;
  overflow: hidden;
  position: relative;
  width: 50%;
  height: 100%;
}
.circle-loader .circle-loader-wrap .left-wrap .loader {
  position: absolute;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transform-origin: 0 50% 0;
  -webkit-transform-origin: 0 50% 0;
  animation: loading-left 6s infinite linear;
  -webkit-animation: loading-left 6s infinite linear;
}
.circle-loader .circle-loader-wrap .right-wrap {
  left: 50%;
  top: -100%;
  overflow: hidden;
  position: relative;
  width: 50%;
  height: 100%;
}
.circle-loader .circle-loader-wrap .right-wrap .loader {
  left: -100%;
  position: absolute;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  transform-origin: 100% 50% 0;
  -webkit-transform-origin: 100% 50% 0;
  animation: loading-right 6s infinite linear;
  -webkit-animation: loading-right 6s infinite linear;
}
.circle-loader .circle-loader-wrap .circle-loader-button-behind {
  background-color: #20194c;
  position: absolute;
  left: 4px;
  top: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  z-index: 100;
  transform: rotate(180deg);
}
.circle-loader .circle-loader-wrap .circle-loader-button-behind .svg {
  height: 90%;
  width: 100%;
  position: absolute;
  top: 1px;
  left: 1px;
}
.circle-loader .circle-loader-wrap .circle-loader-button {
  background-color: #20194c;
  position: absolute;
  left: 4px;
  top: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 120px);
}
.circle-loader .circle-loader-wrap .circle-loader-button .svg {
  height: 90%;
  width: 100%;
  position: absolute;
  top: 2px;
}
@-moz-keyframes loading-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes loading-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-o-keyframes loading-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes loading-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-moz-keyframes loading-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes loading-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@-o-keyframes loading-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes loading-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
#wrapper-karuzeli {
  height: 100%;
  margin-top: -147px;
  background: #110047;
}
.owl-stage-outer {
  height: 100%;
}
.owl-stage-outer .owl-stage {
  height: 100% !important;
}
.owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel {
  height: 100%;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .owl-dots {
  margin-top: -55px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 12px;
  font-weight: 400 !important;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .owl-dots .owl-dot {
  margin: 0px 5px 5px 5px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .owl-dots .owl-dot:hover {
  color: #00edc2;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .owl-dots .owl-dot.active {
  color: #00edc2;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .owl-dots::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 180px;
  background: rgba(0,237,194,0.3);
  left: calc(50% - 90px);
  top: 35%;
  z-index: -1;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide {
  height: 100%;
  margin: 0 auto;
  padding-top: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .single-slide-background {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .granatowa-blenda {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  background: rgba(17,0,71,0.4);
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .green-circle-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .green-circle-wrapper .green-circle {
  height: 800px;
  width: 800px;
  margin-top: 4%;
  margin-left: 6%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0,237,194,0.3) 0%, rgba(0,237,194,0) 60%, rgba(0,237,194,0) 100%);
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .single-slide-seashore {
  background-image: url("../img/banner_seashore.svg");
  background-repeat: no-repeat;
  background-position: 50% 105%;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .bottomstrip {
  background-color: #110047;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  max-width: 1440px;
  height: 100%;
  max-height: 880px;
  margin: 0 auto;
  padding-bottom: 180px;
  padding-top: 60px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .slide-text h1 {
  margin-top: 0px;
  font-size: 80px;
  font-family: frank-new, sans-serif;
  font-weight: 500 !important;
  font-style: normal;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .slide-text h2 {
  font-size: 40px;
  font-family: frank-new, sans-serif;
  font-weight: 200 !important;
  font-style: normal;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .slide-text ul {
  color: #000;
  font-size: 19px;
  font-family: frank-new, sans-serif;
  font-weight: 400 !important;
  font-style: regular;
  display: flex;
  width: 100%;
  padding-inline-start: 0px;
  color: #fff;
  margin-top: 4%;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .slide-text ul li {
  color: #fff;
  list-style-type: none;
  padding-right: 40px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .slide-text ul li::before {
  content: '';
  width: 1px;
  height: 1px;
  display: inline-block;
  padding: 0.25em;
  margin-right: 0.5em;
  background-color: rgba(32,24,85,0.5);
  border: solid 2px #00edc2;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 1px #00edc2;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .slide-text ul li:hover:before {
  cursor: pointer;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .btn-more {
  font-size: 14px;
  font-family: frank-new, sans-serif;
  font-weight: 400 !important;
  font-style: regular;
  width: 160px;
  height: 40px;
  background-color: rgba(32,24,85,0.5);
  color: #fff;
  transition: all 0.3s linear;
  border: solid 1px #00edc2;
  border-radius: 20px;
}
.owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .slide-content .btn-more:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 1px #00edc2;
}
@media (min-width: 2190px) {
  body header {
    padding: 20px 20px 0px 20px;
  }
  body header .bottomstrip {
    bottom: 0px;
  }
  body header .header-bg-img {
    background-size: cover;
    background-position: 0%;
  }
  body header .header-bg-img .circle-loader {
    bottom: 22px;
  }
  body header .owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .green-circle-wrapper .green-circle {
    height: 1000px;
    width: 1000px;
    margin-left: 8%;
    margin-top: 3%;
  }
  body header .owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .single-slide-seashore {
    background-position: 0% 700px;
  }
}
@media (max-width: 1600px) {
  body header .owl-stage-outer .owl-stage .owl-item .owl-carousel .singleSlide .green-circle-wrapper .green-circle {
    margin-left: 8%;
    margin-top: 6%;
  }
}
@media (max-width: 1245px) {
  body header .header-bg-img {
    background-position: 50% 100%;
    background-size: auto;
  }
}
@media (max-width: 1024px) {
  body {
    padding: 0px;
  }
  body header {
    padding: 0px;
  }
  body header .header-bg-img #wrapper-karuzeli {
    margin-top: -100vh;
    margin: 0px;
  }
  body header .header-bg-img .owl-carousel {
    height: 100vh;
    margin: 0px;
  }
  body header .header-bg-img .owl-carousel .owl-dots {
    bottom: 85px;
    margin-top: 0px !important;
  }
  body header .header-bg-img .owl-carousel .owl-dots::before {
    width: 80px;
    left: 46%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer {
    height: 100vh;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage {
    height: 860px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-dots {
    bottom: 45px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .singleSlide .single-slide-background {
    background-size: cover;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .singleSlide .slide-content {
    max-width: 1440px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .singleSlide .slide-content .slide-text {
    margin-top: 100px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .singleSlide .slide-content .slide-text h1 {
    font-size: 60px;
    line-height: 55px;
    margin-bottom: 10px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .singleSlide .slide-content .slide-text h2 {
    font-size: 30px;
    padding-bottom: 20px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .singleSlide .slide-content .slide-text ul {
    width: 80%;
    font-size: 16px;
    margin-bottom: 150px;
  }
  body header .header-bg-img .circle-loader {
    visibility: hidden;
  }
}
@media (max-width: 768px) {
  body header .header-bg-img {
    background-position: 72% 99%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    height: 100%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text ul,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text ul {
    width: 100%;
    font-size: 14px;
  }
}
@media (max-width: 616px) {
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content {
    padding-top: 0px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text h1,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text h1 {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 10px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text h2,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text h2 {
    font-size: 20px;
    padding-bottom: 20px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text ul,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text ul {
    display: table-row;
    margin-bottom: 10%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text ul li,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text ul li {
    margin: 0px auto;
    padding-top: 1px;
  }
}
@media (max-width: 540px) {
  body header .header-bg-img {
    background-position: 72% 97%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    height: 100%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide {
    height: 600px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content {
    padding: 0px;
    margin: 0 auto;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text h1,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text h1 {
    font-size: 35px;
    line-height: 40px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text h2,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text h2 {
    padding-bottom: 20px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text ul,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text ul {
    display: table-row;
    margin-bottom: 10%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-dots .owl-item .singleSlide .slide-content .slide-text ul li,
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .singleSlide .slide-content .slide-text ul li {
    margin: 0px auto;
    padding-top: 1px;
  }
}
@media (max-width: 440px) {
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item {
    height: 100%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .owl-dots {
    bottom: 45px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .owl-dots .slide-content {
    padding-bottom: 0px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .owl-dots .slide-content .slide-text h1 {
    font-size: 35px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .owl-dots .slide-content .slide-text h2 {
    padding-bottom: 20px;
    font-size: 25px;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .owl-dots .slide-content .slide-text ul {
    display: table-row;
    margin-bottom: 10%;
  }
  body header .header-bg-img .owl-carousel .owl-stage-outer .owl-stage .owl-item .owl-dots .slide-content .slide-text ul li {
    margin: 0px auto;
    padding-top: 1px;
  }
}
.mainPartners {
  padding: 0px 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainPartners .portsTilesWrapper {
  margin-top: 100px;
  width: 100%;
}
.mainPartners .portsTilesWrapper ul {
  list-style-type: none;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .mainPartners .portsTilesWrapper ul {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .mainPartners .portsTilesWrapper ul {
    grid-template-columns: repeat(3, 1fr);
  }
}
.mainPartners .portsTilesWrapper ul li {
  border: solid 1px #00edc2;
  width: 100%;
  min-width: 33%;
  margin: 3px;
  padding: 4em 2em 6em 2.5em;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainPartners .portsTilesWrapper ul li img {
  margin-top: 16px;
  max-width: 90%;
}
.mainPartners .portsTilesWrapper ul li a {
  text-decoration: none;
  color: #00edc2;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 20px;
}
.mainPartners .portsTilesWrapper ul li p {
  margin: 0px 0px 16px;
  width: 100%;
  font-size: 14px;
  line-height: 2em;
  font-weight: 400 !important;
  color: #110047;
}
.mainPartners .row-elements-4 {
  width: 80%;
  max-width: 1440px;
}
.mainPartners .row-elements-4 ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}
.mainPartners .row-elements-4 ul li {
  border: solid 1px #00edc2;
  margin: 3px;
  margin-bottom: 40px;
  padding: 50px 2em;
  width: 24%;
  height: 215px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.mainPartners .row-elements-3 {
  width: 100%;
  max-width: 1440px;
}
@media (max-width: 1245px) {
  .mainPartners {
    padding: 0px;
  }
  .mainPartners .row-elements-3 ul {
    width: 100%;
    align-items: center;
    margin: auto 0px;
    flex-direction: column;
  }
  .mainPartners .row-elements-3 ul li {
    width: 66%;
  }
}
@media (max-width: 1024px) {
  .mainPartners .row-elements-4 ul li {
    min-width: 200px;
  }
  .mainPartners .row-elements-3 ul {
    width: 100%;
    align-items: center;
    margin: auto 0px;
    flex-direction: column;
  }
  .mainPartners .row-elements-3 ul li {
    width: 66%;
  }
}
@media (max-width: 768px) {
  .mainPartners .row-elements-3 ul {
    align-items: center;
    flex-direction: column;
  }
  .mainPartners .row-elements-3 ul li {
    width: 60%;
  }
}
@media (max-width: 768px) {
  .mainPartners .row-elements-3 ul li {
    width: 98%;
    padding: 4em 0.5em 6em 1em;
  }
}
.joinUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 80%;
  margin: 0 auto;
}
.joinUs h3 {
  color: #00edc2;
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 0px;
}
.joinUs h4 {
  color: #00edc2;
  font-size: 24px;
  font-weight: 400;
  margin-top: 5px;
}
.joinUs p {
  color: #110047;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  text-align: center;
}
.trustedUs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trustedUs .titleWrapper2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  justify-content: flex-start;
}
.trustedUs .titleWrapper2 h1 {
  margin: 0 43px 0 0;
  color: #26009e;
  font-size: 52.95px;
  padding: 0;
  font-weight: 100;
  align-items: flex-start;
}
.trustedUs .titleWrapper2 h2 {
  font-size: 36px;
  font-weight: 400;
}
.trustedUs p {
  font-weight: 400;
  word-spacing: 8px;
  line-height: 1.5em;
  color: #110047;
  width: 80%;
}
.trustedUs .companyTilesWrapper {
  margin-top: 60px;
}
.trustedUs .companyTilesWrapper ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  justify-content: center;
  overflow: hidden;
}
.trustedUs .companyTilesWrapper ul li {
  border: solid 1px #00edc2;
  margin: 3px;
  margin-bottom: 40px;
  padding: 50px 0px;
  width: 275px;
  height: 215px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.trustedUs .companyTilesWrapper ul li a {
  text-decoration: none;
  color: #00edc2;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 1024px) {
  #partnersWrapper {
    margin-top: -450px;
  }
  #partnersWrapper .partnersList .partnersIntro ul.aboutMenu {
    height: 70px;
    position: relative;
    flex-direction: column;
  }
  #partnersWrapper .partnersList .partnersIntro ul.aboutMenu span {
    position: absolute;
    right: 0px;
    top: 20px;
    visibility: visible;
  }
  #partnersWrapper .partnersList .partnersIntro ul.aboutMenu li {
    flex-direction: column;
    display: none;
    width: 300px;
    padding-right: 0px;
  }
  #partnersWrapper .partnersList .partnersIntro ul.aboutMenu li:nth-child(4) {
    background-position: 16%;
  }
}
.partners-slider {
  margin-top: 100px;
}
.partners-slider .owl-item {
  border: 1px solid #dcdcdc;
  border-top: none;
  border-bottom: none;
}
.partners-slider .owl-item:nth-of-type(add) {
  border-left: none;
}
.partners-slider .owl-dots {
  display: none;
}
.noMenu2 {
  padding: 0;
  margin: -400px auto 0 auto !important;
  position: relative !important;
  min-height: 300px !important;
}
.noMenu2 .container .wrapper-top {
  border-top-right-radius: 20px;
}
.noMenu2 .container .wrapper-top .menu-intro {
  visibility: hidden;
}
#systemPcsImporterzy {
  margin: 0;
}
#systemPcsImporterzy .general-mockup-wrapper {
  width: 100%;
}
#systemPcsImporterzy .general-mockup-wrapper .container {
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  #systemPcsImporterzy .general-mockup-wrapper .container {
    padding: 0 40px;
  }
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box {
  margin: 0;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .header {
  background-image: none;
  margin: 0;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .header h1 {
  padding: 65px 0 0 0;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper h2 {
  width: 100%;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper .h2 {
  margin: 0;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .header .wrapper .h2 ul li:before {
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin: 0 10px 0 0;
  border-radius: 50%;
  border: 1px solid #00edc2;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .header .header-to-button .button {
  display: none;
  width: 0;
}
#systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
  display: flex;
  left: 99%;
  top: 0;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 0;
}
@media (max-width: 1751px) {
  #systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
    left: 80%;
  }
}
@media (max-width: 1230px) {
  #systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
    display: none;
  }
}
@media (max-width: 616px) {
  #systemPcsImporterzy .general-mockup-wrapper .container .what-is-pcs .left-box .rightBox {
    width: 100%;
  }
}
.noMenu {
  margin: -400px auto 0 auto !important;
  position: relative !important;
  min-height: 300px !important;
}
.noMenu .container .wrapper-top .headers {
  width: 100%;
}
.noMenu .container .wrapper-top .menu-intro {
  display: none !important;
}
@media (max-width: 1024px) {
  .noMenu {
    margin-top: -400px !important;
  }
}
@media (max-width: 768px) {
  #top .container .wrapper-top {
    padding: 30px 40px 0;
  }
}
#singleNewsWrapper {
  font-family: frank-new, sans-serif;
  font-weight: 500 !important;
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -270px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#singleNewsWrapper .text-wrapper {
  padding: 0px;
  border: none;
}
#singleNewsWrapper .singleNews {
  font-size: 16px;
  width: 80%;
  max-width: 1440px;
  min-height: 500px;
  background-color: #fff;
  border-top-right-radius: 20px;
  overflow: hidden;
  padding: 55px 100px;
}
#singleNewsWrapper .singleNews .go-back-news {
  display: flex;
  flex-flow: row;
  height: 30px;
  margin: 30px 10px 60px 0px;
  height: 15px;
}
#singleNewsWrapper .singleNews .go-back-news .go-back-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-flow: row;
  height: 10px;
}
#singleNewsWrapper .singleNews .go-back-news .go-back-btn .box-for-arrow {
  margin: -3px 10px 0 0;
  width: 10px;
  height: 30px;
}
#singleNewsWrapper .singleNews .go-back-news .go-back-btn .box-for-arrow img {
  height: 10px;
}
#singleNewsWrapper .singleNews .go-back-news .go-back-btn a {
  color: #309;
  font-weight: 500 !important;
  text-decoration: none;
  font-size: 14px;
}
#singleNewsWrapper .singleNews .textes {
  margin: 0px;
  width: 100%;
}
#singleNewsWrapper .singleNews h1 {
  font-size: 16px;
  margin: 60px auto;
}
#singleNewsWrapper .singleNews p {
  font-weight: 400 !important;
  word-spacing: 8px;
  line-height: 1.5em;
}
#singleNewsWrapper .singleNews .singleNewsImage {
  border: 1px solid #f00;
  background-image: url("../img/singleNewsPicture.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 505px;
  width: 100%;
  margin-top: 12%;
  margin-bottom: 10%;
}
#singleNewsWrapper .singleNews .newsShare ul {
  list-style-type: none;
  font-weight: 400 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding-left: 0px;
}
#singleNewsWrapper .singleNews .newsShare ul li {
  margin: 70px 5px 20px 11px;
  box-sizing: border-box;
}
#singleNewsWrapper .singleNews .newsShare ul li:nth-child(n+2) {
  padding: 3px;
  margin: 0 5px;
  border: 1px solid #00edc2;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
  justify-content: center;
  color: #309;
  cursor: pointer;
}
#singleNewsWrapper .singleNews .newsShare ul li:nth-child(n+2):hover {
  box-shadow: 0px 0px 0px 3px #00edc2;
}
.small header .header-bg-img .title h1 {
  font-size: 36px;
}
.small header .header-bg-img .title h2 {
  font-size: 16px;
  color: #00edc2;
  margin-top: 10px;
}
@media (max-width: 1440px) {
  #singleNewsWrapper .singleNews .singleNewsImage {
    height: 35vw;
  }
}
@media (max-width: 1024px) {
  #singleNewsWrapper {
    margin-top: -450px;
  }
}
@media (max-width: 769px) {
  #singleNewsWrapper .singleNews {
    width: 90%;
    padding: 40px 40px;
  }
}
@media (max-width: 616px) {
  #singleNewsWrapper .singleNews {
    width: 100%;
    padding: 40px;
    border-top-left-radius: 20px;
  }
}
@media (max-width: 640px) {
  .small header .header-bg-img .title h1 {
    font-size: 24px;
  }
  #singleNewsWrapper .singleNews {
    padding: 2em 1.5em;
  }
}
#newsWrapper {
  font-family: frank-new, sans-serif;
  font-weight: 500 !important;
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}
#newsWrapper .newsList {
  max-width: 1440px;
  font-size: 16px;
  width: 100%;
  background-color: #fff;
  border-top-right-radius: 20px;
}
#newsWrapper .newsList .year {
  background-image: url("../img/polokrag_zielony.svg");
  background-repeat: no-repeat;
  background-position: 100%;
  height: 2em;
  width: 80px;
  margin: 1.4em auto 1.6em 3.85em;
  padding-top: 8px;
  font-size: 28px;
  font-weight: 300 !important;
  color: #309;
}
#newsWrapper .newsList ul {
  list-style-type: none;
  padding: 0px;
}
#newsWrapper .newsList ul li {
  padding-top: 10px;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
}
#newsWrapper .newsList ul li:hover {
  background-color: #e8fff9;
}
#newsWrapper .newsList ul li .addDate {
  padding-right: 40px;
  width: 23%;
}
#newsWrapper .newsList ul li .addDate .addDatebox {
  border-bottom: solid 1px #309;
}
#newsWrapper .newsList ul li .addDate .addDatebox p {
  color: #00edc2;
  font-weight: 500 !important;
  text-align: right;
  margin-bottom: 0.3em;
}
#newsWrapper .newsList ul li .addDate .addDatebox p span {
  font-weight: 400 !important;
}
#newsWrapper .newsList ul li .newsContent {
  width: 77%;
  padding-left: 1.6em;
  padding-right: 6em;
  padding-bottom: 2em;
}
#newsWrapper .newsList ul li .newsContent h4 {
  margin-top: 1em;
  color: #110047;
}
#newsWrapper .newsList ul li .newsContent p {
  font-weight: 400 !important;
}
#newsWrapper .newsList ul li .newsContent p a {
  color: #00edc2;
}
#newsWrapper .newsList ul li .newsContent a {
  color: #00edc2;
  display: flex;
  flex-flow: row;
}
#newsWrapper .newsList ul li .newsContent a .readMoreBtn {
  background-color: transparent;
  border: none;
  color: #309;
  padding-left: 0px;
  width: 105px;
  text-align: left;
  cursor: pointer;
  outline: none;
}
#newsWrapper .newsList ul li .newsContent a .box-for-arrow2 {
  width: 10px;
  height: 30px;
  transform: rotate(180deg);
}
#newsWrapper .newsList ul li .newsContent a .box-for-arrow2 img {
  height: 10px;
}
#newsWrapper .newsList ul li .newsContent .newsImg {
  width: 330px;
  height: 200px;
  background: #f00;
  margin-top: 2em;
  margin-bottom: 1em;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
#newsWrapper .newsPagination {
  background-color: #fff;
  height: 45px;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 400 !important;
  position: relative;
}
#newsWrapper .newsPagination::before {
  content: '';
  background: #00edc2;
  width: 100px;
  height: 1px;
  position: absolute;
  left: calc(50% - 50px);
  top: 73%;
  opacity: 0.3;
}
#newsWrapper .newsPagination a {
  background: transparent;
  display: inline-block;
  padding: 4px 8px;
  text-decoration: none;
  color: #000;
}
#newsWrapper .newsPagination a:first-child {
  color: #00edc2;
}
#newsWrapper .newsPagination a:last-child {
  color: #00edc2;
}
#newsWrapper .newsPagination a:hover {
  color: #00edc2;
}
#newsWrapper .newsPagination a:active {
  color: #00edc2;
}
@media (max-width: 1024px) {
  #newsWrapper {
    margin-top: 0;
  }
  #newsWrapper .newsList {
    width: 90%;
  }
}
@media (max-width: 768px) {
  #newsWrapper .newsList .year {
    margin-left: 1.85em;
  }
  #newsWrapper .newsList ul li .addDate {
    padding-right: 15px;
  }
  #newsWrapper .newsList ul li .newsContent {
    padding-right: 3em;
  }
}
@media (max-width: 616px) {
  #newsWrapper .newsList {
    width: 100%;
    border-top-left-radius: 20px;
  }
}
@media (max-width: 540px) {
  #newsWrapper .newsList .year {
    margin-left: 1.5em;
  }
  #newsWrapper .newsList ul li {
    flex-direction: column;
  }
  #newsWrapper .newsList ul li .addDate {
    width: 100%;
  }
  #newsWrapper .newsList ul li .addDate .addDatebox p {
    text-align: left;
    padding-left: 2.5em;
  }
  #newsWrapper .newsList ul li .newsContent {
    width: 100%;
    padding-right: 2.5em;
    padding-left: 2.5em;
  }
}
.content-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20vh 0;
  position: relative;
}
.content-404 a.button-green {
  background: #00edc2 0% 0% no-repeat;
  border: 2px solid #32efcc;
  border-radius: 100px;
  opacity: 1;
  padding: 7px 32px 9px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #fff !important;
  text-decoration: none;
  margin-bottom: 40px;
}
.content-404 h2.header-404 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: 0;
  color: #411da9;
}
@media (min-width: 768px) {
  .content-404 h2.header-404 {
    font-size: 60px;
    line-height: 80px;
  }
}
.content-404 p.description {
  font-family: "frank-new", "sans-serif" !important;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #411da9;
  margin-bottom: 29px;
}
.content-404 img.ships-404 {
  position: absolute;
  bottom: 50px;
  left: 40px;
  width: 80%;
}
@media (min-width: 768px) {
  .content-404 img.ships-404 {
    width: auto;
  }
}
.content-404 img.img-404 {
  width: 80%;
  max-width: 450px;
}
@media (min-width: 768px) {
  .content-404 img.img-404 {
    width: 600px;
    max-width: unset;
  }
}
@media (min-width: 1440px) {
  .content-404 img.img-404 {
    width: auto;
  }
}
