*,*:focus,*:hover
  outline:none



.containers-status-background-1
  overflow-x:hidden
  position: absolute
  z-index: -1
  top: 0
  right: 0
  left: 0
  bottom: 0
  @media (max-width: 1439px)
    display:none
  &::before
    width:550px
    height:550px
    background-image:url('../img/status-kontenerow/background.svg')
    background-repeat:no-repeat
    background-size:contain
    top:10px
    right: -25px
    margin-right:0
    content:''
    position: absolute
    @media (max-width: 1600px)
      right:-120px

.containers-status-background-2
  overflow-x:hidden
  position: absolute
  z-index: -1
  top: 0
  right: 0
  left: 0
  bottom: 0
  @media (max-width: 1439px)
    display:none
  &::before
    width:550px
    height:550px
    background-image:url('../img/status-kontenerow/background2.svg')
    background-repeat:no-repeat
    background-size:contain
    top:10px
    right: -25px
    margin-right:0
    content:''
    position: absolute


.containers-status-container
  width: 80%
  max-width: 1440px
  margin: 0px auto
  background: none
  margin: -80vh auto 0
  z-index: 1;
  position: relative
  @media (max-width: 1300px), (max-height: 860px)
    margin: -80vh auto 0
  @media (max-width: 1100px)
    margin: -80vh auto 0
  &.smaller
    @media (max-width: 768px)
      width:90%
  .container-status
    h2
      text-align: left
      font-weight: bold
      font-size :60px
      line-height: 78px
      letter-spacing: 0px
      color: #411DA9
      @media (max-width: 768px)
        font-size :36px
        line-height: 48px


    h3
      text-align: left
      font-size:18px
      line-height: 28px
      font-weight:medium
      letter-spacing: 0px
      color: #00EDC2
      @media (max-width: 2000px)
        font-size :16px
        line-height: 26px
      @media (max-width: 1600px)
        font-size :14px
        line-height: 14px
      @media (max-width: 1200px)
        font-size :12px
        line-height: 12px
        overflow:scroll

    h4
      text-align: left
      font-weight: 100
      font-size :30px
      line-height: 39px
      letter-spacing: 0px
      color: #411DA9
      @media (max-width: 768px)
        font-size :24px
        line-height: 31px

    p
      text-align: left
      font-weight: 400
      font-size :14px
      line-height: 22px
      letter-spacing: 0px
      color: #411DA9
      margin:60px 0
      max-width:500px


    .search-selector-wrapper
      width: 100%
      display: flex
      div
        width: auto;
        cursor: pointer;
        font-size: 30px;
        line-height: 39px;
        color: #00EDC2
        opacity: 0.2
        text-transform: uppercase
        font-weight: 400
        position: relative
        &:nth-of-type(1) {
          margin-right: 30px;
        }
        &.selected
          opacity: 1
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #00EDC2
            left: 0;
          }
    .wrapper
      display:flex
      width:100%
      @media (max-width: 1600px)
        justify-content:space-between
      @media (max-width: 768px)
        flex-direction: column
       .checkbox-wrapper
        display: flex
      .container-input
        display: none
        position: relative
        margin-left: 25px
        font-size: 12px
        color: #411DA9
        font-weight: 400
        margin-bottom: 0
        margin-top: 20px
        line-height: 18px
        &.show
          display: flex
        &:nth-of-type(2){
          margin-left: 50px;
        }


        //input[type=radio]
        //  display: block !important
        //  width: auto

        input ~ .checkmark
          top: 0
          background-color: transparent
          border: 2px solid #411DA9
          width: 18px
          height: 18px


        .checkmark:after, .checkmarked:after
          background-color: #00EDC2
          width: 8px
          height: 8px
          border: none
          border-radius: 50%
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)

    .left-side
      width:50%
      @media (max-width: 768px)
        width:100%
      .search-description
        display: none;
        p {
          display: none;
        }
        &.show
          display: flex;
          p {
            display: flex;
          }

    .right-side
      width:45%
      display: none
      &.show
        display: block
      @media (max-width: 768px)
        width:100%

    .box
      background: #411DA9 0% 0% no-repeat
      border-radius: 0px 20px
      padding:50px
      margin-top: 20%
      position: relative;
      z-index: 2;
      @media (max-width: 1024px)
        padding: 32px;
      &::after
        width:112px
        height:68px
        background-image:url('../img/status-kontenerow/kontener.svg')
        background-repeat:no-repeat
        background-size:contain
        top: -40px
        left: 0px
        right:0px
        margin:0 auto
        content:''
        z-index:2
        position: absolute
      &::before
        width:138px
        height:63px
        background-image:url('../img/status-kontenerow/statek-port.svg')
        background-repeat:no-repeat
        background-size:contain
        top: 30%
        left: -90px
        content:''
        z-index:-2
        position: absolute
        @media (max-width: 1024px)
          display:none
      p
        font-size:12px
        line-height:20px
        color:#fff
        margin:8px 0

      .wrapper-text
        display: flex
        @media (max-width: 1600px)
          flex-direction: column
        p
          font-size:32px
          line-height:41px
          font-weight:bold
          letter-spacing: 14px
          @media (max-width: 1439px)
            letter-spacing: 5px
        .small
          font-size:12px
          line-height:15px
          letter-spacing: 0px
          text-align:center
        .mrn-number
          div
            color: white
            letter-spacing: 7px;
            font-size: 32px;
            span
              color: #00EDC2
        .line
          border-bottom: 1px solid #00EDC2
          position: relative
        .margin-right
          margin-right:24px



      .green
        color: #00EDC2



    .input-wrapper
      margin-top:24px
      display:flex
      position: relative
      max-width: 500px
      &::after
        width:310px
        height:85px
        background-image:url('../img/status-kontenerow/statek-holownik.svg')
        background-repeat:no-repeat
        background-size:contain
        top: 100px
        left: 0px
        right:0px
        margin:0 auto
        content:''
        z-index:2
        position: absolute
        @media (max-width: 768px)
          display:none

    input
      background: #FFFFFF 0% 0% no-repeat
      border: 2px solid #411DA9
      border-radius: 100px
      width: 500px
      height: 48px
      padding-left:24px;
      position relative
      color:#00edc2
      font-weight bold
      padding-right: 43px


    .button-new
      background: #00EDC2 0% 0% no-repeat
      border: 2px solid #32EFCC
      border-radius: 100px
      opacity: 1
      padding:7px 32px 9px
      font-weight: 500
      font-size :16px
      line-height: 22px
      letter-spacing: 0px
      color: #FFFFFF
      text-decoration:none

    .button-input
      position: absolute
      width: 15px
      height: 15px
      top: 17px
      border: 0
      right: 18px
      padding: 0
      cursor: pointer
      outline:none
      background: 0 0
      &::before
        width: 15px
        height: 15px
        background-image:url('../img/status-kontenerow/arrow-button.svg')
        background-repeat:no-repeat
        background-size:contain
        top:0px
        right:0px
        margin-right:0
        content:''
        position: absolute

    .bold
      font-weight: 800

    .ships-under-table
      margin:40px 0
      max-width:100%
      height:85px


    .table-wrapper
      margin-top:40px
      background: none
      border: 2px solid #DEDEDE
      border-radius:23px
      background-color:white
      padding:8px 32px
      width:100%
      &.hide {
        display: none;
      }
      &:nth-of-type(3)
        padding:32px
        @media (max-width: 768px)
          padding:16px
      .table-containers
        width:100%
        margin-bottom:0px
        tbody.second-table
          tr
            &:last-of-type
              display:none
        tbody
          tr
            margin:45px 0
            .table-line
              margin:20px
            .table-item
              width:25%
              font-size:16px
              font-weight:bold
              letter-spacing: 0px;
              color: #411DA9;
              border-top: 0;
              &.rowspan
                padding: 0
                div
                  padding: 4px 0
              &.hide
                display: none
            .table-item-output
              border-bottom: none;
              padding:4px 0
              @media (max-width: 768px)
                padding:6px
                font-size:14px
            .table-item-output-1
              width:15%
            .table-item-output-2
              width:20%
            .table-item-output-3
              width:20%
            .table-item-output-4
              width:35%
            .table-item-output-5
              width:10%




