#top
    margin: -260px auto 0 auto
    position: relative
    // min-height: 300px
    font-family: frank-new, sans-serif
    padding: 0
    max-width: 1440px
    z-index: 0
    border-top-right-radius: 20px
    margin: 0px auto
    background-color: white
    @media (max-width: 1024px)
        // margin: -450px auto 10px auto 
        // min-height: 450px 
        // max-height: 6000px
    @media (max-width: 768px)
        border-top-right-radius: 20px
    @media (max-width: 616px)
        border-top-right-radius: 20px
        border-top-left-radius: 20px
        width: 100%  !important
    &.centered
        .wrapper-top
            .headers
                display block !important
           *
                text-align: center
            button
                display: block !important
                max-width: 100%
                margin-top: 80px
                margin-left: auto
                margin-right: auto
            p
                margin: 0
            h2
                margin-top: 50px
                margin-bottom: 40px
                font-weight: 400
                font-size: 30px


    .container
        border-top-right-radius: 20px
        display: flex
        flex-flow: column
        align-items: flex-start
        min-width: 100%
        padding: 0
        margin: 0 auto
        z-index: 100
        border-top-right-radius: 20px
        @media (max-width: 1024px)
            border-top-right-radius: 0
        @media (max-width: 616px)
            width: 80%
        .wrapper-top
            display: flex
            flex-flow: column
            padding: 70px 100px 0 100px
            width: 100%
            // min-height: 300px
            position: relative
            border-top-right-radius: 20px
            @media (max-width: 768px)
                padding: 30px 40px 0
            .dark
                display: none
            .headers
                display: flex
                flex-flow: column
                width:100%
                margin: 0px 0 0 0
                font-size: 16px
                color: headerTextBlue
                font-weight: 400 !important
                word-spacing: 8px
                line-height: 1.5em
                ol
                    list-style-type: decimal 
                p
                    width: 100%
                ul
                    padding: 0 0 0 20px
                    list-style-type: none
                    font-size: 16px
                    font-weight: 400 !important
                    li
                        line-height: 2em;
                        list-style-type: none
                        text-align: left
                        &::before
                            content: ''
                            display: inline-block
                            height: .8em
                            width: .8em
                            margin: 10px 10px 0 -20px 
                            border-radius: 50%
                            border: 1px solid pictograms
                            position: absolute 
            .menu-intro
                width: 100%
                display: flex
                flex-flow: column
                align-items: flex-end
                @media (max-width: 1024px)
                    display: none
                .aboutMenuArrow 
                    display: flex
                    width: 100%
                    justify-content: flex-end
                    display: none
                    img 
                        padding-right: 15px
                        cursor: pointer
                    @media (max-width: 1024px)
                        padding-top: 15px
                        padding-bottom: 15px
                        margin: 85px 0 0 0
                        display: flex
                        border-top: 1px solid pcsBlue
                        border-bottom: 1px solid bottomLineGrey
                ul
                    list-style-type: none
                    width: 100%
                    margin-top: 85px
                    padding: 20px 0px
                    margin-top: 80px
                    display: flex
                    border-top: 1px solid pcsBlue
                    border-bottom: 1px solid bottomLineGrey
                    @media (max-width: 1024px)
                        position: relative
                        flex-direction: column
                        margin: 0
                        display: none
                    li
                        padding: 10px 50px 10px 0px
                        font-size: 14px
                        color: pcsTextLightBlue
                        cursor: pointer
                        display:flex
                        position: relative
                        &.active
                            a
                                position relative !important
                                max-height: 21px
                                color pictograms
                                &::after
                                    content: ''
                                    display block
                                    background-image: url(../img/polokrag_zielony.svg)
                                    background-repeat: no-repeat
                                    height 25px
                                    width: 25px
                                    position absolute
                                    right -12px  
                                    top: 0.1px //for safari
                        a
                            text-decoration: none
                            color: headerNumbersBlue
                            font-size: 14px
                            display: flex
                            &:hover
                                color: pictograms


