.headers
    max-width: 1440px
    margin: 0 auto
    font-family: frank-new, sans-serif
    @media (max-width: 768px)
        margin: 0 40px
    .container
        display: flex
        flex-flow: column
        min-width: 100%
        margin: 0
        padding: 0
        @media (max-width: 616px)
            margin: 0
            padding: 0
        .number-tresc-header
            width: 100%
            @media (max-width: 768px)
                flex-flow: column
                align-items: flex-start
                padding: 0
            h1
                margin: 0 35px 0 0
                color: headerNumbersBlue
                font-size: 52.95px
                padding: 0
                font-weight: 100 !important
            h2 
                color: headerTextBlue
                padding: 10px 0 0 0
                margin: 0  
                @media (max-width: 768px)
                    padding: 0

                *
                    font-weight: 400 !important
                    font-size: 36px
                    line-height: 46px