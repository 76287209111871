#systemPcsImporterzy
    margin: 0
    .general-mockup-wrapper
        width: 100%
        .container
            margin: 0
            padding: 0
            @media(max-width: 768px)
                padding: 0 40px
            .what-is-pcs
                .left-box
                    margin: 0
                    .header
                        background-image: none
                        margin: 0
                        h1
                            padding: 65px 0 0 0
                        .wrapper
                            h2
                                width: 100%
                            .h2
                                margin: 0
                                ul
                                    li
                                        &:before
                                            content: ''
                                            display: inline-block
                                            height: .8em
                                            width: .8em
                                            margin: 0 10px 0 0
                                            border-radius: 50%
                                            border: 1px solid pictograms 
                                    
                        .header-to-button
                            .button
                                display: none
                                width: 0
                    .rightBox
                        display: flex
                        left: 99%
                        top: 0
                        height 100%
                        margin: 0
                        background-repeat: no-repeat
                        background-position: left
                        z-index: 0
                        @media (max-width: 1751px)
                            left: 80%
                        @media (max-width: 1230px)
                            display: none
                        @media (max-width: 616px)
                            width: 100%
.noMenu
    margin: -400px auto 0 auto !important
    position: relative !important
    min-height: 300px !important
    .container
        .wrapper-top
            .headers
                width: 100%
            .menu-intro
                display: none !important

@media (max-width: 1024px)
    .noMenu
        margin-top: -400px !important
@media (max-width: 768px)
    #top
        // width: 90%
        .container
            .wrapper-top
                padding: 30px 40px 0
                
        
