.content-404
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    padding: 20vh 0
    position: relative
    a.button-green
      background: #00EDC2 0% 0% no-repeat
      border: 2px solid #32EFCC
      border-radius: 100px
      opacity: 1
      padding:7px 32px 9px
      font-weight: 500
      font-size :16px
      line-height: 22px
      letter-spacing: 0px
      color: #FFFFFF!important
      text-decoration:none
      margin-bottom: 40px
    h2.header-404
      font-size: 36px;
      line-height: 48px;
      font-weight: 700;
      letter-spacing: 0;
      color: #411da9;
      @media(min-width: 768px)
        font-size: 60px;
        line-height: 80px;
    p.description
      font-family: "frank-new", "sans-serif" !important
      font-size: 16px
      line-height: 21px
      font-weight: 400
      color: #411DA9
      margin-bottom: 29px
    img.ships-404
      position: absolute;
      bottom: 50px
      left: 40px
      width: 80%
      @media(min-width: 768px)
        width: auto
    img.img-404
      width: 80%
      max-width: 450px
      @media(min-width: 768px)
          width: 600px
          max-width: unset
      @media(min-width: 1440px)
          width: auto