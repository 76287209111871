.contact-popup, .home-popup
    .pop-up
        position: fixed
        top: 0
        left: 0
        height: 100%
        width: 100%
        background-color: rgba( 17,0,71, 0.6 )
        z-index: 999
        .popup-wrapper
            display: flex
            flex-flow: column
            position: absolute
            border-radius: 15px
            height: 100%
            width: 100%
            padding: 10 0
            box-shadow: 3px 2px 0 4px rgba(17,0,71, .0)
            .exit-cross
                position absolute
                top: -140px
                right: -70px
                width: 30px
                height: 30px
                cursor: pointer
                z-index: 1000
                @media (max-width: 768px)
                    top: -150px
                    right: -170px
                @media (max-width: 500px)
                    top: 0px
                    right: 0px
                @media (max-width: 500px)
                    top: -50px
                    right: 0px
                &::before
                    position: absolute
                    right: 12px
                    top: 2px
                    content: ''
                    height: 30px
                    width: 1px
                    background-color: #fff
                    transform: rotate(45deg)
                &::after
                    position: absolute
                    right: 12px
                    top: 2px
                    content: ''
                    height: 30px
                    width: 1px
                    background-color: #fff
                    transform: rotate(-45deg)
    .shore-popup
        width: 100vw
        height: 70vh
        border: none
        bottom: 10%
        left: -20px
        background-color: rgba( 17,0,71, -1 )
        background-size: cover
        background-repeat: no-repeat
        overflow:hidden
        display: flex
        z-index: 1
        position: absolute
        @media(max-width: 980px)
            background-size: contain
            top: 100px
            left: 0
        @media(max-width: 616px)
            background-size: contain
            top: 200px
            left: 0
    
    .popup-content
        height: 100%
        width: 100%
        padding: 0
        display: flex
        flex-flow: column
        border: none 
        outline: none
        justify-content: center
        .wrapper-up-content
            display: flex
            flex-flow: column
            justify-content: flex-end
            align-items: flex-end
            padding: 100px 50px
            position relative
            background-color: headerTextBlue
            max-width: 50%
            left: 50%
            transform: translateX(-50%)
            max-height: 1500px
            border-top-right-radius: 20px
            @media (max-width: 1024px)
                max-width: 70%
                max-height: 100%
            @media (max-width: 500px)
                max-width: 100%
                height: 100vh
                align-items: center
                justify-content: flex-start
            &.home-wrapper
                z-index 9999999999
                .main-header
                    width 100%!important
            .gradeint-popup
                background-image radial-gradient(circle at 35% 35%, rgba(0,236,194, .3), rgba(17,6,74, .2), rgba(17,0,71, .1))
                position absolute
                width 100%
                height 100%
                min-height 60%
                border none
                top 0
                left 0
                -webkit-border-top-right-radius: 40px
                -moz-border-radius-topright: 40px
                border-top-right-radius: 40px
                overflow:hidden
                display: flex
                @media (max-width: 500px)
                    width: 100%
                    height: 70vh
                    top: -90px
                    border-top-right-radius: 0
                &.home-gradient
                    background-image radial-gradient(circle at 55% 50%,rgba(0,236,194,.2),rgba(17,6,74,.2),rgba(17,0,71,.1)) !important
            .main-header
                position relative
                display: flex
                justify-content: flex-end
                flex-flow: row
                align-items: flex-end
                z-index 100
                width: 60%
                height: 100%
                margin: 0
                border-top-right-radius: 20px
                z-index: 100
                // @media (max-width: 980px)
                //     margin: 20px auto 0 auto
                @media (max-width: 768px)
                    flex-flow: column
                @media (max-width: 500px)
                    width: 100%
                    height: 50%
                    padding: 0
                    margin: 0
                h1
                    margin: 0 43px 0 0px
                    color: #fff
                    font-size: 52.95px
                    font-weight: 100 !important
                    position: relative
                .notice-header
                    display: flex
                    flex-flow: column
                    width: 100%
                    padding: 20px 0 0 0
                    h2
                        color: #fff
                        margin: 0 
                        font-weight: 400 !important
                        display: flex 
                    .message
                        color: pictograms!important
                        width: 100%
                        font-size: 20px !important
                        font-weight: 100 !important
                        font-family: "frank-new", "sans-serif" !important
                        &.error
                            display: flex
                            flex-flow: column
                            padding: 0
                            color: pictograms!important
                            li
                                list-style-type none
                                padding: 0
                                color: pictograms
                                font-weight: 300 !important
                                font-size: 10px!important
                    .notice
                        margin: 0 43px 0 0px
                        color: #fff
                        font-size: 52.95px
                        font-weight: 100 !important
                        position: relative 
                    .home-popup-header
                        font-size: 40px!important;
                        letter-spacing: 0px!important;
                        color: #FFFFFF!important;
                        opacity: 1!important;
                        @media (max-width:768px){
                            font-size:30px!important
                        }
                    .home-popup-message
                        font-size: 17px!important
                        letter-spacing: 0px!important;
                        color: #FFFFFF!important;
                        @media (max-width:768px){
                            font-size: 14px!important
                        }
                    .green-text
                        font-size 17px!important;
                        letter-spacing: 0px!important;
                        color: #25E3BD!important;
                        @media (max-width:768px){
                            font-size: 14px!important
                        }
.home-popup
    .home-wrapper
        position relative!important
        min-height 50%!important
        padding 80px 60px!important
    .exit-cross
        top -60px!important
        right -25px!important
    .main-header
        -webkit-box-pack unset!important;
        -ms-flex-pack  unset!important
        justify-content  unset!important
        -webkit-box-orient unset!important
        -webkit-box-direction  unset!important
        -ms-flex-flow  unset!important
        flex-flow  unset!important
        -webkit-box-align  unset!important
        -ms-flex-align  unset!important
        align-items unset!important
    .additional-info
        padding-top 20px!important
    .home-popup-header
        padding-bottom 20px!important
    .notice-header
        padding 0!important

