.video-wrapper
    padding-top: 50px
    margin-bottom: 30px
    height: 650px
    @media (min-width: 1200px)
        height: 1184px
    @media (min-width: 992px)
        height: 984px
    @media (min-width: 768px)
        height: 708px;
    .video-box
        height: 640px
        width: 100%
        @media (min-width: 400px)
            height: 300px
        @media (min-width: 550px)
            height: 460px
        @media (min-width: 992px)
            height: 640px