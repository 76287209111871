.joinUs
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 100px
    margin-bottom: 100px
    max-width: 80%
    margin: 0 auto
    h3
        color: pcsGreen
        font-size: 36px
        font-weight: 900
        margin-bottom: 0px
    h4 
        color: pcsGreen
        font-size: 24px
        font-weight: 400
        margin-top: 5px
    p
        color: pcsTextDarkBlue
        font-size: 18px
        font-weight: 400
        line-height: 1.5em
        text-align: center
.trustedUs
    display: flex
    flex-direction: column
    align-items: center
    .titleWrapper2
        display: flex
        flex-direction: row
        align-items: baseline
        width: 100%
        justify-content: flex-start
        h1
            margin: 0 43px 0 0
            color: pcsTextLightBlue
            font-size: 52.95px
            padding: 0
            font-weight: 100
            align-items: flex-start
        h2
            font-size: 36px
            font-weight: 400
    p
        font-weight: 400
        word-spacing: 8px
        line-height: 1.5em
        color: pcsTextDarkBlue
        width: 80%
    .companyTilesWrapper
        margin-top: 60px
        ul
            list-style-type: none
            display: flex
            flex-direction: row
            flex-wrap: wrap
            padding: 0px
            justify-content: center
            overflow: hidden

            li
                border: solid 1px pcsGreen
                margin: 3px
                margin-bottom: 40px
                padding: 50px 0px
                width: 275px
                height: 215px
                border-bottom-left-radius: 20px
                border-bottom-right-radius: 20px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: space-around
                a
                    text-decoration: none
                    color: pcsGreen
                    font-size: 14px
                    font-weight: 400 


@media (max-width 1024px)
    #partnersWrapper
        margin-top: -450px 
        .partnersList
            .partnersIntro
                ul.aboutMenu
                    height 70px
                    position: relative
                    flex-direction: column
                    span 
                        position: absolute
                        right: 0px
                        top: 20px
                        visibility: visible
                    li
                        flex-direction: column
                        display: none
                        width: 300px
                        padding-right: 0px
                        &:nth-child(4)
                            background-position: 16%

