.choose-method
    padding 80px 100px
    h1
        color pcsGreen
        font-weight 400
    @media (max-width: 768px)
        padding 40px
    .choose-method-header-wrapper
        display: flex
        width: 100%
        gap 100px
        padding 0
        @media (max-width: 768px)
            flex-wrap wrap
            gap 10px
        .wrapper-item
            h2
                color pcsBlue
                font-size 14px
            .method-button
                margin-top 17px
                display flex
                justify-content center
                align-items center
                width 175px
                background-color pcsGreen
                color white
                border-radius 20px
                font-size 16px
                padding 5px 15px
                cursor pointer
                transition-duration 0.2s
                &:hover
                    pcs-shadow-menu()
    .choose-method-content-wrapper
        h2,p
            color pcsTextDarkBlue
            font-weight 400
        h2
            margin-bottom 40px
            font-size 40px
            line-height 48px
        .content-header
            margin-top 80px


