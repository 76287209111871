#singleNewsWrapper 
    font-family: frank-new, sans-serif
    font-weight: 500 !important
    background-color: transparent 
    position: relative
    width: 100%
    height: 100% 
    margin-top: -270px
    display: flex
    flex-direction: column
    align-items: center     
    .text-wrapper
        padding: 0px
        border: none
    .singleNews
        font-size: 16px
        width: 80%
        max-width: 1440px
        min-height: 500px
        background-color: white
        border-top-right-radius: 20px
        overflow: hidden
        padding: 55px 100px
        .go-back-news
            display: flex
            flex-flow: row
            height: 30px
            // padding: 0px 25px
            margin: 30px 10px 60px 0px
            // width: 10px;
            height: 15px
            .go-back-btn
                background: transparent
                border: none
                cursor: pointer
                padding: 0
                display: flex
                flex-flow: row
                height: 10px
                .box-for-arrow
                    margin: -3px 10px 0 0
                    width: 10px
                    height: 30px
                    img
                        height: 10px
                a
                    color: pcsBlue
                    font-weight: 500 !important
                    text-decoration: none
                    font-size: 14px

        .textes
            margin: 0px
            width: 100%
        h1
            font-size: 16px
            margin: 60px auto
        p
            font-weight: 400 !important
            word-spacing: 8px
            line-height: 1.5em
        .singleNewsImage
            border: 1px solid red
            background-image: url(../img/singleNewsPicture.png)
            background-size: contain;
            background-repeat: no-repeat;
            height: 505px
            width: 100%
            margin-top: 12%
            margin-bottom: 10%
        .newsShare
            ul
                list-style-type: none
                font-weight: 400 !important
                display: flex 
                flex-direction: row
                justify-content: center
                align-items: baseline
                padding-left: 0px
                li
                    margin: 70px 5px 20px 11px
                    box-sizing: border-box
                    &:nth-child(n+2)
                        padding: 3px
                        margin: 0 5px 
                        border: 1px solid pcsGreen
                        border-radius: 50px
                        width: 30px
                        height: 30px
                        text-align: center
                        justify-content: center
                        color: pcsBlue
                        cursor: pointer
                    &:nth-child(n+2):hover
                            box-shadow: 0px 0px 0px 3px pcsGreen



.small
    header
        .header-bg-img
            .title
                // miarki = 40px
                // szerokosc  = "calc(80% + %s)" % miarki
                // width: szerokosc

                // max-width: 1440px
                // padding: 0px 100px
                // margin: 15% auto
                h1
                    font-size: 36px
                h2
                    font-size: 16px
                    color: pcsGreen 
                    margin-top: 10px



// @media (min-width: 2300px)
//     .small
//         header
//             .header-bg-img
//                 .title 
//                     margin-top: 12%;

// @media (min-width: 1900px)
//     .small
//         header
//             .header-bg-img
//                 .title 
//                     margin-top: 12%
// @media (max-width: 1500px)
//     .small
//         header
//             .header-bg-img
//                 .title 
//                     margin-top: 20%;

@media (max-width: 1440px)
    #singleNewsWrapper
        .singleNews
            .singleNewsImage
                height: 35vw 

@media (max-width: 1024px)
    // .small
    //     header
    //         .header-bg-img
    //             .title 
    //                 margin-top: 9%
    #singleNewsWrapper
        margin-top: -450px
@media (max-width: 769px)
    #singleNewsWrapper
        .singleNews
            width: 90%
            padding: 40px 40px
@media (max-width: 616px)
    #singleNewsWrapper
        .singleNews
            width: 100%
            padding: 40px
            border-top-left-radius: 20px 
@media (max-width: 640px)
    .small
        header
            .header-bg-img
                .title
                    // margin-top: 20%
                    // padding: 0px 1.5em
                    h1
                        font-size: 24px
    #singleNewsWrapper
        .singleNews
            padding: 2em 1.5em
    

