.ship-movement-container
  width: 100%
  margin: 0px auto
  padding: 0px 20px
  background: none
  margin: -88vh auto 0;
  margin-top: calc(-100vh + 111px);
  z-index: 1;
  position: relative
  overflow:hidden
  //@media (max-width: 1500px)
  //  margin: -86vh auto 0
  //@media (max-width: 1300px)
  //  margin: -84vh auto 0
  //@media (min-width: 2500px)
  //  margin: -91vh auto 0
  //&.smaller
  //  @media (max-width: 768px)
  //    width:90%
  //&::after
  //  content:''
  //  position:absolute
  //  height:9px
  //  width:100%
  //  background-color:black
  //  opacity:0.1
  //  top:0
  //  margin:0 auto
  //  z-index: 9999999;
  //  overflow:hidden

  ::-webkit-scrollbar-track
    border-radius: 10px
    background-color: #F5F5F5
    background-color: #Fff

  ::-webkit-scrollbar
    width: 8px
    background-color: #F5F5F5
    background-color: #Fff
    border-radius: 10px


  ::-webkit-scrollbar-thumb
    border-radius: 10px;
    background-color: #E2E2E2
    background-color: #Fff


  .sidebar-ship-movement-wrapper
    padding:30px 40px
    position:absolute
    background: #ffffff
    width:500px
    height:100%
    z-index:50
    display:flex
    justify-content flex-end
    transform:translateX(0px)
    transition:all 0.5s
    &.button-click
      transform:translateX(-480px)
      transition:all 0.5s

    .select2-selection__placeholder
      color:#411da9!important;

    .sidebar-button-wrapper
      position: absolute
      top: 32px
      right: -20px
      width:40px
      height 40px
      border-radius:100px
      background:#fff

    .sidebar-button
      width:27px
      height: 27px
      border-radius:100px
      background:none
      border:0
      top: 0
      left: 6px
      bottom: 0
      right: 0
      position: relative
      cursor:pointer
      outline:0
      box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
      transition:all 0.5s
      &::after
        position:absolute
        content:''
        background-image:url('../img/ruch-statkow/arrow-button.svg')
        background-repeat:no-repeat
        background-size:contain
        top: 0
        right: 0
        left: 0
        bottom: 0
        margin: auto
        width: 14px
        height: 12px
        transform:rotate(0deg)
        transition:all 0.5s
      &.rotate-icon
        transition:all 0.5s
        &::after
          transform:rotate(180deg)
          transition:all 0.5s


    .sidebar-content-wrapper
      border:2px solid #F5F5F5
      border-radius:22px
      width:100%
      padding:50px 30px
      position:relative
      overflow:hidden
      @media (max-width: 1450px)
        padding:30px
      //@media (max-height: 930px)
      //  overflow-y:scroll

      .content-after-port-choose
        padding: 0 20px
        display:block
        height:100%
        &.hidden
          display:none
        .overflow-content
          height:100%
          overflow-y:auto

    .ship-info-wrapper
      background-color:white
      position:absolute
      width:100%
      border-radius:22px
      height:100%
      top: 0;
      z-index: 10;
      right: 0;
      overflow-x:hidden

      .ship-info-image
        width:100%
        height:260px
        border-top-left-radius:22px
        border-top-right-radius:22px


      .ship-info-text-wrapper
        padding:30px

        .ship-info-close
          font-size:12px
          line-height:16px
          color:#00EDC2
          cursor:pointer
          font-weight bold
          position:relative
          margin-left:16px
          &::before
            position:absolute
            content:''
            background-image:url('../img/ruch-statkow/arrow-button-close.svg')
            background-repeat:no-repeat
            background-size:contain
            top: 0
            left: -16px
            bottom: 0
            margin: auto
            width: 10px
            height: 10px


        .ship-info-name
          font-size:32px
          line-height:43px
          color:#411DA9

        .ship-info-type-wrapper
          display:flex
          align-items:center

          svg
            width:10px
            height:10px

          p
            font-size:12px
            line-height: 16px
            color:#411DA9
            margin-left:8px
            margin-bottom:0




    select.ship-movement
      border:2px solid #411DA9!important
      border-radius:100px

    .sidebar-label
      font-size:32px
      line-height:43px
      color:#411DA9
      font-weight:400
      margin-bottom:16px

    .ship-legend-wrapper
      height: unset
      //overflow-y:scroll
      //display:block
      visibility: visible
      @media (max-width: 1450px)
        height: unset
      &.hidden
        //display:none
        visibility: hidden
        height:0
      &.visible
        //visibility: visible
        //height: 150px
        //@media (max-width: 1450px)
        //  height: 123px
      &.more-ship
        height: 304px;
        overflow-y: scroll;
        visibility: visible;
        position: absolute;
        background: white;
        width: 100%;
        z-index: 5;
      > div
        height: 100%
      .legend-item-port
        display: flex
        flex-direction: row
        align-items center
        justify-content:space-between
        font-size: 14px
        width: 100%

        &.hidden
          display:none


        @media (max-width: 440px)
          font-size: 12px

        .btn-add
          width: 8px
          height: 8px
          transform:rotate(133deg)
          margin-right:16px
          cursor:pointer

        .text-wrapper
          label
            margin-bottom:0
            font-size:14px
            line-height:19px
            font-weight:400
            color:#411DA9
            cursor:pointer
          .svg
            margin-right: 10px
            width: 27px
            height: 11px




    .selected-categories-wrapper
      height:auto
      &.margin-top
        padding-top: 16px
      &.line
        position:relative
        margin-bottom:24px
        &::after
          content:''
          position:absolute
          width:100%
          height:1px
          background-color:#eeeeee
          bottom:0
          margin: -10px auto;

      .legend-item-port
        display: flex
        flex-direction: row
        align-items center
        font-size: 14px
        width: 100%
        justify-content:space-between

        @media (max-width: 440px)
          font-size: 12px

        .text-wrapper
          label
            margin-bottom:0
            font-size:14px
            line-height:19px

            font-weight:400
            color:#411DA9

          .svg
            margin-right: 10px
            width: 27px
            height: 11px

        .btn-delete-from-filtered
          width: 8px
          height: 8px
          cursor:pointer


    .ship-movement-text
      font-size:14px
      line-height:19px
      font-weight:400
      margin-bottom:0
      display:block
      &.blue
        color:#411DA9
      &.green
        color:#00EDC2
      &.ships-in-port
        margin-bottom:8px
      &.clean
        cursor:pointer
      &.hidden
        display:none
      &.filter
        position:relative
        cursor:pointer
        &::after
          content:''
          position:absolute
          background-color:pcsGreen
          top: 0
          right: -16px
          bottom: 0
          margin: auto
          width: 8px
          height: 2px
          transform:rotate(0)
          cursor:pointer
        &::before
          content:''
          position:absolute
          background-color:pcsGreen
          top: 0
          right: -16px
          bottom: 0
          margin: auto
          width: 8px
          height: 2px
          transform:rotate(90deg)
          cursor:pointer
      &.filter-close
        position:relative
        cursor:pointer
        &::after
          content:''
          position:absolute
          background-color:pcsGreen
          top: 0
          right: -16px
          bottom: 0
          margin: auto
          width: 8px
          height: 2px
          transform:rotate(0)
          cursor:pointer



    .ship-text-wrapper
      display:flex
      justify-content:space-between
      align-items:center
      &.hidden
        display:none


    .line-ship-movement
      height 1px
      width:100%
      background-color:#E0E0E0
      margin:24px 0
      &.first-line
        margin: 4px 0 24px

    .active-ships-in-port
      p
        font-size: 18px
        line-height: 19px
        color:#411DA9
        font-weight:500


    .ship-list-svg
      width:10px 
      height:10px

    .ship-port-visbility
      height: 100%
      display:block
      visibility: visible
      &.hidden
        display:none
        visibility: hidden

    .ship-names-list
      font-size:12px
      line-height:18px
      color:#411DA9
      height: 100%
      //overflow-y: auto
      font-weight:400
      //@media (min-width: 2500px)
      //  height: 662px
      //@media (max-width: 1450px)
      //  height: 190px
      //@media (max-width: 1300px)
      //  height: 147px


      p
        margin-bottom:4px

    .ship-legend-port
      display: flex
      flex-direction: column
      width: 100%
      position: relative
      flex-wrap: wrap
      &.for-ship-port-list
        //&::after
        //  content:''
        //  position:absolute
        //  background-repeat:no-repeat
        //  background-size:contain
        //  margin: auto
        //  width: 8px
        //  height: 8px
        //  right: 11px;
        //  top: 4px;
        //  cursor:pointer
        //  background-image:url('../img/ruch-statkow/button-delete-from-filtered.svg')
        //  transform:rotate(133deg)
        //  pointer-events: none

      .legend-item-port
        display: flex
        flex-direction: row
        align-items center
        font-size: 14px
        width: 100%

        @media (max-width: 440px)
          font-size: 12px

        label
          margin-bottom:0
          font-size:14px
          line-height:19px
          font-weight:400
          color:#411DA9
        .svg
          margin-right: 10px
          width: 27px
          height: 11px

    .weather-module
        display:flex
        justify-content:space-between
        align-items:center
        //padding:0 16px
        margin-top:8px
        p.loading-weather
          font-size:18px
          line-height: 19px
          color:#411DA9
          margin-bottom:0
        .text-elements
          display:flex
          .temperature
            font-size:43px
            color:#411DA9
            position:relative
            margin-bottom:0
            &::before
              content: ''
              position: absolute
              top: 15px
              right: -8px
              margin: auto
              width: 10px
              height: 10px
              border-radius: 100px
              border: 2px solid #411da9

          .city-wrapper
            display:flex
            flex-direction:column
            align-items:flex-start
            justify-content:center
            padding-left:16px
            padding-right: 24px
            .city
              font-size:18px
              line-height: 19px
              color:#411DA9
              margin-bottom:0
            .wind-wrapper
              display:flex
              .wind
                font-size: 11px
                line-height: 13px
                margin-left: 8px
                color:#411DA9
                position:relative
                margin-top:3px
                margin-bottom:0
              .wind-icon
                background-image:url('../img/ruch-statkow/send.svg')
                background-repeat:no-repeat
                background-size:contain

                //top: 0
                //left: -16px
                //bottom: 0
                margin: auto
                width: 10px
                height: 10px


        .icon-wrapper
          position:relative
          &::before
            content:''
            position:absolute
            left: -16px
            bottom: -8px
            margin: auto
            width: 1px
            height: 63px
            background-color:#E0E0E0
          .weather-icon
            width: 56px
            height: 56px
            border-radius: 12px
            position:relative

