.general-wrapper
    width: 100% 
    position: relative
    min-height: 900px
    max-height: 5000px
    overflow: hidden
    @media(max-width: 616px)
        min-height: 1000px
    @media(max-width: 482px)
        min-height: 1250px
    @media(max-width: 374px)
        min-height: 1250px
    @media(max-width: 321px)
        min-height: 1350px
    .shore
        position: absolute
        width: 100%
        height: 100%
        border: none
        top: 0px
        left: 0px
        z-index: 1
        background-size: cover
        background-repeat: no-repeat
        overflow:hidden
        display: flex
        @media (max-width: 1240px)
            width: 100%
    .navy
        background-color: headerTextBlue
        position: absolute
        height: 100%
        width: 90%
        max-height: 1700px
        top: 0px
        left: 16px
        -webkit-border-top-right-radius: 40px
        -moz-border-radius-topright: 40px
        border-top-right-radius: 40px
        overflow:hidden
        display: flex
        @media (max-width: 1240px) 
            width: 100%
            margin: 0
            border-radius: 0
            left: 0
    .gradeint
        position: absolute
        width: 99%
        margin: 0 auto
        height: 100%
        border: none
        top: 0px
        left: 16px
        max-height:1700px
        -webkit-border-top-right-radius: 40px
        -moz-border-radius-topright: 40px
        border-top-right-radius: 40px
        overflow:hidden
        display: flex
        padding: 70px 0 0 0
        max-height: 3000px
        @media (max-width: 1240px)
            width: 100%
            left: 0
        .gradeint1
            background-image: radial-gradient(circle at 35% 35%, rgba(0,236,194, .3), rgba(17,6,74, .2), rgba(17,0,71, .1))
            position: absolute
            width: 80%
            height: 100%
            border: none
            top: 0px
            left: 0
            max-height:1700px
            -webkit-border-top-right-radius: 40px
            -moz-border-radius-topright: 40px
            border-top-right-radius: 40px
            overflow:hidden
            display: flex
            @media (max-width: 1240px)
                width: 100%
                left: 0
        .current-wrapper
            width: 80%
            min-height: 100%
            margin: 0 auto
            padding: 0
            .container
                width: 100%
                height: 100%
                max-height: 1700px
                display: flex
                flex-flow: column
                padding: 0
                .news
                    display: flex
                    justify-content: flex-start
                    flex-flow: row 
                    align-items: flex-start
                    width: 100%
                    height: 100%
                    max-height: 1700px
                    z-index: 100
                    @media (max-width: 1240px)
                        width: 100%
                    @media (max-width: 768px)
                        flex-flow: column
                        min-height: 100%
                        padding: 0
                    h1
                        margin: 0 43px 0 0
                        color: #fff
                        font-size: 52.95px
                        padding: 0
                        font-weight: 100 !important
                        align-items: flex-start
                        min-height: 70px
                        max-height: 70px
                    .wrapper
                        display: flex
                        flex-flow: column
                        width: 100%
                        min-height: 700px 
                        max-height: 5000px
                        padding: 1px
                        position: relative
                        h2 
                            color: #fff
                            padding: 0px 0 0 0
                            margin: 0 
                            font-weight: 400 !important
                            display: flex 
                            transform: translateY(15%);
                            @media (max-width: 768px)
                                padding: 0 0 0 3px
                            p
                                padding: 0
                                margin: 0
                                display: flex
                            *
                                font-size: 36px
                                line-height: 46px
                                font-weight: 400 !important


                        .posts
                            display: flex
                            flex-flow: column
                            margin: 103px 0 0 0
                            min-height: 100%
                            @media (max-width: 780px)
                                width: 100%
                                margin: 50px 0 0 0
                            &:hover
                                color: pictograms
                            .post
                                display: flex
                                flex-flow: row
                                width: 100%
                                margin: 0 0 100px 0
                                &:hover
                                    color: pictograms
                                .one-news
                                    display: flex
                                    font-size: 15px
                                    font-weight: 400 !important
                                    color: #fff
                                    align-items: flex-start
                                    position: relative
                                    z-index: 2
                                    &:hover
                                        .text
                                            a
                                                color: pictograms
                                        
                                    .data
                                        display: flex
                                        flex-flow: row
                                        min-width: 100px
                                        max-width: 150px
                                        font-size: 15px
                                        font-weight: 400 !important
                                        color: #fff
                                        padding: 5px 5px 0 0
                                        background-color: transparent
                                        position relative
                                        @media (max-width: 780px)
                                            margin: 0 0 0 10px
                                        &::after
                                            content: ""
                                            position absolute
                                            top: calc( 50% + 15px )
                                            right: 0
                                            background-color: headerNumbersBlue
                                            height: 1px
                                            width: 90vw
                                            @media(max-width: 780px)
                                                display: none

                                        @media (max-width: 780px)                                    
                                            width: 100%
                                            padding: 0 5px 0 0
                                            border-bottom: 1px solid pictograms
                                            p
                                                display: flex
                                                flex-flow: row
                                                min-width: 200px;
                                                max-width: 200px;
                                                position relative
                                        .lines
                                            border: 1px solid #fff
                                            display: flex
                                            width: 100%
                                            height: 100%

                                    .text
                                        display: flex
                                        flex-flow: row
                                        font-size: 16px
                                        width: 100%
                                        padding: 0 0 0 10px
                                        font-weight: 300 !important
                                        line-height: 2em
                                        color: #fff

                                        p
                                            padding: 0 30px 0 0
                                            margin: 0
                                            width: 100%
                                            
                                            a
                                                color: pictograms
                                                text-decoration: none
                                                &:active,
                                                &:visited,
                                                &:focus
                                                    color: pictograms
                                                    text-decoration: none

                                            @media (max-width: 780px)
                                                margin: 10px 0 0 0

                                    @media (max-width: 780px)
                                        flex-flow: column
                                        width: 100%

                    .button
                        padding: 0
                        width: 100%
                        margin: 50px auto 0 auto
                        display: flex
                        justify-content: flex-start
                        align-items: center
                        position: absolute
                        bottom: 0
                        @media (max-width: 780px)
                            justify-content: flex-start
                            margin: 0
                            width: 100%
                            align-items: flex-end
                        .see-more
                            display: flex
                            align-items: center
                            justify-content: center
                            width: 242px
                            height: 42px
                            border: 1px solid rgba(0,237,194,1)
                            border-radius: 30px
                            background-color: rgba(32,24,85,0.5)
                            font-size: 14px
                            color: #fff
                            font-weight: 400 !important
                            padding: 0
                            margin: 0 0 0 121px
                            @media (max-width: 780px)
                                margin: 0 0 0 11px
                            &:hover
                                -webkit-box-shadow: 0px 0px 10px 1px rgba(0,237,194,1);
                                -moz-box-shadow: 0px 0px 10px 1px rgba(0,237,194,1);
                                box-shadow: 0px 0px 10px 1px rgba(0,237,194,1);
                            a
                                text-decoration: none
                                width: 242px
                                color: #fff
                                text-align: center


                                
        
