.ships-movement-wrapper
    width: 100%
    .container
        // @media(max-width:575px)
        //     padding: 85px 57px 35px !important
        @media(max-width:768px)
            padding: 30px 40px 35px !important
    .text
        @media (max-width: 685px)
            padding: 0 !important
    .box-button
        align-self: flex-end
        @media(max-width: 1012px)
                margin-top: 25px

.content-title
    margin-top: 20px
    @media (max-width: 425px)
        font-size: 18px

    span
        font-weight: bold

.ship-legend
    display: flex
    flex-direction: column
    width: 100%
    position: relative
    margin-bottom: 50px
    flex-wrap: wrap

    .legend-item
        display: flex
        flex-direction: row
        align-items center
        font-size: 12px
        line-height:16px
        width: 100%

        label
            margin-bottom:0
            font-size:14px
            line-height:19px
            font-weight:400
            color:#411DA9
        .svg
            margin-right: 10px
            width: 27px
            height: 11px

            path
                fill: inherit


.ship-movement-info
    margin-top: 10px !important

