#contact
    margin: -400px auto 0px auto
    background-color: #fff
    min-height: 400px
    max-width: 1440px
    position: relative
    z-index: 0 
    border-top-right-radius: 20px
    width: 80%
    @media (max-width: 1024px)
        margin: -400px auto 10px auto
        min-height: 450px 
        max-height: 6000px
    @media (max-width: 768px)
        border-top-left-radius: 20px
    @media (max-width: 616px)
        width: 100%
    .container
        display: flex
        background-color: #fff
        min-width: 100%
        z-index: 100
        position: relative
        border-top-right-radius: 20px
        padding: 0
        margin: 0
        @media (max-width: 1024px)
            width: 80%
        @media (max-width: 768px)
            border-top-left-radius: 20px
            padding: 0
            margin: 0

        .infos
            width: 100%
            display: flex
            padding: 70px 100px 0 100px
            justify-content: space-between
            z-index: 100
            grid-gap: 20px
            @media (max-width: 1024px)
                padding: 100px 100px 0 100px
            @media (max-width: 922px)
                flex-flow: column
                padding: 70px 100px 0 100px
                text-align: center
                justify-content: center
            @media (max-width: 768px)
                padding: 30px 40px 0 40px
            .header-boxes
                h1
                    font-size: 15.78px
                    color: pictograms
                    font-weight: 700 !important
                    border-bottom: 1px solid headerNumbersBlue
                    @media (max-width: 1024px)
                        margin: 10px 0 0 0
                        text-align: left
                        margin: 0
                .text-box
                    font-size: 16px
                    color: headerTextBlue
                    font-weight: 400 !important
                    padding: 15px 0 0 0
                    @media (max-width: 1024px)
                        flex-flow: column
                        text-align: center
                        justify-content: center
                        width: 100%
                        text-align: left
                        margin: 0
                    a
                        text-decoration: none
                        font-weight: 700 !important
                        color: headerNumbersBlue
            .header-boxes2
                h1
                    font-size: 15.78px
                    color: pictograms
                    font-weight: 700 !important
                    border-bottom: 1px solid headerNumbersBlue                    
                    @media (max-width: 922px)
                        text-align: left
                a
                    color: headerNumbersBlue
                .text-box
                    font-size: 16px
                    color: headerTextBlue
                    font-weight: 400 !important
                    padding: 0px 0 0 0
                    display: flex
                    flex-flow: row nowrap
                    @media (max-width: 1024px)
                        flex-flow: column
                        text-align: center
                        justify-content: center
                        text-align: left
                        margin: 0

                    a
                        text-decoration: none
                        font-weight: 700 !important
                    .arrow
                        margin: -3px 0 0 12px
                        padding: 0
                        width: 10px
                        height: 30px
                        transform: rotate(180deg)
                        @media (max-width: 1024px)
                            display: none
                        img
                            height: 10px
            .header-boxes3
                @media (max-width:  1024px)
                    justify-content: center
                    text-align: center
                @media (max-width: 768px)
                    text-align: left
                    margin: 0
                .wrapper
                    display: flex
                    flex-flow: row-reverse
                    width: 100%
                    @media (max-width: 1024px)
                        flex-flow: column
                        text-align: center
                        justify-content: center
                    .foto-wrapper
                        border-radius: 50%
                        height: 162px
                        width: 162px
                        position: relative
                        margin: 0 30px 0 0
                        opacity: 0
                        @media (max-width: 1280px)
                            display: none
                        .patron-picture
                            width: 100%
                            height: 100%
                            background-size: cover
                            border-radius: 50%
                            img 
                                width: 100%
                                height: 100%
                        .patron-picture-circle
                            width: 102%
                            height: 102%
                            border-radius: 50%
                            background-size: contain
                            background-repeat: no-repeat
                            border: 1px solid #fff 
                            padding: 0
                            margin:0
                            position: absolute
                            top: -1px;
                            left: -2px;
                            background-position-x: 10px
                    .patron-data
                        padding: 0
                        margin: 0
                        @media (max-width:  1024px)
                            justify-content: center
                            text-align: center
                            text-align: left
                            margin: 0
                        h1
                            font-size: 15.78px
                            color: pictograms
                            font-weight: 700 !important
                            border-bottom: 1px solid headerNumbersBlue
                        .text-box
                            font-size: 16px
                            color: headerTextBlue
                            font-weight: 400 !important
                            padding: 15px 0 0 0
                            display: flex
                            flex-flow: row nowrap
                            @media (max-width: 1024px)
                                flex-flow: column
                                text-align: center
                                justify-content: center
                                text-align: left
                                margin: 0
                            a
                                text-decoration: none
                                font-weight: 700 !important
        