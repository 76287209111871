.general-mockup-wrapper
    width: 80%
    margin: -200px auto 0 auto
    max-width: 1440px
    @media (max-width: 1230px)
        margin: 0 auto
    .container
        padding: 0
        width: 100%
        margin: 0 auto
        @media (max-width: 1230px)
            width: 100%
            margin: 0
            padding: 0
        .what-is-pcs
            display: flex
            flex-flow: column
            justify-content: center
            align-items: flex-start
            width: 100%
            padding: 0
            max-height: 3000px
            .left-box
                display: flex
                flex-flow: column
                justify-content: center
                align-content: flex-start
                width: 54%
                position: relative
                margin: 0 0 20px 0
                min-height: 1000px
                z-index: 100
                @media (max-width: 4000px)
                    min-height: 1400px
                @media (max-width: 3000px)
                    min-height: 900px
                @media (max-width: 1230px)
                    min-height: 400px
                    width: 100%
                @media (max-width: 768px)
                    margin: 0
                .rightBox
                    position: absolute
                    left: 75%
                    top: 0
                    width: 54vw
                    height 100%
                    margin: 0
                    background-repeat: no-repeat
                    background-position: left
                    z-index: 0
                    @media (max-width: 1230px)
                        display: none
                    @media (max-width: 768px)
                        width: 100%
                .header
                    display: flex
                    justify-content: flex-start
                    flex-flow: row
                    align-items: flex-start
                    z-index 100
                    width: 100%
                    height: auto
                    margin: 0 auto
                    padding: 0
                    background-image: url('../../dist/img/polokrag_zielony.svg')
                    background-repeat: no-repeat
                    background-position: left
                    background-size: contain
                    position: relative
                    @media (max-width: 768px)
                        background-image: none
                        flex-flow: column
                        width: 100%
                        margin: 0
                        padding: 0
                    h1
                        margin: 0 43px 0 0px
                        color: headerNumbersBlue
                        font-size: 52.95px
                        padding: 110px 0 0 0
                        font-weight: 100 !important
                        position: relative
                        @media (max-width: 1230px)
                            padding: 65px 0 0 0
                        @media (max-width: 768px)
                            margin: 0
                            padding: 0
                    .wrapper
                        width: 90%
                        display: flex
                        flex-flow: column
                        margin: 0 0 0px 0
                        @media (max-width: 1230px)
                            width: 100%
                        h2 
                            color: headerTextBlue
                            font-weight: 400 !important
                            font-size: 36px
                            padding: 80px 0 0 0
                            margin: 0  
                            height 100%  
                            width: 40%
                            line-height: 46.02px
                            transform: translateY(7%);
                            @media (max-width: 1332px)
                                width: 50%
                            @media (max-width: 1230px)
                                width: 100%
                            @media (max-width: 768px)
                                margin: 0
                                padding: 0
                                width: 100%
                        .h2
                            font-size: 16px
                            font-weight: 400 !important
                            width: 80%
                            z-index: 100
                            line-height: 2em
                            margin: 30px 0 80px 0
                            color: headerTextBlue
                            padding: 22px 0 40px 0
                            hyphens: auto
                            position: relative
                            @media (max-width: 1230px)
                                width: 100%
                            @media (max-width: 1240)
                                margin-bottom: 20px
                            @media (max-width: 768px)
                                margin: 0
                .header-to-button
                    position: absolute
                    top: 0
                    left: 0
                    height: 90%
                    max-width: 530px
                    min-width: 530px
                    display: flex
                    justify-content: flex-end
                    align-items: flex-end
                    z-index: 100
                    font-weight: 500 !important
                    top: 0
                    left: 0
                    @media (max-width: 1230px)
                        height: 90%
                        max-width: 450px
                        min-width: 458px
                        position: absolute
                    @media (max-width: 757px)
                        max-width: 400px
                        height: 100%
                        justify-content: center
                        position: absolute
                    @media (max-width: 768px)
                        min-width: 100%
                        height: 100%
                        justify-content: center
                        position: absolute
                    .button
                        margin: 100px 0 0 0
                        padding: 0
                        display: flex
                        justify-content: center
                        z-index: 5
                        transition: all 0.3s linear
                        a
                            text-decoration: none
                            margin: 0
                            display: flex
                            align-items: center
                            justify-content: center
                            width: 184px
                            height: 42px
                            border: 1px solid pictograms
                            padding: 0
                            border-radius: 50px
                            background-color: white
                            font-size: 14px
                            color: headerTextBlue
                            font-weight: 400 !important
                            margin: 0  
                            &:hover
                                background-color: whiteButtonHover   
.rightBox2
    width: 100%
    min-height: 100px
    margin: 0 auto
    z-index: 100
    display: none
    @media (max-width: 1230px)
        display: flex
        width: 100%
        height: 100%
        margin: 50px auto 0 auto
        img 
            width: 100%
            height: 100%