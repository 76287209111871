.select2-results__options
    display: flex
    width: 100px
    position: absolute
    left: -50px
    top: 14px
.select2-search--dropdown
    display: none !important
// to jest baner z karuzelą do strony startowej



header
    .header-bg-img
        .sticky
            width: 100%
            position: fixed
            left: 0
            top: 0
            z-index: 100
            background-color: #FFF
            box-shadow: 0 5px 13px -7px rgba(0,0,0,.5)
            padding-top: 10px
            align-items: center
            z-index: 999
            transition: background-color 0.2s ease

        nav
            color: white
            margin: auto
            padding-top: 60px
            display: flex
            flex-direction: row
            align-items: flex-start
            justify-content: space-around
            z-index: 100
            position: relative
            &.shadow
                box-shadow: 0 9px 0 0 rgba(0,0,0,0.1)
                background-color:white
            &.padding
                padding: 50px 0 0;
            .rwd-nav-stripe
                position: absolute
                display: none
            a
                z-index: 999
                .logo-sticky
                    display: none
                .logo
                    height: 85px
                    visibility: visible !important
                    z-index: 1
                    left -60px
                    position: relative
                    top: -24px
                    @media (max-width: 1024px)
                        top: -3px
                        height: 50px!important
                    &.new-styles
                        height: 80px
                .logo-sticky-mobile
                    max-width: 40px
                    max-height: 40px
                    width: 100%
                    height: 100%
                    margin-top: -15px
            .menu-wrapper
                display: flex
                justify-content: space-between
                flex-direction: row
                ul
                    color: white
                    font-size: 16px
                    font-family: frank-new, sans-serif
                    font-weight: 300 !important
                    font-style: light
                    list-style: none
                    display: flex
                    flex-direction: row
                    align-self: center
                    justify-content: space-between
                    align-items: center
                    padding-left: 0
                    padding-bottom: 15px
                    margin-bottom: 0
                    position: relative
                    border-bottom: 1px solid transparent
                    li
                        display: flex
                        justify-content: space-around
                        margin: auto 16px
                        &:first-child
                            margin-left: 0
                        &:last-child
                            margin-right: 0
                        &:hover
                            cursor: pointer
                            color: pcsGreen
                        &.active
                            color: pcsGreen
                        // &:focus > .dropdown
                        //     visibility: visible
                        //     opacity: 1
                        a
                            text-decoration: none
                            color: white
                            position: relative
                            &:hover,
                            &.active
                                color: pcsGreen !important


                        .white-menu
                            position: absolute
                            width: 200vw
                            height: 0
                            background: white
                            top: 45px
                            box-shadow: 0 5px 13px -7px rgba(0,0,0,.5)
                            display: inline-block
                            transition: height 0.2s ease
                            @media (max-width: 1240px)
                                top: 49px
                                height: 0
                        .dropdown
                            font-size: 14px
                            visibility: hidden
                            opacity: 0
                            position: absolute
                            left: -25px
                            top: 40px
                            overflow: hidden
                            display: none
                            display: flex
                            flex-direction: row
                            border: none
                            min-width: 5rem
                            padding: 14px 110px 50px 25px
                            transition: all 0.5s ease
                            &:after
                                content: ''
                                width: 75%
                                height: 0
                                position: absolute
                                right: 9%
                                top: 0
                                box-shadow: 0 0 100px 4px pcsGreen
                                border-radius: 50%
                            li
                                width: fit-content !important;
                                white-space: nowrap;
                                &:before
                                    content: ''
                                    background: transparent
                                    width: 0
                                a
                                    position: relative
                            li:first-child
                                @media(max-width: 1024px)
                                    width: 100%
                .panel-menu
                    margin-left: 40px
                    border: none
                    align-items: center
                    font-size: 14px
                    font-family: frank-new, sans-serif
                    font-weight: 300 !important
                    font-style: light
                    li
                        transition: all 0.3s linear
                        &:hover:before
                            background: transparent
                            border: none
                        font-size: 14px
                        &:first-child
                            margin-right: 0
                            font-size 16px
                            background-color:pcsGreen
                            padding: 5px 15px
                            a
                                color:white!important
                                font-weight: 500 !important
                            pcs-border-menu()
                        &:first-child:hover
                            pcs-shadow-menu()
                        &:nth-child(2)
                            margin-right: 0
                            background-color:white
                            padding: 5px 23px
                            a
                                font-weight: 400 !important
                                color:pcsBlue
                            pcs-border-menu()
                        &:nth-child(2):hover
                            pcs-shadow-menu()
                        &:nth-child(3)
                            margin-right: 3px
                            height: 33px
                            width: 33px
                            pcs-border-menu()
                        &:nth-child(3):hover
                            pcs-shadow-menu()
                        &:nth-child(4)
                            margin-left: 3px
                            // padding: 5px 9px
                            height: 33px
                            width: 33px
                            pcs-border-menu()
                        &:nth-child(4):hover
                            pcs-shadow-menu()
                        &:nth-child(5)
                            visibility: hidden
                            display:none
                            border-bottom: 1px solid transparent
                            // ----SELECT 2---- //
                            .select2-search__field
                                display: none
                            .select2-container
                                display: inline
                                background: transparent
                            .select2-container .select2-selection
                                border: none
                                height: 40px
                                background: rgba(0, 0, 0, 0)
                                background: url(../img/arrow-down.png) !important
                                background-repeat: no-repeat !important
                                background-position: 16px !important
                            .select2-container .select2-selection span:first-child
                                color: white
                                line-height: 40px
                            .select2-container .selection
                                display: inline-block
                            .select2-container .selection span .select2-selection__arrow
                                display: none
                            .select2-container--open .select2-dropdown .select2-dropdown--below
                                background: rgba(0,0,0,0)
                                .select2-results ul li
                                    color: white
                                    display: flex !important
                                .select2-dropdown
                                    display: inline
                                    width: auto
                                    background: rgba(0, 0, 0, 0)
                                    border: none
                                .select2-search input:first-child
                                    display: none
                            span.select2-selection.select2-selection--single:focus
                                outline: none
                        a
                            display: flex
                            justify-content: center
                            align-items: center
                            height: 100%
                            width: 100%
                            //.filter-green
                            //    filter: invert(76%) sepia(48%) saturate(4140%) hue-rotate(119deg) brightness(103%) contrast(101%)
                            .svg
                                width: 42%
                                height: 42%
                                fill: white
            // &, & *
            //     fill: white
.blue-menu
    a
        color:#411DA9!important
    @media (max-width: 1024px)
        a
            color:#fff!important

@media (max-width: 1600px)
    header
        .header-bg-img
            nav
                .menu-wrapper
                    ul.main-menu
                        margin-right: 45px
                    ul.panel-menu
                        margin-right: auto

@media (max-width: 1440px)
    header
        .header-bg-img
            nav
                .menu-wrapper
                    ul.main-menu
                        margin-right: 0
                    ul.panel-menu
                        margin-right: auto
                        margin-left: 20px

@media (max-width: 1245px)
    body
        header
            .header-bg-img
                background-position: 50% 105%
                background-size: auto
                nav
                    padding-top: 25px
                    margin-bottom: 35px + 11px
                    justify-content: space-around
                    .logo
                        height 50px
                        left -60px !important
                        top -7px !important
                    ul.main-menu
                        margin-right: auto
                        font-size: 14px
                        li
                            margin: auto 12px
                            .dropdown
                                font-size: 12px
                    ul.panel-menu
                        font-size: 13px
                        margin-left: 15px
                        li
                            &:nth-child(3)

                                margin-right: 1px
                            &:nth-child(4)
                                margin-left: 0


@media (max-width: 1024px)
    header
        padding: 0
        .header-bg-img
            background-position: 72% 104%
            background-size: auto
            .bottomstrip
                bottom: 0

            nav
                visibility: hidden
                justify-content: space-around !important
                background: transparent
                z-index: 10
                position: absolute
                top: 0
                width: 100%
                height: auto
                padding-top: 25px !important
                #box
                    height: 100vh
                    width: 2px
                    top -20px
                    position: absolute
                    z-index: 0
                    background: #110047 !important
                    transition: height 0.3s linear
                .rwd-nav-stripe
                    width: 100%
                    display: flex
                    visibility: hidden
                    top: 0
                    height: 60px
                    background: #110047
                img.logo
                    visibility: visible !important
                    margin-right: 0
                    z-index: 1 !important
                .menu-wrapper
                    flex-direction: column
                    opacity: 0
                    transition: all 0.3s
                    ul.main-menu
                        flex-direction column
                        align-self flex-start
                        margin 15vh 0 0 10%
                        padding-right 2em
                        font-size 16px
                        box-sizing border-box
                        li
                            width 100%
                            margin 10px 0
                            display flex
                            justify-content start
                            &:hover:before
                                background transparent
                                border none
                            .dropdown
                                border-left solid 1px white
                                flex-direction column
                                padding 0 0 0 30px
                                font-size 16px
                                top 0
                                margin-left 135px
                                &:after
                                    box-shadow none
                    ul.panel-menu
                        margin-left: 0
                        li
                            &:first-child
                                margin-left: 0
    .selected-value
        color: white
        &:before
            content: none
@media (max-width: 1024px)
    body
        header
            .header-bg-img
                nav
                    .logo
                        height: 35px
                        left 10%!important
                        margin-right: 0
                        transform:translateY(11px)
                .menu-wrapper
                    .panel-menu
                        height 140px
                        background #110047
                        bottom 25px
                        padding 0
                        justify-content center
                    .main-menu
                        height calc(100vh - 335px)
                        overflow scroll
                        justify-content flex-start

@media (max-width: 540px)
    body
        header
            .header-bg-img
                .border-top
                    ul
                        li:nth-child(2n)
                            visibility: hidden
#dropdown-login-panel
    color pcsBlue
    position relative
    height 100%
    &:hover
        .log-in-text
            color #00EDC2 !important
    .log-in-text
        font-size 16px
        font-family frank-new !important
        font-weight 400
        transition all 0.3s
.dropdown-panel-visible
    .dropdown-panel
        pointer-events unset !important
        height 140px
        opacity 1 !important
        a
            opacity 1
            pointer-events unset !important
            z-index unset
        a:hover
            color #00EDC2 !important
.dropdown-panel
    pointer-events none !important
    position absolute
    display flex
    justify-content center
    flex-direction column
    padding: 0 23px;
    top 45px
    right 0
    background-color white
    border 1px solid #00edc2
    border-radius 17px
    width fit-content !important
    transition all 0.3s
    font-size 12px
    font-family frank-new !important
    height 0
    opacity 0
    @media (max-width 1024px)
        top -150px !important
    .panel-links
        display flex
        flex-direction row
        width 100%
        justify-content flex-end
    a
        justify-content flex-end!important
        padding 5px 0
        color pcsBlue !important
        width fit-content !important
        height fit-content !important
        white-space nowrap
        text-align right
        transition all 0.3s
        pointer-events: none
        font-weight 400
        opacity 0
        z-index -1
    &:hover
        pcs-shadow-menu()

@media (min-width: 1025px)
  div.sidebar-menu-toggle
    display none!important
div.sidebar-menu-toggle
    position absolute
    display flex
    visibility visible
    cursor pointer
    overflow hidden
    opacity 1
    width 29px
    height 36px
    top 42px
    right 10%
    z-index 9999
    transition all .3s
    div.sidebar-menu-btn
        flex-direction column
        justify-content space-between
        display flex
        height 100%
        width 100%
        div.sidebar-menu-btn-line
            display block
            background #00edc2
            transition all .3s
            width 100%
            height 7px
            &.btn-line-3
              position relative
              z-index 8
              width 7px
              overflow hidden
              background transparent
              transform translateX(22px) rotate(90deg)
              .sidebar-menu-btn-triangle
                transition all .3s
                position absolute
                height 100%
                width 15px
                right 0
                background #00edc2
                transform rotate(45deg)
    &.active
        div.sidebar-menu-btn
            div.sidebar-menu-btn-line
                &.btn-line-1
                    transform translateX(21px)
                &.btn-line-3
                    transform translateX(0px) rotate(90deg)
.logo-refs
    position absolute
    left 10%
    img
        margin 0!important
@media (max-width: 1024px)
    nav
        div.menu-wrapper
            margin 0 !important
            position relative
            width 100%
            height 100vh
            display flex
            justify-content space-between
            top: 0 !important
            ul.main-menu
                width 90%
                li
                    border-bottom 1px solid #2D1B67
                    &:last-of-type
                        border-bottom 0
                        padding-bottom 0
                li.main-menu-items
                    flex-direction column!important
                    a.first-link
                        padding-bottom 11px
                    ul.dropdown
                        border-top: 1px solid #2d1b67!important;
                        border-bottom: none;
                        border-left: none;
                        position unset!important
                        display flex
                        align-items flex-start
                        justify-content flex-start
                        transition all 0.3s
                        width 100%
                        height 0
                        max-height 0
                        margin-left 0!important
                        li
                            padding 10px 0
                            margin 0 !important
                            border-bottom none
                            position relative
                            &::before
                                content: '';
                                background-color: #2d1b67;
                                width: 10px;
                                height: 2px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(50%);
                                left: -30px;
                        &.show-up
                            height fit-content!important
                            max-height 300px
            ul.panel-menu
                position absolute
                z-index 9999999
                bottom 5%
                width 100%
                height fit-content
                display flex
                justify-content space-evenly
                align-items flex-start !important
                flex-direction row-reverse
                padding 0 10%
                li
                    white-space nowrap
                    width 170px
                    height 32px !important
                    margin 0 !important
                    border-bottom 1px solid #2D1B67
                    &#dropdown-login-panel
                        margin-right 10px!important
                        align-items center
                        span.log-in-text
                            height fit-content
