.header-wrapper-home
    margin: 90px auto 0 auto;
    width: 80%!important;
    max-width: 1140px
    padding: 0
    position: relative
    @media (max-width: 768px)
        width: 100%!important;



.operations-wrapper
    margin: 90px auto 0 auto;
    width: 80%!important;
    max-width: 1140px
    padding: 0
    position: relative
    .header
        width: 100%
        margin: 0 auto
        @media (max-width: 768px)
            width: 80%
            justify-content: flex-start

    @media (max-width: 768px)
        width: 100%
    .operations
        display: flex
        margin-top: 50px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        @media (max-width: 768px)
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        .input-field
            max-width 390px
            margin-top: 35px
            .select-box
                label
                    font-size: 14px
                    line-height: 30px  
                    margin: 0 0 0 5px
                    color: #110047  
                    font-weight: 400  
            .select2
                .companies-select
                    background-color: #FFF 
                    border: none !important
        .text-wrapper
            padding: 80px 100px 100px ;
            background-color: #f1f1f1
            flex: 1
            border-bottom-left-radius: 20px
            @media (max-width: 768px)
                border-bottom-left-radius: 0px
                padding: 34px 10% 80px 10%
                
            .text
                @media (max-width: 768px)
                   flex-direction: column
                   align-items: flex-start
            .picto
                margin-right: 20px
                padding-top: 5px
                justify-content: center
                align-items: center
                display: flex
                img
                    width: 91px
                    height: 91px

            p
                margin-top:20px
                font-size: 16px;
                color: #110047;
                font-weight: 400
            button.dark
                margin-top: 25px
                max-width :100%
        .image
            flex: 1
            @media(max-width:992px)
                display none