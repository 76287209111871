.ships-wrapper
    width: calc(100% - 16px);
    z-index: 1;
    position relative
    padding-top: 70px
    padding-bottom: 8%
    min-height: 500px;
    margin-left: 16px;
    @media(max-width:1200px)
        background-size: 100% 100%
        margin-left: 0px;
    .background-element
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right 
        position: absolute
        left 0
        top: 0
        height 100% 
        width calc(90% + 16px)
        border-top-right-radius: 20px
        @media(max-width:1240px){
           width calc(100% + 16px);
        }
    .blenda
        width 90%
    .light-green
        margin-left: 100px;
        @media(max-width: 768px)
            margin-left: 0

    .standard-wrapper
        position relative
        z-index: 10
    .header
        margin-bottom: 10%;
        h2
            p
                font-weight: 300 !important

    .header, .text
        *
            color: #ffffff
    .text
        max-width : 280px;
        margin-left: 25px;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400
        p
            margin-bottom: 0
    .picto
        margin-left: 105px;
        display: flex
        align-items: center
        img
            height 82px;
            width 82px;
        @media(max-width: 768px)
            margin-left: 0
            img
                width: 62px;
                height: 62px;
            