.textes
    // width: 80%
    max-width: 1440px
    margin: 0 auto
    font-family: frank-new, sans-serif
    z-index: 100
    @media (max-width: 768px)
        margin: 0 40px
    .container
        display: flex
        flex-flow: column
        min-width: 100%
        margin: 0
        padding: 0
        @media (max-width: 616px)
            margin: 0
            padding: 0
        .text-wrapper
            width: 100%
            font-size: 16px
            color: headerTextBlue
            font-weight: 400 !important
            word-spacing: 8px
            line-height: 1.5em
            padding: 0 100px
            @media (max-width: 1024px)
                padding: 0 0 0 100px
            @media (max-width: 768px)
                padding: 0
                margin: 0
            ul
                padding: 0 0 0 20px
                list-style-type: none
                font-size: 16px
                font-weight: 00
                li
                    line-height: 2em;
                    list-style-type: none
                    text-align: left
                    &::before
                        content: ''
                        display: inline-block
                        height: .8em
                        width: .8em
                        margin: 10px 10px 0 -20px 
                        border-radius: 50%
                        border: 1px solid pictograms
                        position: absolute