
// to jest baner z karuzelą do strony startowej 
body 
    font-size: 18px
    font-family: frank-new, sans-serif
    font-weight: 500 !important
    font-style: medium 
    margin: 0  
    header 
        height: 100vh
        margin-bottom: 50px
        padding: headerPadding
        overflow: hidden
        &.no-margin
            margin-bottom:40px
            padding-bottom:0


        .header-bg-img  
            height: 100%
            position relative
            .border-container 
                z-index: 999    
                &.border-top
                    position: absolute
                    background-color: white;
                    height: headerPadding
                    width: 100%
                    top: -(headerPadding)
                    z-index: 2
                    ul  
                        flex-direction: row
                        justify-content: space-around
                        align-items: center
                &.border-left
                    position: absolute
                    background-color: white
                    height: calc( 100% + 40px )
                    width: headerPadding
                    top: -(headerPadding)
                    left: -(headerPadding)
                    ul
                        li
                            transform: rotate(270deg)
                &.border-right
                    position absolute
                    left: 100%
                    background-color: white
                    height: calc( 100% + 40px )
                    top: -(headerPadding)
                    width: headerPadding
                    ul
                        li
                            transform: rotate(90deg)
                ul
                    list-style-type: none
                    font-family: frank-new, sans-serif
                    font-weight: 500 !important
                    font-size: 12px
                    height: headerPadding
                    display: flex
                    flex-direction: column
                    justify-content: space-around
                    padding-top: 10px
                    padding: 0
                    height: 100%
/////// Circle Loader
            .circle-loader            
                position: absolute
                z-index: 10
                width: 30px
                height: 30px
                bottom: 22px
                left: calc(50% - 70px)
                .circle-loader-wrap
                    overflow: hidden
                    position: relative
                    margin-top: -10px
                    width: 30px
                    height: 30px
                    background-color: #20194C
                    pcs-border-menu(2px)
                    pcs-shadow-menu()
                    -ms-transform: rotate(180deg)
                    -webkit-transform: rotate(180deg)
                    -moz-transform: rotate(180deg)
                    transform: rotate(180deg)
                    .loader 
                        position: absolute
                        top:0
                        left:100%
                        width:100%
                        height:100%
                        border-radius: 100%
                        background-color: #11727b
                        z-index: 50
                    .left-wrap 
                        left: 0
                        overflow: hidden
                        position: relative
                        width: 50%
                        height:100%
                        .loader
                            position: absolute
                            border-top-left-radius: 0
                            border-bottom-left-radius: 0
                            transform-origin: 0 50% 0
                            -webkit-transform-origin:0 50% 0
                            animation:loading-left 6s infinite linear
                            -webkit-animation:loading-left 6s infinite linear	
                    .right-wrap
                        left:50%
                        top: -100%
                        overflow: hidden 
                        position: relative
                        width: 50%
                        height:100%
                        .loader 
                            left:-100%
                            position: absolute
                            border-bottom-right-radius: 0
                            border-top-right-radius: 0
                            transform-origin: 100% 50% 0
                            -webkit-transform-origin:100% 50% 0
                            animation:loading-right 6s infinite linear 
                            -webkit-animation:loading-right 6s infinite linear 



                    .circle-loader-button-behind
                        background-color:#20194C
                        position: absolute
                        left: 4px
                        top: 4px
                        width: 18px
                        height: 18px
                        border-radius: 50%
                        z-index: 100
                        transform: rotate(180deg)
                        .svg
                            height: 90%
                            width: 100%
                            position: absolute
                            top: 1px 
                            left: 1px   
                            // z-index: -999
                    .circle-loader-button
                        background-color:#20194C
                        position: absolute
                        left: 4px
                        top: 4px
                        width: 18px
                        height: 18px
                        border-radius: 50%
                        z-index: 100
                        -webkit-transform: translate3d(0,0,120px);
                        .svg
                            height: 90%
                            width: 100%
                            position: absolute
                            top: 2px
                    

               
                @keyframes loading-left{
                    0%{
                        transform:rotate(0deg)
                    }
                    25%{
                        transform:rotate(90deg)
                    }
                    50%{
                        transform:rotate(180deg)
                    }
                    100%{
                        transform:rotate(180deg)
                    }
                }
                
                @keyframes loading-right{
                    0%{
                        transform:rotate(0deg)
                    }
                    25%{
                        transform:rotate(0deg)
                    }
                    50%{
                        transform:rotate(0deg)
                    }
                    75%{
                        transform:rotate(90deg)
                    }
                    100%{
                        transform:rotate(180deg)
                    }
                }
                                   
//////// Owl Carousel
        #wrapper-karuzeli                   
            height: 100%
            margin-top: -147px
            background: #110047
        .owl-stage-outer 
            height: 100%                       
            .owl-stage
                height: 100% !important
                .owl-item
                    height: 100%
                    .owl-carousel 
                        height: 100%
                        .owl-dots
                            margin-top: -55px
                            position: relative
                            z-index: 1
                            color: white
                            font-size: 12px
                            font-weight: 400 !important
                            .owl-dot
                                margin: 0px 5px 5px 5px
                            .owl-dot:hover
                                color: pcsGreen
                            .owl-dot.active
                                color: pcsGreen
                        .owl-dots::before
                            content:''
                            position: absolute
                            height: 1px
                            width: 180px
                            background: rgba(0, 237, 194, .3)
                            left: calc(50% - 90px)
                            top: 35%
                            z-index: -1
                        .singleSlide   
                            height: 100%
                            margin: 0 auto
                            padding-top: 130px
                            width: 100%
                            display: flex
                            flex-direction: column
                            justify-content: center
                            color: white
                            .single-slide-background
                                background-repeat: no-repeat
                                background-position: 50% 100%
                                background-size: cover 
                                width: 100%
                                height: 100%
                                position: absolute
                                top: 0px
                            .granatowa-blenda
                                height: 100%
                                width: 100%
                                position: absolute
                                top: 0px
                                background: rgba(17, 0, 71, 0.4)
                            .green-circle-wrapper
                                height: 100%
                                width: 100%
                                position: absolute
                                top: 0
                                .green-circle
                                    height: 800px
                                    width: 800px
                                    margin-top: 4%
                                    margin-left: 6%
                                    border-radius: 50%
                                    background: radial-gradient(circle, rgba(0,237,194,0.3) 0%, rgba(0,237,194,0) 60%, rgba(0,237,194,0) 100%)
                            .single-slide-seashore
                                background-image: url(../img/banner_seashore.svg)
                                background-repeat: no-repeat
                                background-position: 50% 105%
                                background-size: contain 
                                width: 100%
                                height: 100%
                                position: absolute
                                top: 0px
                            .bottomstrip
                                background-color: #110047
                                width: 100%
                                height: 20px
                                position: absolute
                                bottom: 0px
                            .slide-content
                                z-index: 1
                                display: flex
                                flex-direction: column
                                justify-content: space-around
                                width: 70%
                                max-width: 1440px
                                height: 100%
                                max-height: 880px
                                margin: 0 auto
                                padding-bottom: 180px
                                padding-top: 60px
                                .slide-text       
                                    h1 
                                        margin-top: 0px
                                        font-size: 80px
                                        font-family: frank-new, sans-serif
                                        font-weight: 500 !important
                                        font-style: normal
                                    h2 
                                        font-size: 40px
                                        font-family: frank-new, sans-serif
                                        font-weight: 200 !important
                                        font-style: normal      
                                    ul
                                        color: black
                                        font-size: 19px
                                        font-family: frank-new, sans-serif
                                        font-weight: 400 !important
                                        font-style: regular
                                        display: flex                   
                                        width: 100%
                                        padding-inline-start: 0px
                                        color: white
                                        margin-top: 4%              
                                        li
                                            color: white
                                            list-style-type: none
                                            padding-right: 40px
                                        li::before
                                            content: ''
                                            width: 1px
                                            height: 1px
                                            display: inline-block
                                            padding: 0.25em
                                            margin-right: 0.5em
                                            background-color: rgba(32,24,85,0.5)
                                            pcs-border-menu(2px)
                                            pcs-shadow-menu()
                                        li:hover:before
                                            cursor: pointer
                                .btn-more
                                    font-size: 14px
                                    font-family: frank-new, sans-serif
                                    font-weight: 400 !important
                                    font-style: regular
                                    width: 160px
                                    height: 40px
                                    background-color: rgba(32,24,85,0.5)
                                    color: white
                                    transition: all 0.3s linear;
                                    pcs-border-menu()
                                .btn-more:hover
                                    cursor: pointer
                                    pcs-shadow-menu()
/////// Media Query
@media (min-width: 2190px)
    body
        header
            padding: 20px 20px 0px 20px 
            .bottomstrip
                bottom: 0px
            .header-bg-img
                background-size: cover
                background-position: 0%
                .circle-loader
                    bottom: 22px  
            .owl-stage-outer
                .owl-stage
                    .owl-item
                        .owl-carousel
                            .singleSlide
                                .green-circle-wrapper
                                    .green-circle
                                        height: 1000px
                                        width: 1000px
                                        margin-left: 8%
                                        margin-top: 3%
                                .single-slide-seashore
                                    background-position: 0% 700px

@media (max-width: 1600px)
    body
        header 
            .owl-stage-outer
                .owl-stage
                    .owl-item
                        .owl-carousel
                            .singleSlide
                                .green-circle-wrapper
                                    .green-circle
                                        margin-left: 8%
                                        margin-top: 6%
@media (max-width: 1245px)
    body
        header 
            .header-bg-img
                background-position: 50% 100% 
                background-size: auto               
@media (max-width: 1024px)
    body
        padding: 0px
        header 
            padding: 0px
            .header-bg-img
                #wrapper-karuzeli                   
                    margin-top: -100vh
                    margin: 0px
                .owl-carousel 
                    height: 100vh
                    margin: 0px
                    .owl-dots
                        bottom: 85px
                        margin-top: 0px !important
                    .owl-dots::before
                        width: 80px
                        left: 46%
                    .owl-stage-outer
                        height: 100vh
                        .owl-stage
                            height: 860px
                            .owl-dots
                                    bottom: 45px
                            .singleSlide
                                .single-slide-background
                                    background-size: cover
                                .slide-content
                                    max-width: 1440px
                                    .slide-text
                                        margin-top: 100px
                                        h1 
                                            font-size: 60px
                                            line-height: 55px
                                            margin-bottom: 10px
                                        h2 
                                            font-size: 30px
                                            padding-bottom: 20px
                                        ul
                                            width: 80%
                                            font-size: 16px
                                            margin-bottom: 150px
                .circle-loader
                    visibility: hidden
@media (max-width: 768px)
    body
        header 
            .header-bg-img
                background-position: 72% 99%  
                .owl-carousel 
                    .owl-stage-outer
                        .owl-dots
                        .owl-stage
                            .owl-item
                                height: 100%
                                .singleSlide
                                    .slide-content
                                        .slide-text
                                            ul
                                                width: 100%
                                                font-size: 14px
                                                // li
                                                //     padding: 15px  
@media (max-width: 616px)
    body
        header 
            .header-bg-img
                .owl-carousel 
                    .owl-stage-outer
                        .owl-dots
                        .owl-stage
                            .owl-item
                                .singleSlide
                                    .slide-content
                                        padding-top: 0px
                                        .slide-text
                                            h1 
                                                font-size: 45px
                                                line-height: 55px
                                                margin-bottom: 10px
                                            h2 
                                                font-size: 20px
                                                padding-bottom: 20px
                                            ul
                                                display: table-row
                                                margin-bottom: 10% 
                                                li
                                                    margin: 0px auto
                                                    padding-top: 1px  
@media (max-width: 540px) 
    body
        header 
            .header-bg-img
                background-position: 72% 97%
                .owl-carousel 
                    .owl-stage-outer
                        .owl-dots
                        .owl-stage
                            .owl-item
                                height: 100%
                                .singleSlide
                                    height: 600px
                                    .slide-content
                                        padding: 0px
                                        margin: 0 auto
                                        .slide-text
                                            h1
                                                font-size: 35px
                                                line-height: 40px
                                            h2
                                                padding-bottom: 20px
                                            ul
                                                display: table-row
                                                margin-bottom: 10% 
                                                li
                                                    margin: 0px auto
                                                    padding-top: 1px
@media (max-width: 440px)
    body
        header 
            .header-bg-img
                .owl-carousel 
                    .owl-stage-outer
                        .owl-stage
                            .owl-item
                                height: 100%
                                .owl-dots
                                    bottom: 45px
                                    .slide-content
                                        padding-bottom: 0px
                                        .slide-text
                                            h1
                                                font-size: 35px
                                            h2
                                                padding-bottom: 20px
                                                font-size: 25px
                                            ul
                                                display: table-row
                                                margin-bottom: 10% 
                                                li
                                                    margin: 0px auto
                                                    padding-top: 1px















    




            

    
                                
                                    

                        




            
