 
.mainPartners
    padding: 0px 100px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    .portsTilesWrapper
        margin-top: 100px
        width: 100%
        ul
            list-style-type: none
            padding: 0 20px
            display: grid
            grid-template-columns: repeat(1, 1fr)
            grid-gap: 10px
            overflow: hidden
            @media(min-width: 768px)
                grid-template-columns: repeat(2, 1fr)
            @media(min-width: 1280px)
                grid-template-columns: repeat(3, 1fr)
            li
                border: solid 1px pcsGreen 
                width: 100%
                min-width: 33%
                margin: 3px
                padding: 4em 2em 6em 2.5em
                border-bottom-left-radius: 20px
                border-bottom-right-radius: 20px
                display: flex
                flex-direction: column
                align-items: center
                img
                    margin-top:16px
                    max-width: 90%
                    //width: 100%
                a
                    text-decoration: none
                    color: pcsGreen
                    font-size: 14px
                    margin-bottom: 8px
                    margin-top: 20px
                p
                    margin: 0px 0px 16px
                    width: 100%
                    font-size: 14px
                    line-height: 2em
                    font-weight: 400 !important
                    color: #110047

    .row-elements-4
        width: 80%
        max-width: 1440px
        ul
            list-style-type: none
            display: flex
            flex-direction: row
            flex-wrap: wrap
            justify-content: center
            overflow: hidden
            li
                border: solid 1px pcsGreen
                margin: 3px
                margin-bottom: 40px
                padding: 50px 2em
                width: 24%
                height: 215px
                border-bottom-left-radius: 20px
                border-bottom-right-radius: 20px 
                display: flex
                flex-direction: column
                align-items: center
                justify-content: space-around 
    .row-elements-3
        width: 100%
        max-width: 1440px
// @media (max-width: 1300px)
//     .mainPartners
//         .portsTilesWrapper
//             padding: 0px 80px
@media (max-width: 1245px)      
    .mainPartners
        padding: 0px
        .row-elements-3
            ul
                width: 100%
                align-items: center
                margin: auto 0px
                flex-direction: column 
                li
                    width: 66%

@media (max-width 1024px)
    .mainPartners
        .row-elements-4
            ul
                li
                    min-width: 200px
        .row-elements-3
            ul
                width: 100%
                align-items: center
                margin: auto 0px
                flex-direction: column 
                li
                    width: 66%
@media (max-width 768px)
    // #partnersWrapper
    //     .partnersList
    //         .partnersIntro
    //             ul
    //                 justify-content: center
    //                 li
    //                     padding: 10px 15px 10px 0px
    //                     &:last-child
    //                         background-position: 88%
    .mainPartners
        .row-elements-3
            ul
                align-items: center 
                flex-direction: column
                li  
                    width: 60%
 
@media (max-width 768px)
    .mainPartners
        .row-elements-3
            ul 
                li  
                    width: 98%
                    padding: 4em .5em 6em 1em
