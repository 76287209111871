mainRed = #ff0000
headerNumbersBlue = #26009e
headerTextBlue = #110047
pictograms = #00edc2
newsBackgroundColor = #11064a
unHoverBox = #e3fdf8
mapGrey = #d2d4d6
bottomLineGrey = #e5e3eb
errors = #c79494
whiteButtonHover = #c2ffef

pcsTextLightBlue = #26009e
pcsTextDarkBlue = #110047
pcsBlue = #330099
pcsGreen = #00edc2
frankNewFont = frank-new normal 200, sans-serif 
headerPadding = 20px

// body
    // *
	// 	background-color: rgba(0, 0 , 0, 0.1)
.standard-wrapper
    width: 80%;
    max-width: 1140px;
    margin: 0 auto

.blenda
    width: 100%
    position: absolute
    bottom: 0
    left 0
    height 100%
    z-index 1
    background: -moz-linear-gradient(left, rgba(17,0,71,1) 0%, transparent 100%)
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(17,0,71,1)), color-stop(100%, transparent))
    background: -webkit-linear-gradient(left, rgba(17,0,71,1) 0%, transparent 100%)
    background: -o-linear-gradient(left, rgba(17,0,71,1) 0%, transparent 100%)
    background: -ms-linear-gradient(left, rgba(17,0,71,1) 0%, transparent 100%)
    background: linear-gradient(to right, rgba(17,0,71,1) 0%, transparent 100%)


pcs-border-menu(width = 1px)
    border: solid width pcsGreen
    border-radius: 20px
pcs-shadow-menu()
    box-shadow: 0px 0px 10px 1px pcsGreen



*
    box-sizing: border-box
    
    .sticky
        width: 100%
        position: fixed
        left: 0
        top: 0
        z-index: 100



a:active,
a:visited,
a:focus
    text-decoration: none
    outline: none
a
    transition: all 0.3s linear

body
    font-family: "frank-new", "sans-serif" !important
.display-inline
    height: 45px !important
.logo-sticky-visible
    display: inline !important
    height: 40px
    margin-top: -10px
    visibility: visible
.logo-sticky-mobile
    display none
.current
    color: pcsGreen
.blue-border-bottom
    border-bottom: solid 1px pcsBlue !important
    transition: all 0.3s ease
.pseudo-no-content
    &:after
        content: none !important
.visible
    visibility: visible !important

.element-content-box
    width: 80%
    max-width: 1440px
    margin: 0px auto
    background: white
    margin-top: -400px
    position: relative
    border-top-right-radius: 20px
    @media (max-width: 616px)
        width: 100% !important
        border-top-left-radius: 20px
    &.containers-status
        background: none
        margin: -680px auto 0;
//menu

.overflowHidden
    overflow-y: hidden
    // position: fixed
    touch-action: none
.big-box
    width: 2000px !important
    height: 2000px  !important
.opacity100
    opacity: 1 !important
.hamburger-1st                      
    top: 29px !important 
    width: 0% !important
    opacity: 0 !important
.hamburger-2nd
    transform: rotate(45deg) !important
.hamburger-3rd
    transform: rotate(-45deg) !important
.hamburger-4th
    top: 29px !important 
    width: 0% !important 
    opacity: 0 !important
    

. selected-value
    color: pcsGreen !important
    &:before
        content: ''
        top: -5px
        right: calc(50% - 10px)
        width: 20px
        height: 4px
        border: solid 1px pcsGreen
        border-color: pcsGreen transparent transparent transparent
        border-radius: 50%/3px 3px 0 0
        position: absolute
        transform: rotate(180deg)
//endmenu

.aboutMenu-OnOff
    display: flex !important

.aboutMenu-borderBottom
    border-bottom: none !important

.show-up
    opacity : 1 !important
    visibility visible !important
.slider-button-behind
    z-index: -100 !important
.slider-button-front
    z-index: 100 !important
.paused
    -webkit-animation-play-state:paused !important
    -moz-animation-play-state:paused !important
    -o-animation-play-state:paused !important
    animation-play-state:paused !important
    display: none !important
.bordered
    border-bottom: 1px solid white

.container
    background-color: none
    &::before
        display: none
    &::after
        display none
        padding: 0

.activate
    color: red


.margin
    display: inline-block
    &.xlarge
        margin-bottom: 240px
    &.xsmall
        margin-bottom: 40px
    &.large
        margin-bottom:  120px
    &.medium
        margin-bottom: 85px
    &.small
        margin-bottom: 60px

.bold
    font-weight: 700 !important

.number-tresc-header
    display: flex
    flex-flow: row
    width: 80%
    margin: 0 auto
    h1
        margin: 0 43px 0 0
        color: headerNumbersBlue
        font-size: 52.95px
        padding: 0
        font-weight: 100 !important
    h2
        color: headerTextBlue
        padding: 20px 0 0 0
        margin: 0  
        font-weight: 400 !important
        font-size: 36px
.button
    *
        transition: all 0.3s linear
.header
    width: 90%
    display: flex
    // align-items: flex-end
    h1
        margin: 0 43px 0 0
        color: headerNumbersBlue
        font-size: 52.95px
        padding: 0
        font-weight: 100 !important
    h2 
        color: headerTextBlue
        transform: translateY(15%);
        margin: 0   
        p
            padding: 0
            margin: 0
        * 
            font-weight: 400 !important
            font-size: 36px
            line-height: 46px


ol
    list-style-type: none
    padding: 0
    margin: 0


input::-webkit-input-placeholder, textarea::-webkit-input-placeholder
    color: headerTextBlue

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder
    color: #fff

input:-moz-input-placeholder, textarea:-moz-input-placeholder
    color: headerTextBlue

input:focus::-moz-input-placeholder, textarea::-moz-input-placeholder
    color: #fff

input::-moz-input-placeholder, textarea::-moz-input-placeholder
    color: headerTextBlue

input:focus::-moz-input-placeholder, textarea::-moz-input-placeholder
    color: #fff

input:-ms-input-placeholder, textarea:-ms-input-placeholder
    color: headerTextBlue

input:focus::-ms-input-placeholder, textarea::-ms-input-placeholder
    color: #fff


.flex
    display: flex
    &.space
        justify-content: space-between


.mb-40
    margin-bottom: 40px;

#mapa-statkow, #ruch-statkow
    display: block
    height 800px
.map-wrapper
    position relative
    .loading-blenda
        z-index 1
        height 100%;
        width: 100%
        opacity 0.8;
        background-color: #ffffff
        display: none
        position: absolute
        top: 0
        left 0
        #pcs-spinner
            display block
        &.visible
            display block !important
#ruch-statkow
    height: calc(100vh - 151px);
    //height: 83.5vh
    position: relative
    //@media (max-width: 1500px)
    //    height: 80vh
    //@media (max-width: 1300px)
    //    height: 77vh
    //@media (min-width: 2500px)
    //    height 87vh


#pcs-spinner
    display: none;
    position: absolute
    top: 50%
    left 50%
    transform: translate(-50%, -50%)
    z-index 999
    background-color: transparent
    &.visible
        display: block !important
.select2-selection__clear
    z-index: 999
    margin-right: 20px
    display:none

.relative
    position: relative

.object-data
    padding: 40px 25px 15px 25px
    font-weight: bold
    font-size: 14px
    line-height: 20px
    color: #110047
    display:flex
    justify-content: center
    align-items: center
    .for-img

        .svg
            width: 8px
            height: 8px

    .title
        margin-bottom: 20px

    .desc
        font-size: 12px
        line-height: 17px
        color: #110047
        font-size: 12px
        line-height:22px
    a.newItem
        color:unset
        text-decoration:unset
        transition: all 0.2s!important
        &:hover
            transition: all 0.2s!important
            color:#00ecc2
            text-decoration:unset
.gm-style-iw
    padding: 0 !important


.error-modal
    background-color: #fff;
    opacity: 0;
    z-index: -1;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 50px;
    transform: translate(-50%, -50%);
    &.visible
        opacity 1
        z-index 1
#ruch-statkow
    .gm-style
        .gm-style-iw-t
            &::after
                top:-13px

    .gm-style-iw
        //max-width: 150px !important
        border-radius: 30px;
        top: -12px;
        div
            //width: 250px
            overflow: hidden !important
            .object-data
                padding: 0 16px;
                .title
                    color: #411DA9
                    text-transform: uppercase
                    font-size:12px
                    padding: 10px
                    margin: 0
                .img
                    width: 100%
                    height 125px
                    // background: #7eb0de
                    // background-image: url('../img/ship-img.png')
                    margin-bottom: 10px
                .desc
                    padding: 10px 10px 10px 10px
                    font-size: 14px
                    .value
                        display: block !important
                        color: #929292


        button
            z-index:10;
            right : 0 !important
            width: 100%!important
            height: 100%!important
            top: 0px!important
            img
                display:none!important
        button:before, button:after
            position: absolute;
            left: 15px;
            content: ' ';
            height: 15px;
            width: 2px;
            top:10px
            display:none
        button:before
            transform: rotate(45deg)
        button:after
            transform: rotate(-45deg)
