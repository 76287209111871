.newsletter-wrapper
    margin: 90px auto 0 auto;
    width: 80%;
    max-width: 1140px
    padding: 0
    position: relative
    @media (max-width: 768px)
        width: 100%
    .newsletter-container
        padding: 0
        display: flex
        flex-flow: column
        justify-content: flex-start
        position: relative
        .newsletter-header
            width: 100%
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            width: 100%
            margin: 0px auto 
            @media (max-width: 768px)
                width: 80%
            .header
                padding: 0 0 43px 0
                display: flex
                justify-content: flex-start
                flex-flow: row nowrap
                align-items: center
                margin: 0
                width: 100%
                background-color: white
                z-index: 1
                @media (max-width: 768px)
                    justify-content: flex-start
                    align-items: flex-start
                    flex-flow: column
                h1
                    margin: 0 35px 0 0
                    color: headerNumbersBlue
                    font-size: 52.95px
                    padding: 0
                    font-weight: 100 !important
                .wrapper
                    h2 
                        display: flex
                        transform: translateY(0%);
                        @media (max-width: 768px)
                            padding: 0 
                            justify-content: flex-start

        .newsletter-content
            border-bottom-left-radius: 20px
            border-bottom-right-radius: 0px
            display: flex
            padding: 0
            margin: 0
            background-color: #00ecc2
            flex-flow: row
            justify-content: flex-start
            align-items: flex-start
            width: 50%
            position: relative
            @media(max-width: 992px)
                border-bottom-right-radius: 20px
                width: 100%
            @media (max-width: 768px)
                border-bottom-left-radius: 0px
                border-bottom-right-radius: 0px
            .left-box
                min-height: 100%
                display: flex
                flex-flow: column
                justify-content: flex-start
                margin: 0 auto
                width: 100%
                @media (max-width: 768px)
                    width: 80%
                .top-elements
                    padding: 80px 100px 19px 100px
                    width: 100%
                    display: flex
                    flex-flow: row
                    justify-content: center
                    align-items: center
                    @media (max-width: 768px)
                        padding: 34px 0 19px 0
                        align-items: flex-start
                        flex-flow: column
                    .piktogram
                        width: 91px
                        height: 100%
                        padding: 0 
                        margin: 0 21px 0 0
                        display: flex
                        align-items: center
                        justify-content: center
                        @media (max-width: 768px)
                            margin: 0 21px 34px 0
                        @media (max-width: 375px)
                            width: 65px
                        .svg
                            width: 91px
                            height: 91px
                    .opis
                        display: flex
                        align-items: center
                        justify-content: center
                        height: 100%
                        width: 100%
                        color: newsBackgroundColor
                        font-size: 16px
                        font-weight: 400
                        p
                            width: 100%
                            padding: 0
                            margin: 0

                .form
                    width: 100%
                    padding: 19px 100px 80px 100px
                    font-size: 14px
                    font-weight: 100
                    color: headerTextBlue
                    @media (max-width: 768px)
                        padding: 19px 0 80px 0

                    label
                        width: 100%
                        line-height: 3
                        font-size: 14px
                        color: newsBackgroundColor
                        padding: 0
                        margin: 0
                        font-weight: 400
                    form
                        display: flex
                        flex-flow: column
                        font-size: 14px
                        color: headerTextBlue
                        font-weight: 100
                        input[type="email"]
                            width: 100%
                            height: 40px
                            border-radius: 20px
                            border: none 
                            outline: none 
                            color: newsBackgroundColor
                            font-weight: 100
                            padding: 0 23px
                            margin: 10px 0 23px 0
                            @media (max-width: 992px)
                                width: 390px
                            @media (max-width: 798px)
                                width: 100%
                        input::placeholder
                            color: pictograms
                            font-size: 14px
                            color: #c5c5c5
                            font-weight: 400
                        input[type="submit"]
                            font-weight: 400
                            width: 124px
                            height: 40px
                            border-radius: 20px
                            background-color: newsBackgroundColor
                            color: pictograms
                            border: none 
                            outline: none
                            font-size: 14px
                            cursor: pointer
        .right-box-image
            position: absolute
            right: 0
            bottom: 0
            height: 100%
            width: 50%
            @media (max-width: 992px)
                display: none
            img 
                border-bottom-right-radius: 20px
                width: 100%
                height: 100%

.email-input
    border: 1px solid red

.partnerzy-content
    height 430px
    //border-bottom-left-radius: 20px
    //border-bottom-right-radius: 0px
    //display: flex
    //padding: 0
    //margin: 0
    //flex-flow: row
    //justify-content: flex-start
    //align-items: flex-start
    width: 100%
    position: relative
    @media (max-width: 1443px)
        height auto
    @media(max-width: 992px)
        border-bottom-right-radius: 20px
        width: 100%
    @media (max-width: 768px)
        border-bottom-left-radius: 0px
        border-bottom-right-radius: 0px
    .aktywny
        width 1140px !important
        height 430px !important
        border-radius: 20px !important 
        object-fit fill !important
        @media (max-width: 1443px)
            width 100% !important
            height auto !important
        @media (max-width: 768px)
            border-radius: 0px !important
    .carousel-pagination
        padding-top 45px
        .carousel-indicators
            li
                width 9px!important
                height 9px!important
                border-radius 50%!important
                background-color #11004724
                margin 0 3.5px
                cursor pointer
                &.active
                    background-color #00edc2


