.margin
    &.Extra 
        &.Small
            margin-bottom: 40px
    &.Small
        margin-bottom: 60px
    &.Medium
        margin-bottom: 85px
        @media mobi 
            margin-bottom: 35px
    &.Large
        margin-bottom: 120px
        @media scr1024
            margin-bottom: 80px
        @media mobi
            margin-bottom: 50px
    &.Extra 
        &.Large
            margin-bottom: 240px
            @media scr1024
                margin-bottom: 120px
            @media mobi
                margin-bottom: 60px