.header
    @media(max-width: 768px)
        flex-direction: column
        align-items: flex-start;
        h2
            padding: 0
.community-wrapper
    margin-left: 105px;
    margin-right: 95px
    margin-top: 10%
    color: #110047

    @media(max-width: 768px)
        margin-left: 0px;
        flex-direction: column-reverse
    @media(max-width: 600px)
        margin-top:70px

    h1
        font-size: 60px;
        line-height: 65px;
        font-weight: 700
        @media(max-width:1024px)
            font-size: 30px;
            line-height: 35px;
    p
        font-size: 29px;
        line-height: 35px;
        font-weight: 400
        @media(max-width:1024px)
            font-size: 16px;
            line-height: 30px;
        @media(max-width:600)
            margin-bottom: 70px

    .text-wrapper
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        flex: 2
    .picto-wrapper
        display: flex
        align-items: flex-start
        flex: 0
        @media(max-width:768px)
            margin-bottom: 40px;
        img
            height 190px
            width 190px
            @media(max-width:1024px)
                height 120px;
                width 120px;
