#tekstIMockupPoDole
    max-width: 1440px
    margin: 0 auto
    width: 100%
    @media(max-width: 768px)
        display: column
        margin: 0
        padding: 0
    @media(max-width: 616px)
        margin: 0
        padding: 0 40px 
    .header
        max-width: 1440px
        display: flex
        justify-content: flex-start
        flex-flow: row
        align-items: flex-start
        height: auto
        width: 100%
        padding: 0
        position: relative
        @media(max-width: 768px)
            display: column
            // margin: 0 40px
            margin: 0
        @media(max-width: 616px)
            margin: 0
        h1
            margin: 0 43px 0 0px
            color: headerNumbersBlue
            font-size: 52.95px
            font-weight: 100 !important
            position: relative
        .wrapper
            width: 100%
            display: flex
            flex-flow: column
            h2 
                color: headerTextBlue
                font-weight: 400 !important
                font-size: 36px
                margin: 0  
                height 100%
                line-height: 46.02px
                @media (max-width: 1024px)
                    margin: 15px 0 0 0
            .h2
                font-size: 16px
                font-weight: 100 !important
                width: 100%
                z-index: 100
                line-height: 2em
                margin: 30px 0 0 0
                color: headerTextBlue
                padding: 22px 0 40px 0
                hyphens: auto
                @media (max-width: 1230px)
                    width: 100%
                p
                    font-weight: 400
                    width: 100%
                    @media (max-width: 1230px)
                        width: 100%
                    ul
                        li
                            &:before
                                content: ''
                                display: inline-block
                                height: .8em
                                width: .8em
                                margin: 0 10px 0 0
                                border-radius: 50%
                                border: 1px solid pictograms 
                .button
                    display: none
            .bottom-box-outline
                .bottom-box
                    max-width: 100%
                    max-height: 100%
@media (max-width: 1024px)
    #tekstIMockupPoDole
        .header
            h1
                padding: 0px
            .wrapper
                h2
                    padding: 0px
                    width: 100%
            .bottom-box-outline
                .bottom-box 
                    // display: none
@media (max-width: 768px)
    #tekstIMockupPoDole
        .header
            flex-direction: column
