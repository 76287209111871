.general-contact-wrapper
    height: 660px
    position: relative
    max-width: 1440px
    width: 80%
    margin: 0 auto
    @media(max-width: 768px)
        padding: 0 40px
    @media(max-width: 616px)
        padding: 0
        width: 100%
    .google-map
        position: absolute
        width: 85%
        height: 760px
        top: -10px
        border-top-right-radius: 40px
        overflow: hidden
        display: flex
    .map-wrapper
        position: relative
        padding: 0
        margin: 50px auto 0 auto
        // width: 100%
        @media(max-width: 616px)
            margin: 0 40px
        .container
            min-width: 100%
            margin: 0 auto
            padding: 50px 0 0 0
            .map
                display: flex
                flex-flow: row nowrap
                align-items: flex-start
                padding: 0
                margin: 0
                z-index: 100
                width: 100%
                justify-content: space-between
                @media (max-width: 768px)
                    flex-flow: column
                    align-items: flex-start
                    width: 100%
                    margin: 0 0 15px 0
                    padding: 0
                h1
                    margin: 0 43px 0 0
                    color: headerNumbersBlue
                    font-size: 52.95px
                    padding: 0
                    font-weight: 100 !important
                    align-items: flex-start
                .right-map-part
                    display: flex
                    justify-content: space-between
                    width: 100%
                    @media (max-width: 1152px)
                        flex-flow: column
                    h2 
                        color: headerTextBlue
                        padding: 18px 0 0 0
                        margin: 0 
                        display: flex 
                        font-weight: 400 !important
                        font-size: 36px
                        line-height: 46px;
                        flex-flow: flex-end
                        @media (max-width: 768px)
                            padding: 0 0 0 3px 
        .map-header
            display: flex
            width: 35%
            flex-flow: row
            justify-content: flex-end
            text-align: center
            position: absolute
            right: 0
            z-index: 100
            @media (max-width: 936px)
                flex-flow: column
                width: 80%
                margin: 0 auto
            @media (max-width: 768px)
                width: 100%
            .map-text
                border: 1px solid mapGrey
                border-bottom-right-radius: 30px
                width: 380px
                margin: 20px 0 
                display: flex
                flex-flow: column
                padding: 43px 0
                background-color: #fff
                position: relative
                @media (max-width: 936px)
                    background: transparent
                @media (max-width: 768px)
                    width: 100%
                    border: none
                    margin: 0 auto 
                #triangle
                    transform: rotate(-90deg)
                    position: absolute
                    top: 75px
                    left: -22px
                    @media (max-width: 768px)
                        display: none
                #triangle:after       
                    position:absolute
                    content:""
                    width: 0
                    height: 0
                    margin-top: 2px
                    margin-left: 2px
                    border-left: 20px solid transparent
                    border-right: 20px solid transparent
                    border-bottom: 20px solid white
                #triangle:before 
                    position:absolute
                    content:""
                    width: 0
                    height: 0
                    border-left: 22px solid transparent
                    border-right: 22px solid transparent
                    border-bottom: 22px solid mapGrey
                .adress
                    border-bottom: 1px solid pictograms
                    width: 50%
                    min-height: 230px
                    padding: 20px 0 
                    display: none
                    flex-flow: column
                    justify-content: center
                    margin: 0 auto
                    text-align: left
                    line-height: 2
                    position: relative
                    font-size: 16px
                    @media (max-width: 768px)
                        width: 100%
                        margin: 0
                    :first-child
                        font-weight: 700 !important
                    :nth-child(2)
                        margin: 50px 0 0 
                    p
                        padding: 0
                        margin: 0
                        font-weight: 400 !important
                        color: headerTextBlue
                        font-size: 16px
                            
                    h1
                        font-size: 14px
                        color: headerTextBlue
                        margin: 0 0 50px 0
                        position: absolute
                        top: 0
                        left: 0
                        width: 100%
                        font-size: 16px
                        @media (max-width: 768px)
                            font-size: 16px
                            padding: 0 0 10px 0

                .visible
                    display: flex

                .lokalization
                    display: flex
                    justify-content: center
                    flex-flow: column
                    text-align: left
                    margin: 30px 0 0 0
                    padding: 30px 0 30px 63px
                    width: 70%
                    margin: 0 auto
                    line-height: 2
                    position: relative
                    @media (max-width: 768px)
                        width: 100%
                        border: none
                        margin: 0
                        font-size: 16px
                        padding: 30px 0 30px 30px
                    .input-map
                        width: 100%
                        font-size: 14px
                        color: headerTextBlue
                        font-weight: 400 !important
                        position: relative
                        font-size: 16px
                .lokalization-go
                    .map-center
                        background: none
                        outline: none 
                        border: none
                        display: flex
                        justify-content: flex-start
                        flex-flow: row
                        text-align: left
                        margin: 0 0 0 0
                        padding: 30px 0 30px 0
                        width: 70%
                        margin: 0 auto
                        align-items: center
                        text-decoration: none
                        color: headerNumbersBlue
                        font-size: 14px
                        font-weight: 700 !important
                        @media (max-width: 768px)
                            font-size: 16px
                    .arrow
                        padding: 0
                        display: flex
.google-map2
    width: 65%
    margin: -662px 0 0 0
    @media(max-width: 2560px)
        width: 70%
    @media(max-width: 2560px)
        width: 75%
    @media(max-width: 2000px)
        margin: -650px 0 0 0
    @media(max-width: 936px)
        margin: 100px 0 0 0
        width: 100%
    @media(max-width: 768px)
        margin: 0px 0 0 0
        




input[type=radio] 
    display: none
    cursor: pointer

.checkmark, .checkmarked
    position: absolute
    top: 5px
    left: -25px
    height: 15px
    width: 15px
    background-color: red    
    border-radius: 50%
    cursor: pointer

.checkmarked
    position: absolute
    top: 70px
    left: 0px
    height: 15px
    width: 15px
    background-color: red    
    border-radius: 50%
    cursor: pointer

input:checked ~ .checkmark
    background-color: pictograms

input:checked ~ .checkmarked
    background-color: pictograms
    
.checkmark:after, .checkmarked:after
    content: ""
    position: absolute
    display: none
    

input ~ .checkmark 
    background-color: #ccc

input ~ .checkmarked 
    background-color: #ccc

input:checked ~ .checkmark:after
    display: block

input:checked ~ .checkmarked:after
    display: block

.checkmark:after, .checkmarked:after
    top: 2px
    left: 1.8px
    width: 11px
    height: 11px
    border-radius: 50%
    background-color: pictograms
    border: 2px solid #fff



                                
