.career
    margin: 0 auto
    font-family: frank-new, sans-serif
    max-width: 1440px
    height: 100%
    position: relative
    z-index: 0
    @media (max-width: 768px)
        margin: 0 40px
    .container
        border-top-right-radius: 20px
        display: flex
        flex-flow: column
        width: 100%
        padding: 0
        margin: 0 auto
        z-index: 100
        min-width: 100%
        .offers
            width: 100%
            display: flex
            flex-flow: column
            align-items: center
            .one-offer
                width: 100%
                display: flex
                align-items: center
                flex-flow: column
                &.expand
                    .offer-details-description
                        height auto
                        font-weight: 400 !important
                        opacity 1
                        padding: 0 100px
                        transition: all 0.2 linear
                        max-height: 4000px
                        font-family: "frank-new", "sans-serif" !important
                        @media (max-width: 768px)
                            padding: 0
                            margin: 0 auto
                        @media (max-width: 616px)
                            padding: 0
                        ul
                            padding: 0 0 0 20px
                            list-style-type: none
                            font-size: 16px
                            font-weight: 400 !important
                            margin: 10px 0 0 0
                            li
                                line-height: 2em;
                                list-style-type: none
                                text-align: left
                                &::before
                                    content: ''
                                    display: inline-block
                                    height: .8em
                                    width: .8em
                                    margin: 10px 10px 0 -20px 
                                    border-radius: 50%
                                    border: 1px solid pictograms
                                    position: absolute
                    *
                        height auto
                        opacity 1
                        display block
                        transition: all 0.2 linear

                a
                    text-decoration: none
                    display: block
                    width: 100%
                    .bar
                        display: flex
                        flex-flow: row
                        width: 100%
                        padding: 50px 0
                        margin: 0
                        cursor pointer
                        @media (max-width: 768px)
                            flex-flow: column
                        h1
                            margin: 0 43px 0 0px
                            color: headerNumbersBlue
                            font-size: 52.95px
                            padding: 0
                            font-weight: 100 !important
                            position: relative
                            @media (max-width: 616px)
                                flex-flow: column
                        .offer-headers
                            display: flex
                            justify-content: space-between
                            align-items: center
                            flex-flow: row
                            width: 100%
                            margin: 0 100px 0 0
                            padding: 0 0 50px 0
                            border-bottom: 1px solid headerNumbersBlue
                            h2 
                                color: headerTextBlue
                                font-weight: 400 !important
                                font-size: 36px
                                padding: 0px 0 0 0
                                margin: 0  
                                line-height: 46.02px
                                @media (max-width: 404px)
                                    font-size: 30px
                                @media (max-width: 350px)
                                    font-size: 26px
                            .arrow
                                display: flex
                                align-items: center
                                justify-content: flex-end
                                padding: 0
                                margin: 0
                                position: relative
                                width: 12px
                                height: 30px
                                transform: rotate(270deg)
                                transition: all 0.3s linear
                                @media (max-width: 616px)
                                    padding: 30px
                                img 
                                    // transform: rotate(90deg)
                                    display: flex
                                    align-items: center
                                *
                                    height 100%
                                    

            .offer-details
                display: flex
                flex-flow: column
                width: 100%
                .offer-details-header
                    width: 100%
                    display: flex
            .offer-details-description
                width: 100%
                max-height: 3000px
                display: flex
                flex-flow: column
                height: 0 
                opacity : 0
                font-size: 16px
                font-weight: 300 !important
                color: headerTextBlue
                transition: all 0.2 linear
                font-family: "frank-new", "sans-serif" !important
                *
                    height 0
                    opacity 0
                    margin-bottom: 0
                    transition: all 0.2 linear
                p
                    width: 100%
                .apply-button
                    width: 100%
                    // text-align: center !important
                    margin: 60px  0 0 0
                    display: flex
                    justify-content: flex-start !important
                    .apply-btn
                        display: flex
                        justify-content: center !important 
                        align-items: center !important
                        height: 41px
                        width: 170px
                        border-radius: 50px
                        border: 1px solid pictograms
                        font-size: 14px  
                        background: #fff
                        outline: none
                        cursor pointer   
                        text-decoration: none
                        color:headerTextBlue
                        text-align: center !important
                        display: block
                        padding: 0
                        &:hover
                            background-color: whiteButtonHover

.color-open-career-box
    color: pictograms !important

.arrow-up
    position: absolute
    right: -1px
    display: flex
    align-items: center
    justify-content: flex-end
    padding: 0
    margin: 0
    transform: rotate(90deg) !important
    display: flex
    align-items: center
    display: none