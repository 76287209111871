frankNewFont = frank-new normal 200, sans-serif
pcsBlue = #330099
pcsGreen = #00edc2


#newsWrapper
    font-family: frank-new, sans-serif
    font-weight: 500 !important
    background-color: transparent 
    position: relative
    width: 100%
    height: 100% 
    margin-top: -400px
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 0
    .newsList
        max-width: 1440px
        font-size: 16px
        width: 100%
        background-color: white
        border-top-right-radius: 20px
        .year
            background-image: url(../img/polokrag_zielony.svg)
            background-repeat: no-repeat
            background-position: 100%
            height: 2em
            width: 80px
            margin: 1.4em auto 1.6em 3.85em
            padding-top: 8px 
            font-size: 28px 
            font-weight: 300 !important
            color: pcsBlue
        ul
            list-style-type: none
            padding: 0px
            li
                padding-top: 10px
                margin-top: 3%
                display: flex
                flex-direction: row 
                &:hover
                    background-color: #e8fff9
                    // background-image: url(../img/newsHoverBg.png)
                    // background-size: cover;
                    // background-position: 80%;
                // &:hover .readMoreBtn
                //     visibility: visible !important
                .addDate
                    padding-right: 40px
                    width: 23%
                    .addDatebox
                        border-bottom: solid 1px pcsBlue
                        p
                            color: pcsGreen
                            font-weight: 500 !important
                            text-align: right
                            margin-bottom: 0.3em
                            span
                                font-weight: 400 !important
                .newsContent
                    width: 77%
                    padding-left: 1.6em
                    padding-right: 6em
                    padding-bottom: 2em
                    h4
                        margin-top: 1em
                        color: #110047
                    p
                        font-weight: 400 !important
                        a
                            color: pictograms
                    a    
                        color: pictograms
                        display: flex
                        flex-flow: row
                        .readMoreBtn
                            background-color: transparent
                            border: none 
                            color: pcsBlue
                            // color: blue
                            padding-left: 0px
                            width: 105px
                            text-align: left                  
                            cursor: pointer
                            outline: none
                        .box-for-arrow2
                            width: 10px
                            height: 30px
                            transform: rotate(180deg)
                            img
                                height: 10px
                    .newsImg
                        width: 330px
                        height 200px
                        background: red
                        margin-top: 2em
                        margin-bottom: 1em
                        border-top-right-radius: 20px
                        border-bottom-right-radius: 20px
    .newsPagination
        background-color: white
        height: 45px
        width: 100%
        padding-top: 20px
        margin-bottom: 35px
        text-align: center
        font-size: 12px
        font-weight: 400 !important
        position: relative
        &::before
            content: ''
            background: pcsGreen
            width: 100px
            height: 1px
            position: absolute
            left: calc(50% - 50px)
            top: 73%
            opacity: 0.3
        a
            background: transparent
            display: inline-block
            padding: 4px 8px
            text-decoration: none
            color: #000000
            &:first-child
                color: pcsGreen
            &:last-child
                color: pcsGreen
            &:hover
                color: pcsGreen
            &:active
                color: pcsGreen

@media (max-width: 1024px)
    #newsWrapper
        // margin-top: -400px
        margin-top: 0
        .newsList
            width: 90%
@media (max-width: 768px)
    #newsWrapper
        .newsList
            .year
                margin-left: 1.85em
            ul
                li
                    .addDate
                        padding-right: 15px
                    .newsContent
                        padding-right: 3em
@media (max-width: 616px)
    #newsWrapper
        .newsList
            width: 100%
            border-top-left-radius: 20px
@media (max-width: 540px)
    #newsWrapper
        .newsList
            .year
                margin-left: 1.5em
            ul
                li
                    flex-direction: column
                    .addDate
                        width: 100%
                        .addDatebox
                            p
                                text-align: left
                                padding-left: 2.5em
                    .newsContent
                        width: 100% 
                        padding-right: 2.5em
                        padding-left: 2.5em
        










 