#form
    margin: 0 auto 35px
#form, #cargo-form
    max-width: 1440px
    font-family: "frank-new", "sans-serif" !important
    width: 80%
    @media(max-width: 616px)
        width: 100%
    .container-authorities
        display: flex
        flex-flow: column
        min-width: 100%
        margin: 0
        padding: 0
        @media(max-width: 768px)
            padding: 0 40px
        .form-header
            display: flex
            align-items: flex-start 
            flex-flow: row
            width: 100%
            @media (max-width: 768px)
                flex-flow: column
                align-items: flex-start
            h1
                margin: 0 43px 0 0
                color: headerNumbersBlue
                font-size: 52.95px
                padding: 0
                font-weight: 100 !important
            .h2-elements
                display: flex
                flex-flow: column
                width: 100%
                h2 
                    color: headerTextBlue
                    padding: 20px 0 0 0
                    margin: 0  
                    font-weight: 400 !important
                    font-size: 36px
                    line-height: 46px
                    @media (max-width: 768px)
                        padding: 0 0 0 3px
                .contact-for-authorities-form-wrapper
                    min-height: 400px
                    width: 100%
                    padding: 0 100px 0 0
                    @media(max-width: 768px)
                        padding: 0
                    .form-elements
                        display: flex
                        flex-flow: row
                        margin: 0 0 5px 0
                        justify-content: space-between
                        @media (max-width: 1060px)
                            // width: 100%
                            flex-flow: column
                        .contact-form
                            width: 100%
                            margin: 30px 0 0 0
                            padding: 0
                            display: flex
                            justify-content: space-between
                            flex-flow: row nowrap
                            @media (max-width: 1060px)
                                margin: 22px 0 0 0
                            @media (max-width: 835px)
                                flex-flow: column
                                margin: 16px 0 0 000
                            :nth-child(2)
                                margin: 0
                            input
                                height: 31px
                                width: 49%
                                border-radius: 0 !important
                                margin: 0 10px 0 0
                                color: headerTextBlue !important
                                background-color: unHoverBox
                                font-family: "frank-new", "sans-serif" !important
                                border: none
                                cursor: pointer
                                padding: 0 10px
                                font-size: 14px
                                font-family: "Frank New"
                                outline: none
                                box-sizing: border-box
                                justify-content: space-between
                                font-weight: 400 !important
                                @media (max-width: 835px)
                                    width: 100%
                                    margin: 0 0 15px 0
                                &:focus
                                    background-color: pictograms !important
                                    color: #fff

                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button
                                -webkit-appearance: none
                                margin: 0

                            input[type=number]
                                -moz-appearance: textfield

                            .second-input
                                margin: 0 10px 0 0
                                @media (max-width: 1060px)
                                    margin: 0
                    .contact-form-second
                        width: 100%
                        color: headerTextBlue
                        #message
                            margin: 15px 0 0 0
                            @media (max-width: 835px)
                                margin: 10px 0 0 0
                        textarea
                            width: 100%
                            height: 30px
                            margin: 17px 10px 0px 0
                            padding: 5px 0 0px 9px
                            font-family: "frank-new", "sans-serif" !important
                            font-size: 14px
                            font-weight: 400 !important
                            color: headerTextBlue
                            -ms-color: headerTextBlue
                            background-color: unHoverBox
                            border: none
                            outline: none
                            font-weight: 400 !important
                            -webkit-box-sizing: border-box;
                            box-sizing: border-box
                            @media (max-width: 768px)
                                margin: 17px 10px 10px 0
                            @media (max-width: 835px)
                                margin: 11px 0 0 0
                            &:focus
                                background-color: pictograms
                                color: #fff
                            .form-control
                                height: none
                        .input-radio
                            color: headerTextBlue
                            font-family: "frank-new", "sans-serif" !important
                            font-weight: 400 !important
                            font-size: 14px
                            margin-top: 20px
                            width: 100%
                            position: relative
                            a
                                text-decoration: none
                                color: pictograms

                            input[type=checkbox]
                                height: fit-content
                                width: 3%



    .form-button
        width: 100%
        text-align: center !important
        margin: 15px auto 0
        display: flex
        justify-content: center !important

        button
            display: flex
            justify-content: center !important 
            align-items: center !important
            height: 41px
            width: 170px
            border-radius: 50px
            border: 1px solid pictograms
            font-size: 14px  
            background: #fff
            outline: none
            cursor pointer   
            text-decoration: none
            color:headerTextBlue
            text-align: center !important
            display: block
            padding: 0
            margin: 0 auto
            &:hover
            *
                background-color: whiteButtonHover
               


input[type=checkbox] 
    opacity: 0
    cursor: pointer

.check
    position: absolute
    top: 3px
    left: 1px
    height: 12px
    width: 12px
    background-color: red    
    border-radius: 50%
    cursor: pointer

input:checked ~ .check
    background-color: pictograms

    
.check:after
    content: ""
    position: absolute
    display: none
    

input ~ .check
    background-color: #ccc
    


input:checked ~ .check:after
    display: block


.check:after
    top: 1px
    left: 1px
    width: 10px
    height: 10px
    border-radius: 50%
    background-color: pictograms
    border: 2px solid #fff


.errors
    display: flex
    flex-flow: column
    padding: 0
    li
        list-style-type none
        padding: 0
        color: errors
        font-weight: 400 !important
        font-size: 12px


#cargo-form
    @media (min-width 768px)
        margin: 0 100px
    .form-button
        justify-content: start !important
        button
            margin 0

        