.pictogram-wrapper
    margin: 0 auto
    width: 80%
    max-width: 1440px
    .container
        display: flex
        width: 100%
        padding: 0
        justify-content: center
        .why-worth
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            width: 100%
            padding: 0
            .header
                width: 100%
                display: flex
                // align-items: flex-end
                @media (max-width: 768px)
                    flex-flow: column
                    align-items: flex-start
                    width: 100%
                    margin: 0 0 15px 0
                    padding: 0
                h1
                    margin: 0 43px 0 0
                    color: headerNumbersBlue
                    padding: 0
                    font-weight: 100 !important
                h2 
                    color: headerTextBlue
                    padding: 0px 0 0 0
                    margin: 0  
                    height 100%
                    font-weight: 400 !important
                    
                    @media (max-width: 768px)  
                        padding: 0 0 0 3px
                    p
                        padding: 0
                        margin: 0
                        font-weight: 400 !important
            .boxes
                display: flex
                flex-flow: wrap
                padding: 0
                margin: 0
                @media (max-width: 599px)
                    width: 100%
                .box
                    width: 33.3%
                    display: flex
                    flex-flow: column
                    color: headerTextBlue
                    justify-content: flex-start
                    align-items: center
                    padding: 81px 10px 0 10px
                    margin: 0
                    @media(max-width: 768px)
                        padding: 40px 10px 0 10px
                    @media (max-width: 599px)
                        width: 100%
                        margin: 30px auto 0 auto
                        flex-flow: row
                        justify-content: flex-start
                        padding: 0
                    // &:first-child
                    //     svg 
                    //         height : 62px !important
                    //         width 89px!important
                    // &:nth-child(2)
                    //     svg 
                    //         height : 71px!important
                    //         width 63px!important
                    // &:nth-child(3)
                    //     svg  
                    //         height : 71px!important
                    //         width 71px!important

                    // &:nth-child(4)
                    //     svg  
                    //         height : 73px!important
                    //         width 73px!important
                    
                    // &:nth-child(5)
                    //     svg  
                    //         height : 70px!important
                    //         width 46px!important
                    // &:last-child
                    //     svg 
                    //         height : 54px!important
                    //         width 90px!important
                    svg, .svg
                        width: 200px;
                        height: auto;
                        margin: 0 0 30px 0
                        @media (max-width: 599px)
                            margin: 0 30px 0 0
                    div
                        text-align: center
                        font-size: 16px
                        line-height: 28px
                        padding: 0 10px
                        margin: 0
                        @media (max-width: 599px)
                            width: 100%
                            text-align: left
