.image-wrapper
    display: flex
    flex-flow: column
    width: 100%
    min-height: 900px
    position: relative
    border-left: 16px solid white
    border-right: 16px solid white
    margin: 100px 0 0 0
    font-family: frank-new, sans-serif
    &.hidden
        display:none
        visibility:hidden
    @media (max-width: 1024px)
        border: none
    .footer-image
        background-repeat: no-repeat
        background-position: center
        background-size: cover
        position: absolute
        height: 100%
        bottom: 0
        width: 100%
        margin: 0 auto                
        @media (max-width: 937px)
            background-size: contain
            background-position: bottom
            bottom: 0
    .general-wrapper
        margin: 0 auto
        width: 80%
        min-height: 900px
        max-width: 1440px
        position: relative
        display: flex
        flex-flow: row
        padding: 0 0 0 5%
        @media (max-width: 937px)
            padding: 0
        @media (max-width: 616px)
            width: 80%
        .footer
            width: 100%
            max-width: 1440px
            margin: 0 auto
            flex-flow: row
            .container
                display: flex
                flex-flow: row
                min-width: 100%
                margin: 0 auto
                justify-content: space-between
                @media (max-width: 937px)
                    flex-flow: column
                    justify-content: flex-start
                @media (max-width: 616px)
                    padding: 0
                .footer-elements
                    display: flex
                    flex-direction: row
                    flex-wrap: wrap
                    justify-content: flex-start
                    height: 100%
                    width: 100%
                    @media (max-width: 937px)
                        min-height: 150px
                    .kontakt
                        margin: 0
                    .header-wrapper1
                        width: 70%
                        margin: 0 0 20px 0
                        @media (max-width: 937px)
                            width: 100%
                        h1
                            font-size: 15.78px
                            color: headerTextBlue
                            padding: 0 0 20px 0 
                            margin: 0
                            font-weight: 700 !important
                            width: 100%
                            border-bottom: 1px solid headerNumbersBlue

                    .header-wrapper
                        width: 70%
                        margin:0 50px 20px 0
                        @media (max-width: 937px)
                            width: 100%
                            margin: 50px 0 0 0
                        h1
                            font-size: 15.78px
                            color: headerTextBlue
                            padding: 0 0 20px 0 
                            margin: 0
                            font-weight: 700 !important
                            width: 100%
                            border-bottom: 1px solid headerNumbersBlue

                    .item-wrapper
                        width: 40%
                        a       
                            text-decoration: none
                            color: headerTextBlue
                            font-size: 14px
                            padding: 0
                            margin: 0
                            height: 100%
                            width: 100%
                            font-weight: 400 !important

                    .item-wrapper2
                        width: 80%
                        a       
                            text-decoration: none
                            color: headerTextBlue
                            font-size: 14px
                            padding: 0
                            margin: 0
                            height: 100%
                            width: 100%
                            font-weight: 400 !important

                    .item-wrapper3
                        width: 100%
                        p, a
                            color: headerTextBlue
                            font-size: 14px
                            padding: 0
                            margin: 0
                            width: 100%
                            font-weight: 400 !important
                            text-decoration: none
                            &:hover
                                color: headerTextBlue
                        .footer-btn
                            margin-right: 0
                            background-color: #00edc2
                            padding: 5px 15px
                            border: 1px
                            solid #00edc2
                            border-radius: 20px
                            .footer-btn-name
                              color: #fff
                              font-size: 14px
                              padding: 0
                              margin: 0
                              width: 100%
                              font-weight: 400!important
                              text-decoration: none

                    .social-wrapper
                        border-radius: 100% 
                        width: 30px
                        height: 30px
                        margin: 20px 10px 0 0 0
                        display: flex
                        flex-flow: row
                        border: 1px solid pictograms
                        justify-content: center
                        align-items: center
                        .svg
                            width: 50%
                            height: 50%
                            
                            &, & * 
                                fill: headerNumbersBlue
.bottom-line
    height: 26px
    width: 100%
    position: absolute
    bottom: 0
    background-color: headerTextBlue
.bottom-logo
    position: absolute
    padding: 3px 3px
    right: 4vw
    height: 37px
    width: 58px
    bottom: 10px
    border-radius: 50px
    text-align: center
    background-color: headerTextBlue
    img 
        height: 100%
        width: 100%


.container
    display: flex
    justify-content: flex-end

.copyright
    font-size: 9px
    font-weight: 400 !important
    text-align: right
    padding: 0 16px 0 0
    color: headerTextBlue
    width: 100%
    @media (max-width: 1024px)
        padding: 0
