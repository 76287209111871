.light-green
    border-radius: 20px;
    background-color: #00ecc2
    padding: 10px 25px;
    max-width: 190px;
    border: none
    outline: none
    *
        font-size: 14px
        // line-height: 30px;
        color:  #110047
    a
        &:hover
            text-decoration: none;

.dark
    border-radius: 20px;
    background-color: #110047
    padding: 10px 25px;
    max-width: 120px;
    border: none
    outline: none
    *
        font-size: 14px
        // line-height: 30px;
        color:  #00ecc2
    a
        &:hover
            text-decoration: none;

