#tekstIMockupPoLewej
    position: relative
    max-width: 1440px
    width: 80%
    margin: 0 auto
    display: flex;
    justify-content: flex-end
    @media (max-width: 1230px)
       justify-content: flex-start
    @media (max-width: 768px)
       padding: 0 40px
    .left-box-outline
        position: absolute
        right: 50%
        top: 80px
        @media (max-width: 1230px)
            display: none
    .header
        max-width: 50%
        display: flex
        justify-content: flex-start
        flex-direction: row
        align-items: flex-start
        padding: 0
        padding-left: 30px
        @media (max-width: 1230px)
            max-width: 100%
            padding: 0
        @media (max-width: 1024px)
            padding: 0
        @media (max-width: 768px)
            display: column
            margin: 0 40px
            width: 100%
        @media (max-width: 768px)
            margin: 0
        h1
            margin: 0 43px 0 0px
            color: headerNumbersBlue
            font-size: 52.95px
            padding: 110px 0 0 0
            font-weight: 100 !important
            position: relative
            @media (max-width: 1230px)
                padding: 110px 0 0 0
            @media (max-width: 1024px)
                padding: 50px 0 0 0
        .wrapper
            width: 100%
            display: flex
            flex-flow: column
            margin: 0 0 0px 0
            // border: 1px solid orange
            h2 
                color: headerTextBlue
                font-weight: 400 !important
                font-size: 36px
                padding: 80px 0 0 0
                margin: 0  
                height 100%
                line-height: 46.02px
                @media (max-width: 1230px)
                    width: 100%
                @media (max-width: 1024px)
                    margin: 15px 0 0 0
            .h2
                font-size: 16px
                font-weight: 100 !important
                z-index: 100
                line-height: 2em
                margin: 30px 0 80px 0
                color: headerTextBlue
                padding: 22px 0 40px 0
                hyphens: auto
                @media (max-width: 1024px)
                    margin: 0
                    width: 100%
                p
                    font-weight: 400
                    width: 100%
                .button
                    display: none
@media (max-width: 1024px)
    #tekstIMockupPoLewej
        width: 100%
        .header
            h1
            .wrapper
                h2
                    padding: 0px
                    width: 100%
        .left-box
            display: none
@media (max-width: 768px)
    #tekstIMockupPoLewej
        .header
            flex-direction: column

.left-box-outline2
    width: 100%
    min-height: 100px
    margin: 0 auto
    z-index: 100
    display: none
    @media (max-width: 1230px)
        display: flex
        width: 100%
        height: 100%
        img 
            width: 100%
            height: 100%